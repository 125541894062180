import React from "react"
import "./style.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"

let dateTimeInterval

class HeadContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      date: "",
      time: "",
      specificTime: "WIB",
    }
    this.handleGetDateTime = this.handleGetDateTime.bind(this)
  }

  // handleGetDateTime() {
  //   const today = new Date()
  //   let date = today.getDate() < 10 ? "0" + today.getDate() : today.getDate()
  //   let month
  //   let year = today.getFullYear()
  //   let hour =
  //     today.getHours() < 10 ? "0" + today.getHours() : today.getHours()
  //   let minute =
  //     today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes()
  //   let second =
  //     today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds()

  //   switch (today.getMonth()) {
  //     case 0:
  //       month = "Januari"
  //       break
  //     case 1:
  //       month = "Februari"
  //       break
  //     case 2:
  //       month = "Maret"
  //       break
  //     case 3:
  //       month = "April"
  //       break
  //     case 4:
  //       month = "Mei"
  //       break
  //     case 5:
  //       month = "Juni"
  //       break
  //     case 6:
  //       month = "Juli"
  //       break
  //     case 7:
  //       month = "Agustus"
  //       break
  //     case 8:
  //       month = "September"
  //       break
  //     case 9:
  //       month = "Oktober"
  //       break
  //     case 10:
  //       month = "November"
  //       break
  //     case 11:
  //       month = "Desember"
  //       break
  //     default:
  //       break
  //   }

  //   this.setState({
  //     date: `${date} ${month} ${year}`,
  //     time: `${hour}:${minute}:${second}`,
  //   })
  // }

  handleGetDateTime() {
    const today = new Date()
    let date = today.getDate() < 10 ? "0" + today.getDate() : today.getDate()
    let month
    let year = today.getFullYear()
    let hour =
      today.getHours() < 10 ? "0" + today.getHours() : today.getHours()
    let minute =
      today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes()
    let second =
      today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds()
  
    switch (today.getMonth()) {
      case 0:
        month = "Januari"
        break
      case 1:
        month = "Februari"
        break
      case 2:
        month = "Maret"
        break
      case 3:
        month = "April"
        break
      case 4:
        month = "Mei"
        break
      case 5:
        month = "Juni"
        break
      case 6:
        month = "Juli"
        break
      case 7:
        month = "Agustus"
        break
      case 8:
        month = "September"
        break
      case 9:
        month = "Oktober"
        break
      case 10:
        month = "November"
        break
      case 11:
        month = "Desember"
        break
      default:
        break
    }
  
    const newDate = `${date} ${month} ${year}`
    const newTime = `${hour}:${minute}:${second}`
  
    
    if (newDate !== this.state.date || newTime !== this.state.time) {
      this.setState({
        date: newDate,
        time: newTime,
      })
    }
  }
  
  componentDidMount() {
    this.handleGetDateTime()
    dateTimeInterval = setInterval(this.handleGetDateTime, 1000)
  }

  componentWillUnmount() {
    clearInterval(dateTimeInterval)
  }

 

  render() {
    console.log('headcontent')
    return (
      <div className="Sign-HeadContent">
        <div className="Sign-Container">
          <div className="InnerHead">
            <div className="InnerHeadLeft">
              <div className="PDIPLogoSection">
                <img
                  className="logo"
                  src={require("../../../../Assets/Logos/logo_pdi.png")}
                  alt="logo_pdi"
                />
                <ul className="TextWrapper">
                  <li>DPD PDI Perjuangan</li>
                  <li>Jawa Tengah</li>
                </ul>
              </div>
            </div>
            <div className="InnerHeadRight">
              <div className="DateTime">
                <span className="CalendarIcon">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
                <span className="Date">{this.state.date}</span>
                <span className="Time">{this.state.time}</span>
                <span className="SpecificTime">{this.state.specificTime}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HeadContent
