import React, {Component} from "react"
import AppContext from "../../../../../../../AppContext"
import Slider from "react-slick"
// import classes from "./Multiple.module.scss"
import Partai from "../Partai"
import Paslon from "../Paslon"

class Multiple extends Component {
  
	static contextType = AppContext

	afterChange = current => {
		this.context.setCurrentResults(this.props.data[current])
		/* if (this.props.data.length > 0 && current === this.props.data.length - 1) {
      setInterval(() => {
        this.refreshMap()
      }, 10000)
		} */
	}

	refreshMap = () => {
		this.intervalTime = setInterval(() => {
			this.context.resetMaps(this.context.currentDownline)
		}, 10000)
	}

  componentWillUnmount() {
		/* if (this.intervalTime) {
			clearInterval(this.intervalTime)
		} */
  }
	
	render() {
		const settings = {
			dots: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			speed: 1000,
			autoplaySpeed: 10000,
			cssEase: "ease-in"
		}

		return (
			<div style={{maxWidth: '100vw', zIndex: '9', height: '100%', margin: '0 auto'}}>
				<Slider arrows={false} {...settings} style={{maxWidth: "1536px", boxSizing: "border-box", height: '100%'}} afterChange={this.afterChange}>
					{
						this.props.data.map((item, index) => {
							return (
								this.context.election === 'DPR RI' ||
								this.context.election === 'DPRD PROVINSI' ||
								this.context.election === 'DPRD KABUPATEN/KOTA' ? <Partai key={index} data={item.results}/> : 
								(
									this.context.election === 'PILPRES' ||
									this.context.election === 'PILKADA GUBERNUR' ||
									this.context.election === 'PILKADA BUPATI/WALIKOTA' ||
									this.context.election === 'DPD' ?
									<Paslon key={index} multiple={true} data={item.results}/>
								: null)
							)
						}) 	
					}
				</Slider>
			</div>
		)
	}
}

export default Multiple