import React from 'react';
import AppContext from "../../../../../../AppContext"
import classes from "./DataProgress.module.scss"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"

var numeral = require('numeral')

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 7,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#484848',
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.backgroundcolor !== undefined ? props.backgroundcolor : '#FFE082',
  }),
}))(LinearProgress)

class DataProgress extends React.PureComponent {

  static contextType = AppContext
  
  render() {

	// console.log(this.props,'propsss voteResults')
    return (
			<div className={classes.DataProgress_container}>
				<div className={classes.DataProgress_background}/>
				{
					this.props.data !== undefined &&
					this.props.data.results !== undefined &&
					this.props.data.results.length > 0 ?
					<div className={classes.Content}>
						<ul className={classes.List_bar__ul}>
							{/* <li> */}
								{/* <div className={classes.Info}> */}
									{/* <div>Pengguna Hak Pilih</div> */}
									{/* <span>{numeral(this.props.data.dpt.input.voterT).format()}/{numeral(this.props.data.dpt.data.voterT).format()}</span> */}
									{/* <span>{numeral(this.usedInput()).format()}/{numeral(this.usedData()).format()}</span> */}
									{/* <span>{this.context.abbreviateNumber(this.usedInput())}/{this.context.abbreviateNumber(this.usedData())}</span> */}
								{/* </div> */}
								{/* <BorderLinearProgress */}
									{/* variant="determinate" backgroundcolor="#039BE5" value={this.props.data.dpt.data.voterT > 0 ? Number(((this.props.data.dpt.input.voterT/this.props.data.dpt.data.voterT).toFixed(2)*100).toFixed(0)) : 0}/> */}
							{/* </li> */}
							<li>
								<div className={classes.Info}>
									<div>Desa / Kelurahan Masuk</div>
									<span>{numeral(this.props.data.progress.input.daa).format()}/{numeral(this.props.data.progress.data.daa).format()}</span>
									{/* <span>{this.context.abbreviateNumber(this.props.data.progress.input.c)}/{this.context.abbreviateNumber(this.props.data.progress.data.c)}</span> */}
								</div>
								<BorderLinearProgress variant="determinate" backgroundcolor="#FDD835" value={parseInt(this.props.data.progress.data.daa) > 0 ? Number(((parseInt(this.props.data.progress.input.daa)/parseInt(this.props.data.progress.data.daa)).toFixed(2)*100).toFixed(0)) : 0}/>
							</li>
						</ul>
					</div>
					: null
				}
			</div>
    )
  }
}

export default DataProgress