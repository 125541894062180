import React from "react"
import "./style.scss"
import Login from "../../Contents/Login"
import Logoff from "../../Contents/Logoff"

class BodyContent extends React.PureComponent {
  render() {
    
    return (
      <div className="Sign-BodyContent">
        <div className="Sign-Container">
          <div className="InnerBody">
            {!this.props.isLogoff ? <Login /> : <Logoff />}
          </div>
        </div>
      </div>
    )
  }
}

export default BodyContent
