import React from "react"
import {Doughnut} from "react-chartjs-2"

class ChartDoughnut extends React.PureComponent {
  getData = () => {
    return {
      labels: ['Partisipasi','DPT'],
      datasets: [{
        data: [
          ((parseInt(this.props.source.input)/parseInt(this.props.source.data))*100),
          (100-((parseInt(this.props.source.input)/parseInt(this.props.source.data))*100))
        ],
        backgroundColor: ['#b71c1c','#c4c4c4'],
        hoverBackgroundColor: ['#b71c1c','#c4c4c4']
      }]
    }
  }

  render() {
    return (
      <Doughnut data={this.getData()} options={{legend: {display: false}}} />
    )
  }
}

export default ChartDoughnut