import API from "./API"
import { API_Voteroom } from "./BaseURL"

// const VOTEROOM_API_URL = "http://159.89.195.233:8002"
// const VOTEROOM_API_URL = "http://demovr.pantimarhaen.id:7001"
// const VOTEROOM_API_URL = "http://127.0.0.1:8000"
const VOTEROOM_API_URL = API_Voteroom


const votes = async (periode, election, regional, document, id, type,view) => {

 
  const response = API.get('votes', {
    baseURL: `${VOTEROOM_API_URL}`,
    params: {
      body: {
        // periode: periode,
        periode: 2024,
        election: election,
        regional: regional,
        document: document,
        id: id,
        type: type,
        view:view === 'dashboard'? 'maps':'table'
      }
    }
  })
  .then(res => {
    return res.data
  })
  .catch(err => {
    console.log(err)
    return err.data
  })

  return response
}

const postVotes = async dataPost => {
  const response = API.post('votes', {
    data: dataPost
  },
  {
    baseURL: `${VOTEROOM_API_URL}`,
  })
  .then(res => {
    return res.data
  })
  .catch(err => {
    console.log(err)
    return err.data
  })

  return response
}

const typeToDoc = type => {
  let doc
  if (type === 'Provinsi') {
    doc = 'DC'
  } else if (type === 'Kabupaten' || type === 'Kota') {
    doc = 'DB'
  } else if (type === 'Kecamatan') {
    doc = 'DA'
  } else if (type === 'Desa' || type === 'Kelurahan') {
    doc = 'DAA'
  } else {
    doc = 'C'
  }

  return doc
}

const attachments = async (periode, election, type, provinceID, districtID, subdistrictID, villageID, pollstationID) => {
  const response = API.get('votes/attachments', {
    baseURL: `${VOTEROOM_API_URL}`,
    params: {
      body: {
        // periode: periode,
        periode: 2024,
        election: election,
        document: typeToDoc(type !== null ? type : this.state.currentSelected.type),
        provinceID: provinceID,
        districtID: districtID,
        subdistrictID: subdistrictID,
        villageID: villageID,
        pollstationID: pollstationID
      }
    }
  })
  .then(res => {
    return res.data
  })
  .catch(err => {
    console.log(err)
    return err.data
  })

  return response
}

export { attachments, votes, postVotes }