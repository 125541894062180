import React from "react"
import AppContext from "../../../../../../AppContext"
import classes from "./LastUpdated.module.scss"

class LastUpdated extends React.PureComponent {

  static contextType = AppContext
  
  render() {
    return (
			<div className={classes.LastUpdated_container}>
				<div className={classes.LastUpdated_background}/>
				{
					this.props.data !== undefined &&
					this.props.data.results !== undefined &&
					this.props.data.results.length > 0 ?
					<div className={classes.Content}>
						<div className={classes.Date_info} style={{backgroundColor: `#FFC400`}}>
							<span className={classes.Title_copy}>Last Update</span>
							<span>
								<div className={classes.Info_copy}>{this.props.data.lastupdated !== null ? this.props.data.lastupdated + ' WIB' : 'Tidak ada update'}</div>
							</span>
						</div>
					</div>
					: null
				}
			</div>
    )
  }
}

export default LastUpdated