import React from "react"
import AppContext from "../../../../../AppContext"
import swal from "sweetalert"
import { Redirect, withRouter } from "react-router-dom"
import { Tooltip } from "@material-ui/core"
import UserManagementPNG from "../../../../../Assets/Icons/cogwheel.png"

import "./style.scss"
import { MenuAccess } from "../../../../../Services/Main"
// import FlipCountdown from "@rumess/react-flip-countdown"
// import moment from "moment"
// moment.locale('id')

class BodyContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      redirect: null,
      countDownStatus: true,
      endTime: "2020-12-09 13:00:00",
      menu:[]
    }
  }

  static contextType = AppContext

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
    MenuAccess().then((res) => {
      // console.log(res)

const dataResults = res.data
const options = [
  {
    title: "PILPRES",
    src: require("../../../../../Assets/Icons/presiden.png"),
    alt: "presiden",
    onClick: "PILPRES"
  },
  {
    title: "PILKADA GUBERNUR",
    src: require("../../../../../Assets/Icons/gubernur.png"),
    alt: "gubernur",
    onClick: "PILKADA GUBERNUR"
  },
  {
    title: "PILKADA BUPATI/WALIKOTA",
    src: require("../../../../../Assets/Icons/bupati.png"),
    alt: "bupati"
  },
  {
    title: "DPR RI",
    src: require("../../../../../Assets/Icons/dprri.png"),
    alt: "dprri"
  },
  {
    title: "DPRD PROVINSI",
    src: require("../../../../../Assets/Icons/dprdprovinsi.png"),
    alt: "dprdprovinsi",
    onClick: "DPRD PROVINSI"
  },
  {
    title: "DPRD KABUPATEN/KOTA",
    src: require("../../../../../Assets/Icons/dprdkabupaten.png"),
    alt: "dprdkabupaten"
  }
];



      const ResultsMenu = dataResults?.map((item) => {
        const matchedOption = options?.find(option => option.title === item.election);
        if (matchedOption) {
          item.source = matchedOption.src;
        }
        return item;
      });

      // console.log(res.data,'dari data')


      this.setState({menu:ResultsMenu})
    })
    // this.intervalTime = setInterval(() => {
    // 	const a = moment(this.state.endTime, 'YYYY-MM-DD h:mm:ss')
    // 	const b = moment()
    // 	const days = parseInt(a.diff(b, 'days'))
    // 	const hours = parseInt(a.diff(b, 'hours'))
    // 	const minutes = parseInt(a.diff(b, 'minutes'))
    // 	const seconds = parseInt(a.diff(b, 'seconds'))

    // 	if ((days+hours+minutes+seconds) <= 0) {
    // 		this.setState({countDownStatus: false})
    // 	}
    // }, 1000)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
    // if (this.intervalTime) {
    // 	clearInterval(this.intervalTime)
    // }
  }

  goTo = (election) => {
    // console.log('goto election :',election)
    // if (!this.state.countDownStatus) {
    if (election === "PILKADA BUPATI/WALIKOTA") {
      this.context.changeElection(election, () => {
        this.context.switchMode("dashboard", () => {
          this.context.PAGE.changeActivePage("/dashboard", () => {
            this.props.history.push("/dashboard")
          })
        })
      })
      /* this.context.changeElection(election, () => {
					this.context.switchMode('summary', () => {
						this.context.PAGE.changeActivePage('/summary', () => {
							this.props.history.push('/summary')
						})
					})
				}) */
    } else if(election === "PILKADA GUBERNUR"){
      this.context.changeElection(election, () => {
        this.context.switchMode("dashboard", () => {
          this.context.PAGE.changeActivePage("/dashboard", () => {
            this.props.history.push("/dashboard")
          })
        })
      })
    }else {
      swal(
        "Oops!",
        "Saat ini baru ada PILKADA BUPATI/WALIKOTA Boss!.",
        "warning"
      )
    }
    // } else {
    // 	swal("Oops!","Sabar Boss belum dimulai!.", "warning")
    // }
  }

  render() {



// console.log(this.state.menu)

    return this.state.redirect ? (
      <Redirect to={this.state.redirect} />
    ) : (
      <AppContext.Consumer>
        {(appContext) => (
          <div className="BodyContent">
            <div
              className="BackgroundImage"
              style={{
                backgroundImage: `url(${require("../../../../../Assets/Miscellaneous/maps.png")})`,
              }}
            ></div>
            {/* this.state.countDownStatus ?
							<div style={{position: 'absolute', width: '100vw', marginTop: '100px', zIndex: 9, color: '#fff'}}>
								<div style={{fontSize: 48, fontWeight: 'bold', textAlign: 'center'}}>Perhitungan akan dimulai dalam :</div>
								<FlipCountdown
									hideYear
									hideMonth
									hideDay
									yearTitle='Tahun'
									monthTitle='Bulan'
									dayTitle='Hari'
									hourTitle='Jam'
									minuteTitle='Menit'
									secondTitle='Detik'
									size='large'
									endAt={this.state.endTime}
								/>
							</div> : null */}
            <div className="wrap">
              <nav
                className="nav"
                role="navigation"
                aria-label="main navigation"
              >
                <ul>
                  {/* {this.state.menu?.map((item, index) => (
                                      <Tooltip key={index} title={item?.election}>
                                      <li onClick={() => {
                                        
                                        console.log(item)
                                        

                                        if(item?.status){
                                          this.context.changeElection(item?.election, () => {
                                            this.context.switchMode("dashboard", () => {
                                              this.context.PAGE.changeActivePage("/dashboard", () => {
                                                this.props.history.push("/dashboard")
                                              })
                                            })
                                          })
                                        }else{
                                          swal(
                                            "Oops!",
                                            // "Saat ini baru ada PILKADA BUPATI/WALIKOTA Boss!.",
                                            "Saat ini belum di buka bos",
                                            "warning"
                                          )
                                        }


                                      }}>
                                        <img
                                          src={item?.source}
                                          alt={item?.election}
                                        />
                                        <div className="navOverlay" />
                                      </li>
                                    </Tooltip>
                  ))} */}
                  {/* <Tooltip title={"PILPRES"}>
                    <li onClick={(event) => this.goTo("PILPRES")}>
                      <img
                        src={require("../../../../../Assets/Icons/presiden.png")}
                        alt="presiden"
                      />
                      <div className="navOverlay" />
                    </li>
                  </Tooltip> */}
                  <Tooltip title={"PILKADA GUBERNUR"}>
                    <li onClick={(event) => this.goTo("PILKADA GUBERNUR")}>
                      <img
                        src={require("../../../../../Assets/Icons/gubernur.png")}
                        alt="gubernur"
                      />
                      <div className="navOverlay" />
                    </li>
                  </Tooltip>
                  <Tooltip title={"PILKADA BUPATI/WALIKOTA"}>
                    <li
                      onClick={(event) => this.goTo("PILKADA BUPATI/WALIKOTA")}
                    >
                      <img
                        src={require("../../../../../Assets/Icons/bupati.png")}
                        alt="bupati"
                      />
                      <div className="navOverlay" />
                    </li>
                  </Tooltip>
                  {/* <Tooltip title={"DPR RI"}>
                    <li onClick={(event) => this.goTo("DPR RI")}>
                      <img
                        src={require("../../../../../Assets/Icons/dprri.png")}
                        alt="dprri"
                      />
                      <div className="navOverlay" />
                    </li>
                  </Tooltip>
                  <Tooltip title={"DPRD PROVINSI"}>
                    <li onClick={(event) => this.goTo("DPRD PROVINSI")}>
                      <img
                        src={require("../../../../../Assets/Icons/dprdprovinsi.png")}
                        alt="dprdprovinsi"
                      />
                      <div className="navOverlay" />
                    </li>
                  </Tooltip>
                  <Tooltip title={"DPRD KABUPATEN/KOTA"}>
                    <li onClick={(event) => this.goTo("DPRD KABUPATEN/KOTA")}>
                      <img
                        src={require("../../../../../Assets/Icons/dprdkabupaten.png")}
                        alt="dprdkabupaten"
                      />
                      <div className="navOverlay" />
                    </li>
                  </Tooltip> */}
                  {/* <Tooltip title={"Management"}>
                    <li
                      onClick={(event) =>
                        this.props.history.push("/management")
                      }
                    >
                      <div
                        style={{
                          padding: 10,
                          backgroundColor: "#414141",
                          borderRadius: 10,
                          width: 115,
                          height: 115,
                        }}
                      >
                        <img
                          alt="management"
                          src={UserManagementPNG}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </li>
                  </Tooltip> */}
                </ul>
              </nav>
              <div className="overlay" />
            </div>
          </div>
        )}
      </AppContext.Consumer>
    )
  }
}

export default withRouter(BodyContent)