import React, {Component} from "react"
import Slider from "react-slick"
import classes from "./MorePairs.module.scss"
import Card from "./Card"

class MorePairs extends Component {
	render() {
		const settings = {
			dots: false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 4,
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 5000,
			cssEase: "ease-in"
		}

		return (
			<div className={classes.MorePairs_container}>
				<div className={classes.Content}>
					<div style={{maxWidth: '100vw', zIndex: '9', height: '100%', margin: '0 auto'}}>
						<Slider arrows={false} {...settings} style={{maxWidth: "1536px", boxSizing: "border-box", height: '100%'}}>
							{
								this.props.data.map((item, index) => (
									<Card key={item.number} data={item} totalValue={this.props.totalValue}/>
								))
							}
						</Slider>
					</div>
				</div>
				<div className={classes.Backdrop}/>
			</div>
		)
	}
}

export default MorePairs