import React from "react"
import AppContext from "../../../../../AppContext"
import "./style.scss"
import classes from "./FootContent.module.scss"
import DataResults from "./Results"
import Multiple from "./Results/Multiple"
import DataProgress from "./DataProgress"
import LastUpdated from "./LastUpdated"
import FootCyberjuang from "../../../../../Components/FootCyberjuang"

class FootContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      regionVotes: []
    }
  }

  static contextType = AppContext

  componentDidMount() {
    // console.log('this.context.currentDownline::::',this.context,'seko footer dashboard')
    if (this.context.currentSelected.type === 'Provinsi' && (this.context.election !== 'PILPRES' || this.context.election !== 'PILKADA GUBERNUR' || this.context.election !== 'DPD')) {
      const currentDownlinePromises = this.context.currentDownline.map(async (data, index) => {
        await this.context.getVotes(
          data.regionalID,
          data.type,
          res => {

            var item = res.data
            item.region = data
            this.setState({
              regionVotes: [
                ...this.state.regionVotes,
                item
              ]
            })
          },
          data.regionalID
        )
      })

      Promise.all(currentDownlinePromises).then(() => {
        this.context.updateRegionVotes(this.state.regionVotes, () => {
          // console.log('this.context.regionVotes::',this.context.regionVotes)
          this.setState({
            loading: false
          })
        })
      })
    }
  }

  render() {

    // console.log(this.context,'election context')
 
    return (
      // (this.context.currentSelected.type === 'Provinsi' && (this.context.election !== 'PILPRES' || this.context.election !== 'PILKADA GUBERNUR' || this.context.election !== 'DPD' ))
      (this.context.currentSelected.type === 'Provinsi'&& this.context.roles !== 'dpd' && (this.context.election !== 'PILPRES' || this.context.election !== 'PILKADA GUBERNUR' || this.context.election !== 'DPD' ))
       ?
        !this.state.loading ?
          <div className={["", classes.Grid_container].join(' ')}>
            <div className={classes.MidWrap}>
              <Multiple data={this.state.regionVotes}/>
            </div>
            <div className={classes.EndSide}>
              <FootCyberjuang/>
            </div>
            <div className={classes.EndMid}>
              <DataProgress data={this.context.currentResults}/>
            </div>
            <div className={classes.EndWrap}>
              <LastUpdated data={this.context.currentResults}/>
            </div>
          </div>
        : null
      :
        <div className={["", classes.Grid_container].join(' ')}>
          <div className={classes.MidWrap}>
            <DataResults/>
          </div>
          <div className={classes.EndSide}>
            <FootCyberjuang/>
          </div>
          <div className={classes.EndMid}>
           
            <DataProgress data={this.context.voteResults}/>
          </div>
          <div className={classes.EndWrap}>
          <LastUpdated data={this.context.voteResults}/>
          </div>
        </div>
    )
  }
}

export default FootContent
