import API from "./API"
import { AsyncStorage } from "AsyncStorage"
import jwt_decode from "jwt-decode"
import { API_Generals, API_Voteroom } from "./BaseURL"

// const GENERALS_API_URL = "http://159.89.195.233:8001"
// const VOTEROOM_API_URL = "http://159.89.195.233:8002"
// const GENERALS_API_URL = "http://demogn.pantimarhaen.id:7001"
// const VOTEROOM_API_URL = "http://demovr.pantimarhaen.id:7001"
//const GENERALS_API_URL = "http://generalsapi.test"
//const VOTEROOM_API_URL = "http://voteroomapi.test"
const GENERALS_API_URL = API_Generals
const VOTEROOM_API_URL = API_Voteroom

const auth = async () => {
  const secretData = await AsyncStorage.getItem("voteroom:secret")
  const username = await AsyncStorage.getItem("voteroom:uname")
  let response

  if (secretData) {
    const tokenDecode = jwt_decode(secretData).user
    let initData = {
      userID: tokenDecode.userID,
      cadres: tokenDecode.cadres,
      username: username,
      name: tokenDecode.name,
      photo: tokenDecode.photo,
      level: tokenDecode.level
    }
    response = initData
  } else {
    response = {
      errorCode: 401,
      errorMessage: "Unauthorized",
    }
  }

  return response
}

const login = async data => {
  const auth = `${Buffer.from(data.username + ":" + data.password).toString("base64")}`

  return API.post('auth/login', {}, {
    baseURL: `${GENERALS_API_URL}`,
    headers: {
      'Authorization': `Basic ${auth}`,
    }
  })
  .then(async res => {
    if (res.data.meta.code === 200) {
      localStorage.clear()
      console.log('localStorage.clear()')
    }

    return res.data
  })
  .catch(err => {
    console.log(err)
    return err.data
  })
}

const getAccount = async token => {
  return API.get('auth/account', {
    baseURL: `${GENERALS_API_URL}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(async res => {
    if (res.data.meta.code === 200) {
      await AsyncStorage.setItem("voteroom:userID", res.data.data.userID)
    }

    return res.data
  })
  .catch(err => {
    console.log(err)
    return err.data
  })
}

const getRoles = async (token, userID) => {
  return API.get('roles', {
    baseURL: `${VOTEROOM_API_URL}`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      body: {
        search: {
          groupOp: "AND",
          rules: [
            {
              field: "userID",
              op: "eq",
              data: userID
            }
          ],
          groups: []
        },
        page: 1,
        limit: 100
      }
    }
  })
  .then(async res => {
    if (res.data.meta.code === 200) {
      await AsyncStorage.setItem("voteroom:roles", JSON.stringify(res.data.data.rows))
    }

    return res.data
  })
  .catch(err => {
    console.log(err)
    return err.data
  })
}

const logout = async (isLogoff = false) => {
  await AsyncStorage.getItem("voteroom:uname").then(async uname => {
    await AsyncStorage.getItem("voteroom:name").then(async name => {
      await AsyncStorage.getItem("voteroom:photo").then(async photo => {
        localStorage.clear()
        if (isLogoff) {
          await AsyncStorage.setItem("voteroom:uname", uname)
          await AsyncStorage.setItem("voteroom:name", name)
          await AsyncStorage.setItem("voteroom:photo", photo)
          await AsyncStorage.setItem("isLogoff", isLogoff)
        }
      })
    })
  })
}

export {
  auth,
  login,
  logout,
  getAccount,
  getRoles
}