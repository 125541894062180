import React from "react"
import "./style.scss"
import HeadContent from "./HeadContent"
import BodyContent from "./BodyContent"
import FootContent from "./FootContent"
import AppContext from "../../../AppContext"

class Sign extends React.PureComponent {
  render() {
    return (
      <AppContext.Consumer>
        {(appContext) => (
          <div className="Sign">
            <HeadContent />
            <BodyContent
              activeUser={appContext.USER.activeUser}
              isLogoff={appContext.USER.isLogoff}
              activePage={appContext.PAGE.activePage}
            />
            <FootContent />
          </div>
        )}
      </AppContext.Consumer>
    )
  }
}

export default Sign
