import React from "react"
import AppContext from "../../AppContext"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCaretRight} from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import CustomDropdown from "../Dropdown"
import {electionRegion} from "../../Services/Others"

class RegionalBreadcrumbs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }
  
  static contextType = AppContext

  async componentDidMount() {
    this.context.changeSelectedIndex('provinces', 0)

    await electionRegion(
      this.context.globalState.periodes[this.context.selectedIndex.periodes],
      this.context.election,
      'Provinsi'
    ).then(res => {
      if (res !== undefined) {
        if (res.meta === undefined || parseInt(res.meta.code) === 401) {
          this.context.goToLogout()
        } else {
          this.context.changeGlobalState('provinces', res.data.rows, () => {
            this.setState({loading: false}, () => {
              let index = (
                this.context.defaultSelected.provinceID !== null ?
                this.context.globalState.provinces.findIndex(x => x.regionalID === this.context.defaultSelected.provinceID) : 
                this.context.selectedIndex.provinces
              )
              
              if (index !== -1) {
                this.context.dropdownClick(index, this.context.globalState.provinces[index].type)
              }
            })
          })
        }
      }
    })
  }

  handleProvinceClose = initSelect => {
    this.context.setShowBreadcrumbs({
      provinsi: initSelect ? true : false,
      kabupaten: false,
      kecamatan: false,
      kelurahan: false,
    }, () => {
      console.log('handle Province')
      this.context.changeSelectedIndex('provinces', 0)
    })
  }

  handleDistrictClose = initSelect => {
    this.context.setShowBreadcrumbs({
      provinsi: true,
      kabupaten: initSelect ? true : false,
      kecamatan: false,
      kelurahan: false,
    }, () => {

      console.log('handle district')
      this.context.changeSelectedIndex('districts', 0, () => {
        this.context.changeSelectedIndex('subdistricts', 0, () => {
          this.context.changeSelectedIndex('villages', 0, () => {
            this.context.changeCurrentDownline([], () => {
              let index = (
                this.context.defaultSelected.provinceID !== null ? 
                this.context.globalState.provinces.findIndex(x => x.regionalID === this.context.defaultSelected.provinceID) : 
                this.context.selectedIndex.provinces -1
              )
              
              this.context.changeCurrentSelected(this.context.globalState.provinces[index], () => {
                this.context.changeCurrentDownline(this.context.globalState.districts, () => {
                  if (this.context.election !== 'PILPRES' && this.context.election !== 'PILKADA GUBERNUR') {
                    console.log('ndak masul else')
                    this.context.changeRegionElection({}, () => {
                      this.context.changeVoteResults({}, () => {
                        this.context.resetMaps(this.context.currentDownline)
                      })
                    })
                  } else {
                    console.log('masul else')
                    this.context.getVotes()
                  }
                })
              })
            })
          })
        })
      })
    })
  }

  handleSubdistrictClose = initSelect => {
    this.context.setShowBreadcrumbs({
      provinsi: true,
      kabupaten: true,
      kecamatan: initSelect ? true : false,
      kelurahan: false,
    }, () => {
      console.log('handle SUBdistrict')
      this.context.changeSelectedIndex('subdistricts', 0, () => {
        this.context.changeSelectedIndex('villages', 0, () => {
          this.context.changeCurrentDownline([], () => {
            let index = (
              this.context.defaultSelected.districtID !== null ? 
              this.context.globalState.districts.findIndex(x => x.regionalID === this.context.defaultSelected.districtID) : 
              this.context.selectedIndex.districts -1
            )
            
            this.context.changeCurrentSelected(this.context.globalState.districts[index], () => {
              this.context.changeCurrentDownline(this.context.globalState.subdistricts, () => {
                this.context.getVotes()
                this.context.resetMaps(this.context.currentDownline)
              })
            })
          })
        })
      })
    })
  }

  handleVillageClose = initSelect => {
    this.context.setShowBreadcrumbs({
      provinsi: true,
      kabupaten: true,
      kecamatan: true,
      kelurahan: initSelect ? true : false,
    }, () => {
      console.log('handle Village')
      this.context.changeSelectedIndex('villages', 0, () => {
        this.context.changeCurrentDownline([], () => {
          let index = (
            this.context.defaultSelected.subdistrictID !== null ? 
            this.context.globalState.subdistricts.findIndex(x => x.regionalID === this.context.defaultSelected.subdistrictID) : 
            this.context.selectedIndex.subdistricts -1
          )
          
          this.context.changeCurrentSelected(this.context.globalState.subdistricts[index], () => {
            this.context.changeCurrentDownline(this.context.globalState.villages, () => {
              this.context.getVotes()
              this.context.resetMaps(this.context.currentDownline)
            })
          })
        })
      })
    })
  }

  render() {

    return (
    !this.state.loading ?
      <AppContext.Consumer>
        {(appContext) =>{
          // console.log(appContext,'contatx')
          return  <React.Fragment>
          <Breadcrumbs
            separator={<FontAwesomeIcon icon={faCaretRight} size={"2x"}/>}
            aria-label="breadcrumb"
            style={{fontSize: '10px', color: '#6e6e6e'}}
            >
            <CustomDropdown
              define={"provinces"}
              title={"PROVINSI"}
              primary={"regionalID"}
              label={"name"}
              color={this.props.color}
              initSelect={true}
              withClose={appContext.defaultSelected.provinceID === null ? true : false}
              lockValue={appContext.defaultSelected.provinceID !== null ? true : false}
              data={appContext.globalState.provinces}
              defaultSelected={appContext.defaultSelected.provinceID}
              handleClose={this.handleProvinceClose}
            />
            {
              appContext.showBreadcrumbs.kabupaten ? 
              <CustomDropdown
                define={"districts"}
                title={"KABUPATEN/KOTA"}
                primary={"regionalID"}
                label={"name"}
                color={this.props.color}
                initSelect={true}
                withClose={appContext.defaultSelected.districtID === null ? true : false}
                lockValue={appContext.defaultSelected.districtID !== null ? true : false}
                data={appContext.globalState.districts}
                defaultSelected={appContext.defaultSelected.districtID}
                handleClose={this.handleDistrictClose}
              /> : null
            }
            {
              appContext.showBreadcrumbs.kecamatan ? 
              <CustomDropdown
                define={"subdistricts"}
                title={"KECAMATAN"}
                primary={"regionalID"}
                label={"name"}
                color={this.props.color}
                initSelect={true}
                withClose={appContext.defaultSelected.subdistrictID === null ? true : false}
                lockValue={appContext.defaultSelected.subdistrictID !== null ? true : false}
                data={appContext.globalState.subdistricts}
                defaultSelected={appContext.defaultSelected.subdistrictID}
                handleClose={this.handleSubdistrictClose}
              /> : null
            }
            {/* {
              appContext.showBreadcrumbs.kelurahan ? 
              <CustomDropdown
                define={"villages"}
                title={"DESA/KELURAHAN"}
                primary={"regionalID"}
                label={"name"}
                color={this.props.color}
                initSelect={true}
                withClose={appContext.defaultSelected.villageID === null ? true : false}
                lockValue={appContext.defaultSelected.villageID !== null ? true : false}
                data={appContext.globalState.villages}
                defaultSelected={appContext.defaultSelected.villageID}
                handleClose={this.handleVillageClose}
              /> : null
            } */}
          </Breadcrumbs>
        </React.Fragment>
        }}
      </AppContext.Consumer>
      : null
    )
  }
}

export default RegionalBreadcrumbs