import React from "react"

import MorePairs from "./MorePairs"
import TwoPairs from "./TwoPairs"

class ResultsPaslon extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
			loading: true,
			totalValue: 0,
		}
	}

  totalValue() {
    var totalValue = 0
    const calculateValue = this.props.data.map(item => {
      totalValue += parseInt(item.value)
      return true
    })

    Promise.all(calculateValue).then(() => {
      this.setState({
        totalValue: totalValue
      }, () => {
        this.setState({
          loading: false
        })
      })
    })
	}
  
  render() {
    this.totalValue()

    return (
      !this.state.loading ?
        this.props.data.length > 2 ?
        <MorePairs multiple={this.props.multiple} data={this.props.data} totalValue={this.state.totalValue}/> : 
        <TwoPairs multiple={this.props.multiple} data={this.props.data} totalValue={this.state.totalValue}/>
      : null
    )
  }
}

export default ResultsPaslon