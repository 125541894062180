import React from "react"
import "./style.scss"
import HeadContent from "./HeadContent"
import BodyContent from "./BodyContent"
import FootContent from "./FootContent"
import MainContext from "../../MainContext"

class Main extends React.PureComponent {

  render() {
    return (
      <MainContext.Consumer>
        {(mainContext) => (
          <div className="Main ExtraLarge">
            <HeadContent/>
            <BodyContent/>
            <FootContent/>
          </div>
        )}
      </MainContext.Consumer>
    )
  }
}

export default Main
