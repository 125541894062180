import React from "react"
import "./style.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt, faLock } from "@fortawesome/free-solid-svg-icons"
import AppContext from "../../AppContext"
import { logout } from "../../Services/Auth"
import { API_Voteroom } from "../../Services/BaseURL"

class UserAction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isUserActionOptionActive: false,
      gantiPasswordModalRef: null,
    }
    this.userActionRef = React.createRef()
    this.handleClickKeluar = this.handleClickKeluar.bind(this)
    this.handleClickKunciLayar = this.handleClickKunciLayar.bind(this)
    this.handleClickGantiPassword = this.handleClickGantiPassword.bind(this)
  }

  handleClickKeluar(event, appContext) {
    this.props.handleRemoveEventClickUserActionArea()
    appContext.resetData(["activeUser", "activePage", "isLogoff"], logout)
  }

  handleClickGantiPassword(event) {
    this.state.gantiPasswordModalRef.current.handleToggleGantiPasswordModal()
    this.props.handleRemoveEventClickUserActionArea()
    setTimeout(() => {
      this.setState({
        isUserActionOptionActive: false,
      })
    }, 100)
  }

  handleClickKunciLayar(event, appContext) {
    appContext.USER.changeLogoffStatus(() => {
      logout(true)
    })
  }

  componentDidMount() {
    this.setState({
      gantiPasswordModalRef: this.props.gantiPasswordModalRef,
    })
  }

  render() {
    return (
      <AppContext.Consumer>
        {(appContext) => (
          <React.Fragment>
            <div
              className={`UserAction${
                this.state.isUserActionOptionActive ? " Active" : ""
              }`}
              ref={this.userActionRef}
							style={{zIndex: 999999}}>
              <div
                className="Photo"
                style={{
                  //backgroundImage: `url(https://generalsapi.pantimarhaen.id/${appContext.USER.activeUser.photo})`,
                  backgroundImage: `url(${API_Voteroom}/${appContext.USER.activeUser.photo})`,
                  //backgroundImage: `url(http://generalsapi.test/${appContext.USER.activeUser.photo})`,
                }}
              ></div>
              <div className="Name">
                {
                  appContext.USER.activeUser.name ?
                  appContext.USER.activeUser.name.toLowerCase()
                  : "Anonymous"
                }
              </div>
              {appContext.USER.activeUser.cadres &&
                appContext.USER.activeUser.cadres.KTA && (
                  <div className="noKTAArea">
                    <span className="noKTA CreditCardFont">
                      {appContext.USER.activeUser.cadres.KTA}
                    </span>
                  </div>
                )}
              <ul className="GoToWrapper">
                {/* <li
                  className="GoTo Profile"
                  onClick={(event) =>
                    this.handleClickProfilSaya(event, appContext)
                  }
                >
                  <span className="Icon">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <span className="Name">Profil Saya</span>
                </li> */}
                {/* <li
                  className="GoTo ChangePassword"
                  onClick={this.handleClickGantiPassword}
                >
                  <span className="Icon">
                    <FontAwesomeIcon icon={faKey} />
                  </span>
                  <span className="Name">Ganti Password</span>
                </li> */}
                <li className="Separator">
                  <div className="InnerSeparator"></div>
                </li>
                {/* <li
                  className="GoTo LockScreen"
                  onClick={(event) =>
                    this.handleClickKunciLayar(event, appContext)
                  }
                >
                  <span className="Icon">
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <span className="Name">Kunci Layar</span>
                </li> */}

                <li
                  className="GoTo Logout"
                  onClick={(event) => this.handleClickKeluar(event, appContext)}
                >
                  <span className="Icon">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </span>
                  <span className="Name">Keluar</span>
                </li>
              </ul>
            </div>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    )
  }
}

export default UserAction