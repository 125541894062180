import React from "react"
import classes from "./Sticky.module.scss"

const Sticky = (props) => {
	return (
		<div className={classes.Sticky_container}>
			<div className={classes.Sticky_background}/>
			<div className={classes.Content_flex}>
				<div className={classes.Background}>
					<div className={classes.Number}>
						<div>3</div>
					</div>
				</div>
				<ul className={classes.Partai_ul}>
					<li><img className={classes.Sticky_logo} src={require('../../../../../../../../Assets/Logos/logo_pdi.png')} alt=""/></li>
					<li>
						<div className={classes.Count_info}>
							<div>20%</div><span>43.980</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Sticky