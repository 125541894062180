import React, { Component } from "react"
import AppContext from "../../../../../../../../AppContext"
import classes from "./TwoPairs.module.scss"
import './TwoPairs.scss'
import Progress from "reactstrap/lib/Progress"
import { API_Generals, API_Voteroom } from "../../../../../../../../Services/BaseURL"
import { DATA_PASLON } from "../../../../../../../../Assets/dummy/paslon"

var numeral = require('numeral')

const myStyle = (params) => ({
	backgroundColor: params,
})

class TwoPairs extends Component {
  static contextType = AppContext

	render() {
		
		const paslon1 = DATA_PASLON.find((item)=> item.paslonID === this.props?.data?.[0]?.paslonID)?.photo
		const paslon2 = DATA_PASLON.find((item)=> item.paslonID === this.props?.data?.[1]?.paslonID)?.photo
	
		
		// console.log(paslon2)
		// const dataPaslon = `${API_Voteroom}/assets/`+DATA_PASLON[0]?.photo
		

		return (
			<div className={classes.Card_container}>
				
				<img className={classes.Image_left}
					// src={dataPaslon} 
					src={paslon1} 
					alt="Paslon1" />
				<img className={classes.Image_right}
					// src={`${API_Generals}/assets/` + this.props?.data?.[1]?.photo}
					src={paslon2} 
					 alt="Paslon2" />
				<div className={classes.Bot}>
					<Progress multi className={"custom-progress-bar"}>
						<Progress bar style={myStyle(`${this.props.data?.[0]?.color}`)}
							value={this.props.totalValue > 0 ? Number(((this.props.data?.[0]?.value / this.props.totalValue) * 100).toFixed(2)) : 50}>
							{this.props.data?.[0]?.value > 0 ? Number(((this.props.data?.[0]?.value / this.props.totalValue) * 100).toFixed(2)) : 0}%
						</Progress>
						<Progress bar style={myStyle(`${this.props.data?.[1]?.color}`)}
							value={this.props.totalValue > 0 ? Number(((this.props.data?.[1]?.value / this.props.totalValue) * 100).toFixed(2)) : 50}>
							{this.props.data?.[1]?.value > 0 ? Number(((this.props.data?.[1]?.value / this.props.totalValue) * 100).toFixed(2)) : 0}%
						</Progress>
					</Progress>
				</div>
				<div className={classes.Mid_left}>
					<div>
						<div className={classes.Total}>{numeral(this.props.data?.[0]?.value).format()}</div>
						{/* <div className={classes.Total}>{this.context.abbreviateNumber(this.props.data?.[0]?.value)}</div> */}
						<span>{this.props.data?.[0]?.name}</span>
					</div>
				</div>
				<div className={classes.Mid_right}>
					<div>
						<div className={classes.Total}>{numeral(this.props.data?.[1]?.value).format()}</div>
						{/* <div className={classes.Total}>{this.context.abbreviateNumber(this.props.data?.[1]?.value)}</div> */}
						<span>{this.props.data?.[1]?.name}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default TwoPairs