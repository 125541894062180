import React from "react"
import classes from './Analyzt.module.scss'
import AppContext from "../../AppContext"
import { votes } from "../../Services/Votes"

import { Table, Tag } from "antd"
import "antd/dist/antd.css"

var numeral = require('numeral')

class AnalystKemenangan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      loading: true,
      columnsPrint: [],
      columns: [
        {
          title: 'No.',
          dataIndex: 'key',
          key: 'key',
          width: 20,
          fixed: 'left',
          render: (text, record) => {
            return record.key + 1
          }
        },
        {
          title: 'Wilayah',
          dataIndex: 'regional',
          key: 'regional',
          width: 200,
          fixed: 'left',
        },
        {
          title: 'Data Pemilih',
          dataIndex: 'voterT',
          key: 'voterT',
          width: '7%',
          responsive: ['md'],
          align: 'center',
          render: (text, record) => {
            return <div style={{textAlign: 'right'}}>{numeral(record.voterT).format()}</div>
          }
        },
        {
          title: 'Pengguna Hak Pilih',
          dataIndex: 'voterVT',
          key: 'voterVT',
          width: '7%',
          responsive: ['md'],
          align: 'center',
          render: (text, record) => {
            return <div style={{textAlign: 'right'}}>{numeral(record.voterVT).format()}</div>
          }
        },
        {
          title: 'Data Penggunaan Surat Suara',
          children: [
            {
              title: 'Diterima Termasuk Cadangan',
              dataIndex: 'allocated',
              key: 'allocated',
              width: '7%',
              responsive: ['md'],
              align: 'center',
              render: (text, record) => {
                return <div style={{textAlign: 'right'}}>{numeral(record.allocated).format()}</div>
              }
            },
            {
              title: 'Dikembalikan',
              dataIndex: 'broken',
              key: 'broken',
              width: '7%',
              responsive: ['md'],
              align: 'center',
              render: (text, record) => {
                return <div style={{textAlign: 'right'}}>{numeral(record.broken).format()}</div>
              }
            },
            {
              title: 'Digunakan',
              dataIndex: 'used',
              key: 'used',
              width: '7%',
              responsive: ['md'],
              align: 'center',
              render: (text, record) => {
                return <div style={{textAlign: 'right'}}>{numeral(record.used).format()}</div>
              }
            },
            {
              title: 'Tidak Digunakan Termasuk Cadangan',
              dataIndex: 'unused',
              key: 'unused',
              width: '7%',
              responsive: ['md'],
              align: 'center',
              render: (text, record) => {
                return <div style={{textAlign: 'right'}}>{numeral(record.unused).format()}</div>
              }
            },
            {
              title: 'Suara Sah',
              dataIndex: 'valid',
              key: 'valid',
              width: '7%',
              responsive: ['md'],
              align: 'center',
              render: (text, record) => {
                return <div style={{textAlign: 'right'}}>{numeral(record.valid).format()}</div>
              }
            },
            {
              title: 'Suara Tidak Sah',
              dataIndex: 'invalid',
              key: 'invalid',
              width: '7%',
              responsive: ['md'],
              align: 'center',
              render: (text, record) => {
                return <div style={{textAlign: 'right'}}>{numeral(record.invalid).format()}</div>
              }
            },
            {
              title: 'Suara Sah dan Tidak Sah',
              dataIndex: 'usedT',
              key: 'usedT',
              width: '7%',
              responsive: ['md'],
              align: 'center',
              render: (text, record) => {
                return <div style={{textAlign: 'right'}}>{numeral(record.usedT).format()}</div>
              }
            },
            {
              title: 'Partisipasi Pemilih',
              dataIndex: 'partisipation',
              key: 'partisipation',
              width: '10%',
              responsive: ['md'],
              align: 'center',
            },
          ],
        },
      ],
      rows: []
    }
  }

  static contextType = AppContext

  stringPadLeft = (number, length, delimeter = '0') => {
    return Array(length - String(number).length + 1).join(delimeter) + number
  }

  hexToRgb = hex => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  componentDidMount() {
    const dataVote = []
    const votePromises = this.props.data.map(async (item, index) => {
      await votes(
        this.context.globalState.periodes[this.context.selectedIndex.periodes],
        this.context.election,
        this.context.regionElection.regionalID,
        this.context.typeToDoc((item.type !== undefined ? item.type : "TPS")),
        (item.regionalID !== undefined ? item.regionalID : item.pollstationID),
        (item.type !== undefined ? item.type : "TPS"),
      ).then(res => {
        var provinceID = (
          this.context.defaultSelected.provinceID !== null ?
            this.context.globalState.provinces.findIndex(x => x.regionalID === this.context.defaultSelected.provinceID) :
            this.context.selectedIndex.provinces
        )
        var districtID = (
          this.context.defaultSelected.districtID !== null ?
            this.context.globalState.districts.findIndex(x => x.regionalID === this.context.defaultSelected.districtID) :
            this.context.selectedIndex.districts
        ) - 1
        var subdistrictID = (
          this.context.defaultSelected.subdistrictID !== null ?
            this.context.globalState.subdistricts.findIndex(x => x.regionalID === this.context.defaultSelected.subdistrictID) :
            this.context.selectedIndex.subdistricts
        ) - 1
        var villageID = (
          this.context.defaultSelected.villageID !== null ?
            this.context.globalState.villages.findIndex(x => x.regionalID === this.context.defaultSelected.villageID) :
            this.context.selectedIndex.villages
        ) - 1
        var pollstationID = (
          this.context.defaultSelected.pollstationID !== null ?
            this.context.globalState.pollstations.findIndex(x => x.regionalID === this.context.defaultSelected.pollstationID) :
            this.context.selectedIndex.pollstations
        ) - 1

        var lastWin = 0
        var totalValue = 0
        var winner = null
        var sticky = null
        var sortRanks = []
        res.data.results.map(async (item, index) => {
          totalValue += item.value
          if (item.value > 0) {
            if (lastWin < item.value) {
              winner = item.paslonID
              lastWin = item.value
            }
          }

          if (item.sticky === 'Y') {
            sticky = item
          }
            
          sortRanks.push(item)
        })
          
        const ranks = sortRanks.sort((a, b) => b.value - a.value)
        const indexRanks = (ranks.findIndex(item => item.sticky === 'Y'))+1
        
        dataVote.push({
          key: index,
          regional: item.number !== undefined ? 'TPS ' + this.stringPadLeft(item.number, 3) : item.name,
          provinceID: item.type === 'Provinsi' ? item.regionalID : (provinceID !== -1 ? this.context.globalState.provinces[provinceID].regionalID : null),
          districtID: item.type === 'Kabupaten' || item.type === 'Kota' ? item.regionalID : (districtID !== -1 ? this.context.globalState.districts[districtID].regionalID : null),
          subdistrictID: item.type === 'Kecamatan' ? item.regionalID : (subdistrictID !== -1 ? this.context.globalState.subdistricts[subdistrictID].regionalID : null),
          villageID: item.type === 'Desa' || item.type === 'Kelurahan' ? item.regionalID : (villageID !== -1 ? this.context.globalState.villages[villageID].regionalID : null),
          pollstationID: item.number !== undefined ? item.pollstationID : (pollstationID !== -1 ? this.context.globalState.pollstations[pollstationID].pollstationID : null),
          zoneID: this.context.defaultSelected.zoneID,
          periode: parseInt(this.context.globalState.periodes[this.context.selectedIndex.periodes]),
          election: this.context.election,
          document: this.context.typeToDoc((item.type !== undefined ? item.type : "TPS")),
          dptL: res.data.dpt.data.dptL,
          dptP: res.data.dpt.data.dptP,
          dptT: res.data.dpt.data.dptT,
          dptbL: res.data.dpt.data.dptbL,
          dptbP: res.data.dpt.data.dptbP,
          dptbT: res.data.dpt.data.dptbT,
          dpkL: res.data.dpt.data.dpkL,
          dpkP: res.data.dpt.data.dpkP,
          dpkT: res.data.dpt.data.dpkT,
          voterL: res.data.dpt.data.voterL,
          voterP: res.data.dpt.data.voterP,
          voterT: res.data.dpt.data.voterT,
          dptVL: res.data.dpt.input.dptL,
          dptVP: res.data.dpt.input.dptP,
          dptVT: res.data.dpt.input.dptT,
          dptbVL: res.data.dpt.input.dptbL,
          dptbVP: res.data.dpt.input.dptbP,
          dptbVT: res.data.dpt.input.dptbT,
          dpkVL: res.data.dpt.input.dpkL,
          dpkVP: res.data.dpt.input.dpkP,
          dpkVT: res.data.dpt.input.dpkT,
          voterVL: res.data.dpt.input.voterL,
          voterVP: res.data.dpt.input.voterP,
          voterVT: res.data.dpt.input.voterT,
          diffableL: res.data.dpt.data.diffableL,
          diffableP: res.data.dpt.data.diffableP,
          diffableT: res.data.dpt.data.diffableT,
          diffableVL: res.data.dpt.input.diffableL,
          diffableVP: res.data.dpt.input.diffableP,
          diffableVT: res.data.dpt.input.diffableT,
          allocated: res.data.use.allocated,
          broken: res.data.use.broken,
          valid: res.data.use.valid,
          invalid: res.data.use.invalid,
          usedT: res.data.use.usedT,
          used: res.data.use.used,
          unused: res.data.use.unused,
          results: res.data.results,
          totalValue: totalValue,
          winner: winner,
          different: winner !== null && winner !== sticky?.paslonID ? numeral(parseInt(lastWin)-parseInt(sticky?.value)).format() : 0,
          rank: indexRanks,
          win: winner !== null ? (winner === sticky?.paslonID ? 'Menang' : 'Kalah') : '-',
          partisipation: ((parseInt(res.data.dpt.data.dptT)+parseInt(res.data.dpt.data.dpkT)+parseInt(res.data.dpt.data.dptbT)) !== 0 ?
          (((parseInt(res.data.dpt.input.dptT)+parseInt(res.data.dpt.input.dpkT)+parseInt(res.data.dpt.input.dptbT))/(parseInt(res.data.dpt.data.dptT)+parseInt(res.data.dpt.data.dpkT)+parseInt(res.data.dpt.data.dptbT)))*100).toFixed(2) : 0) + '%',
          problem1: winner !== null ? ((parseInt(res.data.dpt.input.voterT) === parseInt(res.data.use.usedT)) ? 'Sesuai' : 'Tidak Sesuai') : '-',
          problem2: winner !== null ? ((parseInt(res.data.use.valid) === parseInt(totalValue)) ? 'Sesuai' : 'Tidak Sesuai') : '-',
          problem3: winner !== null ? ((parseInt(res.data.use.allocated) - Math.ceil(parseInt(res.data.dpt.data.dptT)+(parseInt(res.data.dpt.data.dptT)*0.025))) !== 0 ? 'Tidak Sesuai' : 'Sesuai') : '-',
          problem4: winner !== null ? (parseInt(res.data.use.unused) !== (parseInt(res.data.use.allocated) - (parseInt(res.data.use.used)+parseInt(res.data.use.broken))) ? 'Tidak Sesuai' : 'Sesuai') : '-',
        })
      })
    })

    Promise.all(votePromises).then(() => {
      const data = dataVote.sort((a, b) => a.key - b.key)
      this.setState({
        rows: data
      }, () => {
        let candidates = []
        const candidatesPromises = this.props.candidates.map((item, index) => {
          candidates.push({
            title: () => {
              return <div key={index} className={classes.paslonName} color={'#'+item.color}>
                <span>{item.name}</span>
              </div>
            },
            onHeaderCell: column => {
              var obj = document.getElementsByClassName(column.title.props.className)
              Array.prototype.slice.call(obj).forEach((element, index) => {
                element.parentElement.style.backgroundColor = element.attributes[1].nodeValue
              })
            },
            children: [
              {
                title: 'Perolehan',
                dataIndex: 'candidate' + index,
                key: 'candidate' + index,
                align: 'center',
                width: 100,
                responsive: ['md'],
                render: (text, record) => {
                  let color = 'green'
                  if (record.winner !== record.results[index].paslonID) {
                    color = 'volcano'
                  }
                  var rgb = this.hexToRgb('#'+record.results[index].color)
                  return {
                    props: {
                      style: {
                        background: 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ', 0.05)'
                      }
                    }, 
                    children: record.win !== '-' ? (
                      <Tag color={color} key={record.winner} style={{textAlign: 'right'}}>
                        <div>{numeral(record.results[index].value).format()}</div>
                      </Tag>
                    ) : '-'
                  }
                }
              },
              {
                title: '%',
                dataIndex: 'candidatepersen' + index,
                key: 'candidatepersen' + index,
                align: 'center',
                width: 100,
                responsive: ['md'],
                render: (text, record) => {
                  var rgb = this.hexToRgb('#'+record.results[index].color)
                  return {
                    props: {
                      style: {
                        background: 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ', 0.05)'
                      }
                    }, 
                    children: <div style={{textAlign: 'right'}}>{(record.totalValue > 0 ? ((record.results[index].value / record.totalValue)*100).toFixed(2) : 0)}%</div>
                  }
                }
              },
            ]
          })
          return true
        })

        Promise.all(candidatesPromises).then(() => {
          this.setState({
            columnsPrint: [
              {
                title: () => {
                  return <div>{this.context.election !== null ? <h5>ANALISA KEMENANGAN PADA <span>{this.context.election}</span> <span>{this.context.regionElection.name !== undefined ? this.context.regionElection.name.toUpperCase() : null}</span></h5> : ''}</div>
                },
                children: [
                  {
                    title: () => {
                      return <div className="titlePageHeader">
                        <span>{this.context.globalState.provinces[this.context.selectedIndex.provinces-1] !== undefined ? 'PROVINSI '+this.context.globalState.provinces[this.context.selectedIndex.provinces-1].name.toUpperCase() : ''}</span>
                        <span>{this.context.globalState.districts[this.context.selectedIndex.districts-1] !== undefined ? ' KABUPATEN/KOTA ' + this.context.globalState.districts[this.context.selectedIndex.districts-1].name.replace('KOTA ', '').toUpperCase() : ''}</span>
                        <span>{this.context.globalState.subdistricts[this.context.selectedIndex.subdistricts-1] !== undefined ? ' KECAMATAN ' + this.context.globalState.subdistricts[this.context.selectedIndex.subdistricts-1].name.toUpperCase() : ''}</span>
                        <span>{this.context.globalState.villages[this.context.selectedIndex.villages-1] !== undefined ? ' DESA/KELURAHAN ' + this.context.globalState.villages[this.context.selectedIndex.villages-1].name.toUpperCase() : ''}</span>
                      </div>
                    },
                    children: [
                      ...this.state.columns,
                      {
                        title: 'Rincian Perolehan Suara',
                        children: candidates
                      },
                      {
                        title: 'Menang/Kalah',
                        children: [
                          {
                            title: 'Peringkat',
                            dataIndex: 'rank',
                            key: 'rank',
                            width: '10%',
                            responsive: ['md'],
                            align: 'center',
                          },
                          {
                            title: 'Status',
                            dataIndex: 'win',
                            key: 'win',
                            width: '10%',
                            responsive: ['md'],
                            align: 'center',
                            render: (text, record) => {
                              let color = 'green'
                              if (record.win === 'Kalah') {
                                color = 'volcano'
                              }
                              return record.win !== '-' ? (
                                <Tag color={color} key={record.win}>
                                  {record.win.toUpperCase()}
                                </Tag>
                              ) : '-'
                            }
                          },
                          {
                            title: 'Selisih',
                            dataIndex: 'different',
                            key: 'different',
                            width: '10%',
                            responsive: ['md'],
                            align: 'center',
                            render: (text, record) => {
                              let color = 'green'
                              if (record.different !== 0) {
                                color = 'volcano'
                              }
                              return record.different !== 0 ? (
                                <Tag color={color} key={record.different}>
                                  {record.different}
                                </Tag>
                              ) : '-'
                            }
                          },
                        ]
                      },
                    ]
                  }
                ]
              }
            ],
            columns: [
              ...this.state.columns,
              {
                title: 'Rincian Perolehan Suara',
                children: candidates
              },
              {
                title: 'Menang/Kalah',
                children: [
                  {
                    title: 'Peringkat',
                    dataIndex: 'rank',
                    key: 'rank',
                    width: '10%',
                    responsive: ['md'],
                    align: 'center',
                  },
                  {
                    title: 'Status',
                    dataIndex: 'win',
                    key: 'win',
                    width: '10%',
                    responsive: ['md'],
                    align: 'center',
                    render: (text, record) => {
                      let color = 'green'
                      if (record.win === 'Kalah') {
                        color = 'volcano'
                      }
                      return record.win !== '-' ? (
                        <Tag color={color} key={record.win}>
                          {record.win.toUpperCase()}
                        </Tag>
                      ) : '-'
                    }
                  },
                  {
                    title: 'Selisih',
                    dataIndex: 'different',
                    key: 'different',
                    width: '10%',
                    responsive: ['md'],
                    align: 'center',
                    render: (text, record) => {
                      let color = 'green'
                      if (record.different !== 0) {
                        color = 'volcano'
                      }
                      return record.different !== 0 ? (
                        <Tag color={color} key={record.different}>
                          {record.different}
                        </Tag>
                      ) : '-'
                    }
                  },
                ]
              },
            ],
            loading: false
          })
        })
      })
    })
  }

  render() {
    return (
      <div>
        <div style={{display: "none"}}>
          <div ref={this.props.propRef} style={{overflow: 'hidden'}}>
            <Table
              loading={this.state.loading}
              columns={this.state.columnsPrint}
              dataSource={this.state.rows}
              pagination={false}
              bordered
              size="middle"
            />
          </div>
        </div>
        <Table
          loading={this.state.loading}
          columns={this.state.columns}
          dataSource={this.state.rows}
          pagination={false}
          bordered
          size="middle"
        />
      </div>
    )
  }
}

export default AnalystKemenangan