import React from "react"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import SwipeableViews from "react-swipeable-views"
import { withRouter } from "react-router-dom"
import "./style.scss"

import UserManagementContent from "../../../../../Components/Management/UserManagementContent"
import PaslonManagementContent from "../../../../../Components/Management/PaslonManagementContent"
import DPTManagementContent from "../../../../../Components/Management/DPTManagementContent"

function TabPanel(props) {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {children}
    </div>
  )
}

class BodyContent extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      tabIndex: 0,
    }
  }

  handleChange = (event, index) => {
    this.setState({ tabIndex: index })
  }
  handleChangeIndex = (index) => {
    this.setState({ tabIndex: index })
  }

  render() {
    return (
      <React.Fragment>
        <div className="BodyContent">
          <div style={{ paddingInline: 10 }}>
            <div style={{ display: "flex", marginTop: 10, gap: 5 }}>
              {["User Management ", "Paslon Management", "DPT Management"].map(
                (item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        this.setState({
                          tabIndex: index,
                        })
                      }}
                      style={{
                        cursor: "pointer",
                        padding: 10,
                        flexGrow: 1,
                        backgroundColor: "#414141",
                        textAlign: "center",
                        color: "white",
                        fontWeight: "bolder",
                        borderBottom:
                          index === this.state.tabIndex
                            ? "3px solid red"
                            : "none",
                      }}
                    >
                      {item}
                    </div>
                  )
                }
              )}
            </div>
            {this.state.tabIndex === 0 && <UserManagementContent />}
            {this.state.tabIndex === 1 && <PaslonManagementContent />}
            {this.state.tabIndex === 2 && <DPTManagementContent />}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(BodyContent)