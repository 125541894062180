import React, {Component} from "react"
import AppContext from "../../AppContext"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import MenuItem from "@material-ui/core/MenuItem"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

class CustomDropdown extends Component {
	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null
		}
		this.handleListItemClick.bind(this)
		this.handleMenuItemClick.bind(this)
	}
  
  static contextType = AppContext

  componentDidMount() {
		let index = this.props.defaultSelected !== undefined && this.props.defaultSelected !== null ? this.context.globalState[this.props.define].findIndex(x => (this.props.primary !== null ? x[this.props.primary] === this.props.defaultSelected : x === this.props.defaultSelected)) : this.context.selectedIndex[this.props.define]
		index = this.props.defaultSelected !== undefined && this.props.defaultSelected !== null && this.props.initSelect ? index+1 : index
		
		this.context.changeSelectedIndex(this.props.define, index)
	}

	handleListItemClick = event => {
		if (!this.props.lockValue) {
			this.setState({
				anchorEl: event.currentTarget
			})
		}
	}

  handleMenuItemClick = index => {
		this.context.changeSelectedIndex(this.props.define, index, () => {
			this.setState({
				anchorEl: null
			}, () => {
				let reIndex = this.props.initSelect ? index-1 : index
				if (reIndex !== -1) {
					this.context.dropdownClick(reIndex, this.context.globalState[this.props.define][reIndex].type)
				} else {
					this.props.handleClose(this.props.initSelect)
				}
			})
		})
	}
	
	secondaryLabel = () => {
		const label = this.props.initSelect ?
		(
			parseInt(this.context.selectedIndex[this.props.define]) !== 0 ?
			(
				this.props.label !== null ? 
					this.props.data[parseInt(this.context.selectedIndex[this.props.define])-1][this.props.label] : 
					this.props.data[parseInt(this.context.selectedIndex[this.props.define])-1]
			) : 
			'PILIH '+this.props.title
		) : 
		(
		this.props.label !== null ? 
			this.props.data[parseInt(this.context.selectedIndex[this.props.define])][this.props.label] : 
			this.props.data[parseInt(this.context.selectedIndex[this.props.define])]
		)

		return label
	}
	
	render() {
		return (
			<div>
				<List component="nav" style={{zIndex:99}}>
					<ListItem
						button
						aria-haspopup="true"
						aria-controls="lock-menu"
						aria-label="when device is locked"
						onClick={event => {
							this.handleListItemClick(event)
						}}
					>
						<ListItemText
							primary={this.props.title}
							primaryTypographyProps={{ style: {fontSize: '13px', outline: 'none', textTransform: 'uppercase', color: this.props.color === 'light' ? '#fff':'#4d4d4d'} }} 
							secondary={this.secondaryLabel()}
							secondaryTypographyProps={{ style: {fontSize: '13px', fontWeight: 'bold', textTransform: 'uppercase', color: this.props.color === 'light' ? '#fff':'#4d4d4d'} }} 
						/>
						{
							this.props.withClose && parseInt(this.context.selectedIndex[this.props.define]) !== 0 ?
							<ListItemSecondaryAction>
								<IconButton onClick={event => {
									this.props.initSelect ?
									this.context.changeSelectedIndex(this.props.define, 0, () => {
										this.props.handleClose(this.props.initSelect)
									}) : this.props.handleClose(this.props.initSelect)
								}} edge="end" aria-label="delete" style={{marginTop:'-22px', marginLeft: '-20px', width: '20px', height: '20px'}}>
									<HighlightOffIcon size={"1x"} style={{fontSize: '20px', color: this.props.color === 'light' ? '#fff':'#4d4d4d'}}/>
								</IconButton>
							</ListItemSecondaryAction>
							: null
						}
					</ListItem>
				</List>
				<Menu
					id="lock-menu"
					anchorEl={this.state.anchorEl}
					keepMounted
					open={Boolean(this.state.anchorEl)}
					onClose={() => {
						this.setState({
							anchorEl: null
						})
					}}
					getContentAnchorEl={null}
					anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
					transformOrigin={{vertical: 'top', horizontal: 'center'}}
				>
					{
						this.props.initSelect ?
							<MenuItem
								key={null}
								selected={parseInt(this.context.selectedIndex[this.props.define]) === 0}
								onClick={event => this.handleMenuItemClick(0)}>
								{'PILIH '+this.props.title}
							</MenuItem>
						: null
					}
					{
						this.props.data.length > 0 ?
						this.props.data.map((option, index) => (
							<MenuItem
								key={this.props.primary !== null ? option[this.props.primary] : option}
								selected={
									this.props.initSelect ? 
									index+1 === parseInt(this.context.selectedIndex[this.props.define]) :
									index === parseInt(this.context.selectedIndex[this.props.define])
								}
								onClick={event => this.handleMenuItemClick((this.props.initSelect ? index+1 : index))}>
								{
									this.props.label !== null ? option[this.props.label] : option
								}
							</MenuItem>
						)) : null
					}
				</Menu>
			</div>
		)
	}
}

export default CustomDropdown