import React from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import './App.scss'
import AppContext, { AppProvider } from "./AppContext"
import MainContent from "./Screens/MainContent/Containers"
import Sign from "./Screens/Sign/Containers"
import './Print.scss'
import { MainProvider } from "./Screens/MainContent/MainContext"
import { auth } from "./Services/Auth"

class MainRoute extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  static contextType = AppContext

  async componentDidMount() {
    const _auth = await auth()
    // console.info(_auth)

    if (_auth.userID) {
      this.context.USER.changeActiveUser(_auth, () => {
        this.setState({
          isLoading: false,
        })
      })
    } else {
      this.setState({
        isLoading: false,
      })
    }
  }

  render() {
    return (
      !this.state.isLoading && (
        <Router>
          <Switch>
            <Route exact path="/">
              {
                /* this.context.USER.isLogoff || !this.context.USER.isLogoff ?
                <Sign /> : <Redirect to="/main" /> */
              }
              {
                (this.context.USER.activeUser && this.context.USER.isLogoff) ||
                (!this.context.USER.activeUser && !this.context.USER.isLogoff) ?
                <Sign /> : <Redirect to="/main" />
              }
            </Route>
            {/* <Route path="/print">
              {
                this.context.USER.activeUser && !this.context.USER.isLogoff ?
                <Print /> : <Redirect to="/" />
              }
            </Route> */}
            <Route path="/:id">
              {
                this.context.USER.activeUser && !this.context.USER.isLogoff ?
                <MainContent /> : <Redirect to="/" />
              }
            </Route>
          </Switch>
        </Router>
      )
    )
  }
}

class App extends React.PureComponent {
  render() {
    return (
      <AppProvider>
        <MainProvider>
          <div className="App">
            <MainRoute />
          </div>
        </MainProvider>
      </AppProvider>
    )
  }
}

export default App
