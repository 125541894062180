import React from "react"
import classes from "./CardPartai.module.scss"

const CardPartai = (props) => {
	return (
		<div className={classes.Card_container__grid}>
			<div className={classes.Left}>
				<img className={classes.Image}
					src={require('../../../../../../../../../Assets/dummy/partais/' + props.data.image)}
					alt="Partai"
					style={{ backgroundColor: `${props.data.color}` }} />
			</div>
			<div className={classes.Right}>
				<div>8%</div>
				<div style={{ backgroundColor: `${props.data.color}` }}>7.000</div>
			</div>
		</div>
	)
}

export default CardPartai