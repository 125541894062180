import React from "react"
import AppContext from "../../../../../../../AppContext"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import DataResults from "../Results"
import DetailVote from "./detail"
import ChartPie from "../../../../../../../Components/Charts/pie"

class ItemRegional extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      regionVotes: []
    }
  }
  
  static contextType = AppContext

  componentDidMount() {
    this.context.getVotes(
      (this.props.data.regionalID !== undefined ? this.props.data.regionalID : this.props.data.pollstationID),
      (this.props.data.type !== undefined ? this.props.data.type : "TPS"),
      res => {
        if (res !== undefined) {
          this.setState({
            regionVotes: res.data,
            loading: false
          })
        }
      },
      this.props.region
    )
  }

  stringPadLeft = (number, length, delimeter = '0') => {
    return Array(length-String(number).length+1).join(delimeter)+number
  }

  handleClick = entry => {
    let type = (this.props.data.type !== undefined ? this.props.data.type : "TPS")
    let index = this.props.index
    if (type.toLowerCase() === 'kabupaten' || type.toLowerCase() === 'kota') {
      this.context.changeSelectedIndex("districts", (index+1), () => {
        this.context.dropdownClick(index, type, entry)
      })
    } else if (type.toLowerCase() === 'kecamatan') {
      this.context.changeSelectedIndex("subdistricts", (index+1), () => {
        this.context.dropdownClick(index, type, entry)
      })
    } else if (type.toLowerCase() === 'desa' || type.toLowerCase() === 'kelurahan') {
      this.context.changeSelectedIndex("villages", (index+1), () => {
        this.context.dropdownClick(index, type, entry)
      })
    }
  }

  render() {
    console.log(this.state.regionVotes,'tateess')
    return (
      !this.state.loading ?
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={2}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={2} zeroMinWidth={true}>
              <h5>{this.props.index+1}.</h5>
            </Grid>
            <Grid item xs={10}>
              <div className={this.props.data.type !== undefined ? "custom-link" : ""}
                onClick={event => {
                  if (this.props.data.type !== undefined) {
                    this.handleClick(false)
                  }
                }}>  
                {
                  this.props.data.type !== undefined ? this.props.data.type.toUpperCase() : ''
                }
                {
                  this.props.data.number !== undefined ? 
                  <div style={{marginTop: '-5px'}}>
                    <span style={{fontSize: '24px'}}>TPS</span>
                    <span style={{fontSize: '24px', fontWeight: 'bold'}}>{this.stringPadLeft(this.props.data.number, 3)}</span>
                  </div>
                  :
                  <h5>{this.props.data.name.replace('KOTA ', '').toUpperCase()}</h5>
                }
              </div>
              {/* {
                this.context.USER.activeUser.level !== 'Executive' ?
                this.props.data.type !== undefined && this.props.region === null ? 
                <Button variant="outlined"
                  style={{
                    width: '100%',
                    color: '#212529',
                    backgroundColor: '#ffc107',
                    borderColor: '#ffc107',
                    fontSize: '12px'
                  }}
                  onClick={event => this.handleClick(true)}>
                  Lihat Form
                </Button> : null : null
              } */}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={7}>
          <DetailVote data={this.state.regionVotes}/>
        </Grid> */}
        <Grid item xs={12}>
          <div style={{width:'450px',marginInline:"auto"}}>
          <ChartPie data={this.state.regionVotes.results}/>
          <p style={{fontWeight:"bold",textAlign:"center"}}> Desa / Kelurahan Masuk : {this.state.regionVotes?.progress?.input?.daa} / {this.state.regionVotes?.progress?.data?.daa}</p>
          </div>

          <DataResults data={this.state.regionVotes.results} model="small"/>
        </Grid>
      </Grid>
      : null
    )
  }
}

export default ItemRegional