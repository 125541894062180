import API from "./API"
import { API_Generals } from "./BaseURL"


const GENERALS_API_URL = API_Generals




const MenuAccess = async()=>{
    try {
        const data = await API({
            url:GENERALS_API_URL + "/settingelection",
            method:"GET"
        })

        return data.data

    } catch (error) {
        return error        
    }
}

export { MenuAccess }