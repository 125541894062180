import React from "react"
import classes from "./PopupMenu.module.scss"
import AppContext from "../../AppContext"
import { Tooltip } from "@material-ui/core"

class PopupMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isPopupMenuOptionActive: false,
		}
		this.popupMenuRef = React.createRef()
	}

  static contextType = AppContext

	componentDidMount() {
		this.setState({ gantiPasswordModalRef: this.props.gantiPasswordModalRef, })
	}

	render() {
		return (
			<AppContext.Consumer>
				{(appContext) => (
					<React.Fragment>
						<div className={[classes.PopupAction, this.state.isPopupMenuOptionActive ? classes.PopupAction_Active : classes.PopupAction].join(' ')}
							ref={this.popupMenuRef}
							style={{zIndex:999}}>

							<ul className={classes.GoToWrapper}>
								{/*<li className={classes.Separator}><div className={classes.InnerSeparator}/></li>*/}
								<li>
									<Tooltip title="PILPRESS">
										<img onClick={() => this.props.goTo('PILPRESS')} className={classes.Photo} src={require('../../Assets/Icons/presiden.png')} alt="Presiden" />
									</Tooltip>
									<Tooltip title="DPR RI">
										<img onClick={() => this.props.goTo('DPR RI')} className={classes.Photo} src={require('../../Assets/Icons/dprri.png')} alt="DPR RI" />
									</Tooltip>
								</li>
								<li>
									<Tooltip title="PILKADA GUBERNUR">
										<img onClick={() => this.props.goTo('PILKADA GUBERNUR')} className={classes.Photo} src={require('../../Assets/Icons/gubernur.png')} alt="Gubernur" />
									</Tooltip>
									<Tooltip title="DPRD PROVINSI">
										<img onClick={() => this.props.goTo('DPRD PROVINSI')} className={classes.Photo} src={require('../../Assets/Icons/dprdprovinsi.png')} alt="DPRD Provinsi" />
									</Tooltip>
								</li>
								<li>
									<Tooltip title="PILKADA BUPATI/WALIKOTA">
										<img onClick={() => this.props.goTo('PILKADA BUPATI/WALIKOTA')} className={classes.Photo} src={require('../../Assets/Icons/bupati.png')} alt="Bupati" />
									</Tooltip>
									<Tooltip title="DPRD KABUPATEN/KOTA">
										<img onClick={() => this.props.goTo('DPRD KABUPATEN/KOTA')} className={classes.Photo} src={require('../../Assets/Icons/dprdkabupaten.png')} alt="DPRD Kabupaten" />
									</Tooltip>
								</li>
							</ul>
						</div>
					</React.Fragment>
				)}
			</AppContext.Consumer>
		)
	}
}

export default PopupMenu