import React from "react"
import AppContext from "../../../../../../../AppContext"
import Button from "@material-ui/core/Button"
import classes from './Results.module.scss'
import { API_Generals } from "../../../../../../../Services/BaseURL"
import {DATA_PASLON} from '../../../../../../../Assets/dummy/paslon'
var numeral = require('numeral')

class DataResults extends React.PureComponent {
	static contextType = AppContext

  totalValue = () => {
    let total = 0
    this.props.data.map(item => (
      total += item.value
    ))

    return total
  }

  render() {
    return (
      <div style={{display:"flex",flexDirection:'column',gap:50}}>
        {/* {
          this.props.model !== 'small' ? 
          <h4 style={{textAlign:"center"}}>Perolehan Suara Pasangan Calon</h4>
          : <h6 style={{textAlign:"center"}}>Perolehan Suara Pasangan Calon</h6>
        } */}
        {
          this.props.data.map((item, index) => {

            const paslon1 = DATA_PASLON.find((itemx)=> itemx.paslonID === item?.paslonID)?.photo

          return  <div
              key={index}
              // className="data-grid-container"
              style={{width:"100%"}}
              >
              {/* <div className="element-data" style={this.props.model !== 'small' ? {fontSize: '12px'} : {fontSize: '10px'}}> */}
              <div style={{display:"flex",justifyContent:"space-between",width:"80%",alignItems:"center",marginInline:"auto"}}>
              <div style={{display:"flex",alignItems:"center"}}>
                 <div className={classes.PaslonNumber}
                  style={{
                    display:"flex",
                    color: '#fff',
                    backgroundColor: `${item.color}`,
                    textAlign: 'center',
                    alignItems:'center',
                   justifyContent:"center",
                   borderRadius:"100%",
                    width:30,height:30
                  }}>
                       <p style={{margin:0}}>
                           {isNaN(item.number) ? 0 : item.number}
                       </p>
                  </div>

                <img
                  className="mx-2" alt="PDIP" width={this.props.model !== 'small' ? "70" : "55"}
                  style={{marginLeft: '30px'}}
                  src={paslon1}
                  />
                <span>{item.name}</span>
              </div>

                {
                  this.props.model !== 'small' ? 
                  <div style={{display:"flex",gap:'50px'}}>
                    <div className="nilai-perolehan">
                      Perolehan Suara
                      <h5>{numeral(item.value).format()} suara</h5>
                      {/* <h5>{this.context.abbreviateNumber(item.value)} suara</h5> */}
                    </div>
                    <div className="nilai-persentase">
                      <h1>{item.value > 0 ? Number(((item.value / this.totalValue()) * 100).toFixed(2)) : 0}%</h1>
                    </div>
                  </div>
                  : 
                  <div className="nilai-persentase">
                    <h2>{item.value > 0 ? Number(((item.value / this.totalValue()) * 100).toFixed(2)) : 0}%</h2>
                    <h4>{numeral(item.value).format()} suara</h4>
                    {/* <h4>{this.context.abbreviateNumber(item.value)} suara</h4> */}
                    {/* <h4 style={{marginTop:'-10px'}}>{this.context.abbreviateNumber(item.value)} suara</h4> */}
                  </div>
                }
              </div>
            </div>
  })
        }
        {/* {
          this.context.USER.activeUser.level !== 'Executive' ?
          this.props.model !== 'small' ?
          <Button variant="outlined"
            style={{
              width: '100%',
              color: '#212529',
              backgroundColor: '#ffc107',
              borderColor: '#ffc107',
              marginTop: '10px'
            }}
            onClick={event => this.context.switchMode('entry')}>
            Lihat Form
          </Button>
          : null : null
        } */}
      </div>
    )
  }
}

export default DataResults