import React from "react"
import {AsyncStorage} from "AsyncStorage"
import AppContext from "../../../../../AppContext"
import {withRouter} from "react-router-dom"
import "./style.scss"
import RegionalBreadcrumbs from "../../../../../Components/RegionalBreadcrumbs"
import CustomDropdown from "../../../../../Components/Dropdown"
import Maps from "./Maps"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import MapIcon from "@material-ui/icons/Map"
import TableChartIcon from "@material-ui/icons/TableChart"
import PieChartIcon from "@material-ui/icons/PieChart"
import GraphicEqIcon from "@material-ui/icons/GraphicEq"
import { BallClipRotateMultiple } from "react-pure-loaders"
import { periode } from "../../../../../Services/Others"

class BodyContent extends React.PureComponent {

	static contextType = AppContext

	async componentDidMount() {
		await periode().then(res => {
			
			if (res !== undefined) {
				if (res.meta === undefined || parseInt(res.meta.code) === 401) {
					this.context.goToLogout()
				} else {
					this.context.changeGlobalState('periodes', res.data, () => {
						
						this.handlePeriodeClick(this.context.selectedIndex.periodes)
						this.context.setLoading(false)
					})
				}
			}
		})
	}

	handlePeriodeClick = index => {
		this.context.changeSelectedIndex('periodes', index, async () => {
			await AsyncStorage.getItem('voteroom:roles').then(value => {
				let roles = JSON.parse(value)
				let role = roles.find(o => o.periode === parseInt(this.context.globalState.periodes[this.context.selectedIndex.periodes]))

				console.log(role,'rolePeriode')
				if (role.provinceID !== null && role.provinceID !== undefined) {
					this.context.changeDefaultSelected('provinceID', role.provinceID)
				}
				if (role.districtID !== null && role.districtID !== undefined) {
					this.context.changeDefaultSelected('districtID', role.districtID)
				}
				if (role.subdistrictID !== null && role.subdistrictID !== undefined) {
					this.context.changeDefaultSelected('subdistrictID', role.subdistrictID)
				}
				if (role.villageID !== null && role.villageID !== undefined) {
					this.context.changeDefaultSelected('villageID', role.villageID)
				}
				if (role.pollstationID !== null && role.pollstationID !== undefined) {
					this.context.changeDefaultSelected('pollstationID', role.pollstationID)
				}
				if (role.zoneID !== null && role.zoneID !== undefined) {
					this.context.changeDefaultSelected('zoneID', role.zoneID)
				}
			})
		})
	}

	goToPage = (mode, page = null) => {
		this.context.switchMode(mode, () => {
			if (page !== null) {
				this.context.PAGE.changeActivePage('/'+page, () => {
					this.props.history.push('/'+page)
				})
			}
		})
	}

	render() {
		
		return (
			<React.Fragment>
				<div className={`BoxLogin-Loader${this.context.isLoading ? " Active" : ""}`}>
					<BallClipRotateMultiple
						color={"#b71c1c"}
						loading={this.context.isLoading}
					/>
				</div>
				{
					!this.context.isLoading ?
						<div className="BodyContent">
							<div className="InnerControlContent">
								<div className="InnerControlLeft" style={{zIndex:99}}>
									<RegionalBreadcrumbs color="light"/>
								</div>
								<div className="InnerControlRight">
									{/* <CustomDropdown
										define={"periodes"}
										title={"PERIODE"}
										primary={null}
										label={null}
										color={"light"}
										data={this.context.globalState.periodes}
										defaultSelected={this.context.defaultSelected.periodeID}
										handleClick={this.handlePeriodeClick}
									/> */}
									
									<ButtonGroup disableElevation variant="contained" style={{zIndex:99}}>
										<Button color={"secondary"} ><MapIcon /></Button>
										<Button onClick={event => this.goToPage('summary', 'summary')}><PieChartIcon /></Button>
										{
											this.context.USER.activeUser.level !== 'Executive' ?
											(this.context.regionElection.regionalID !== undefined) ?
											<Button onClick={event => this.goToPage('entry', 'summary')}><TableChartIcon /></Button>
											: null : null
										}
										{/* {
											(this.context.regionElection.regionalID !== undefined) ?
											<Button onClick={event => this.goToPage('analyzt', 'summary')}><GraphicEqIcon /></Button>
											: null
										} */}
									</ButtonGroup>
								</div>
							</div>
							<div className="InnerContent">
								{
									this.context.currentDownline !== undefined &&
									this.context.currentDownline.length > 0 ?
									<Maps/>
									:
									<div className="notfound"
										style={{
											backgroundImage: `url(${require("../../../../../Assets/Miscellaneous/notfound.png")})`,
										}}
									></div>
								}
							</div>
							{
								(this.context.currentSelected?.type === 'Provinsi' && (this.context.election !== 'PILPRES' || this.state.election !== 'PILKADA GUBERNUR' || this.state.election !== 'DPD')) &&
								this.context.currentResults !== undefined && this.context.currentResults.region !== undefined ?
								<div className="Region" style={{backgroundColor: `#FFC400`}}>
									<div className="Region_Title">{this.context.currentResults.region.name}</div>
								</div> : null
							}
						</div>
					: null
				}
			</React.Fragment>
		)
	}
}

export default withRouter(BodyContent)
