import React from "react"
import "./style.scss"
import { withRouter } from "react-router-dom"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faTh, faBell } from "@fortawesome/free-solid-svg-icons"
import AppContext from "../../../../../AppContext"
import UserAction from "../../../../../Components/UserAction"
import PopupMenu from "../../../../../Components/PopupMenu"
import { API_Generals, API_Voteroom } from "../../../../../Services/BaseURL"

class HeadContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOutsidePortalOpen: false,
    }
    this.UserActionRef = React.createRef()
    this.PopupMenuRef = React.createRef()

    this.handleToggleUserActionArea = this.handleToggleUserActionArea.bind(this)
    this.handleOutsideUserActionAreaClick = this.handleOutsideUserActionAreaClick.bind(this)
    this.handleAddEventClickUserActionArea = this.handleAddEventClickUserActionArea.bind(this)
    this.handleRemoveEventClickUserActionArea = this.handleRemoveEventClickUserActionArea.bind(this)

    this.handleOutsidePortalOpen = this.handleOutsidePortalOpen.bind(this)
    this.handleOutsidePortalExit = this.handleOutsidePortalExit.bind(this)

    this.handleTogglePopupMenuArea = this.handleTogglePopupMenuArea.bind(this)
    this.handleOutsidePopupMenuAreaClick = this.handleOutsidePopupMenuAreaClick.bind(this)
    this.handleAddEventClickPopupMenuArea = this.handleAddEventClickPopupMenuArea.bind(this)
    this.handleRemoveEventClickPopupMenuArea = this.handleRemoveEventClickPopupMenuArea.bind(this)
  }

  static contextType = AppContext

  handleOutsidePortalOpen() {
    this.setState({
      isOutsidePortalOpen: true,
    })
  }

  handleOutsidePortalExit() {
    this.setState({
      isOutsidePortalOpen: false,
    })
  }

  handleOutsideUserActionAreaClick(event) {
    // console.info("WAH")
    // if (
    //   this.UserActionRef.current.userActionRef.current.contains(event.target) ||
    //   this.state.isOutsidePortalOpen
    // ) {
    //   return
    // }

    // this.handleToggleUserActionArea()
  }

  handleOutsidePopupMenuAreaClick(event) {
    // console.info("WAH")
    // if (
    //   this.UserActionRef.current.userActionRef.current.contains(event.target) ||
    //   this.state.isOutsidePortalOpen
    // ) {
    //   return
    // }

    // this.handleToggleUserActionArea()
  }

  handleAddEventClickUserActionArea() {
    document.addEventListener(
      "mousedown",
      this.handleOutsideUserActionAreaClick,
      false
    )
  }

  handleAddEventClickPopupMenuArea() {
    document.addEventListener(
      "mousedown",
      this.handleOutsidePopupMenuAreaClick,
      false
    )
  }

  handleRemoveEventClickUserActionArea() {
    document.removeEventListener(
      "mousedown",
      this.handleOutsidePopupMenuAreaClick,
      false
    )
  }

  handleRemoveEventClickPopupMenuArea() {
    document.removeEventListener(
      "mousedown",
      this.handleOutsidePopupMenuAreaClick,
      false
    )
  }

  handleToggleUserActionArea() {
    this.UserActionRef.current.setState((prevState) => ({
      isUserActionOptionActive: !prevState.isUserActionOptionActive,
    }))
    this.PopupMenuRef.current.setState({ isPopupMenuOptionActive: false, })

    if (!this.UserActionRef.current.state.isUserActionOptionActive) {
      this.handleAddEventClickUserActionArea()
    } else {
      this.handleRemoveEventClickUserActionArea()
    }
  }

  handleTogglePopupMenuArea() {
    this.PopupMenuRef.current.setState((prevState) => ({
      isPopupMenuOptionActive: !prevState.isPopupMenuOptionActive,
    }))
    this.UserActionRef.current.setState({ isUserActionOptionActive: false, })

    if (!this.PopupMenuRef.current.state.isPopupMenuOptionActive) {
      this.handleAddEventClickPopupMenuArea()
    } else {
      this.handleRemoveEventClickPopupMenuArea()
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      "click",
      this.handleOutsideUserActionAreaClick,
      false
    )
  }

  goTo = election => {
    this.context.changeElection(election, () => {
      this.context.PAGE.changeActivePage('/dashboard', () => {
        this.props.history.push('/dashboard')
      })
    })
  }

  render() {
    return (
      <AppContext.Consumer>
        {(appContext) => (
          <div className="Main-HeadContent">
            <div className="Main-Container">
              <div className="InnerHead">
                <div className="InnerHeadLeft">
                  <div className="PDIPLogoSectionMain">
                    <img
                      className="logo"
                      src={require("../../../../../Assets/Logos/logo-header.png")}
                      alt="logo_pdi"
                    />
                    {/* <ul className="TextWrapper">
                      <li>DPD PDI Perjuangan</li>
                      <li>Jawa Tengah</li>
                    </ul> */}
                  </div>
                </div>
								<div className="Title">
									{<h5>SELAMAT DATANG <span>{appContext.USER.activeUser.name ? appContext.USER.activeUser.name.toUpperCase() : "Anonymous"}</span></h5>}
								</div>
                <div className="InnerHeadRight">
                  <div className="UserArea">
                    {/* <span className="NotificationButton">
                      <FontAwesomeIcon icon={faBell} />
                    </span> */}

                    {/* <span className="GridButton" onMouseDown={this.handleTogglePopupMenuArea}>
                      <FontAwesomeIcon icon={faTh} />
                    </span> */}
                    <div className="UserPhoto"
                      //style={{ backgroundImage: `url(https://generalsapi.pantimarhaen.id/${appContext.USER.activeUser.photo})`, }}
                      style={{ backgroundImage: `url(${API_Generals}/${appContext.USER.activeUser.photo})`, }}
                      onMouseDown={this.handleToggleUserActionArea}>
                    </div>
                  </div>
                  <UserAction
                    ref={this.UserActionRef}
                    gantiPasswordModalRef={this.props.gantiPasswordModalRef}
                    handleOutsidePortalOpen={this.handleOutsidePortalOpen}
                    handleOutsidePortalExit={this.handleOutsidePortalExit}
                    handleRemoveEventClickUserActionArea={this.handleRemoveEventClickUserActionArea}
                  />
                  <PopupMenu
                    ref={this.PopupMenuRef}
                    goTo={this.goTo}
                    handleOutsidePortalOpen={this.handleOutsidePortalOpen}
                  />
                </div>
                <div className="SubHeaderContainer">
                  <div className="HeaderBox">
                    <img className="Ico" src={require('../../../../../Assets/Logos/voteroom-ico.png')} alt="Letter" />
                    <img className="Letter" src={require('../../../../../Assets/Logos/voteroom-letter.png')} alt="Voteroom Application" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AppContext.Consumer>
    )
  }
}

export default withRouter(HeadContent)
