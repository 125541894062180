import axios from "axios"
import AppContext from "../AppContext"
import { AsyncStorage } from "AsyncStorage"

let API = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

/* AsyncStorage.getItem('voteroom:secret').then(val => {
  API.defaults.headers.common['Authorization'] = `Bearer ${val}`
}) */

API.interceptors.request.use(
  (config) => {
    AsyncStorage.getItem('voteroom:secret').then(token => {
      if (token !== undefined) {
        config.headers.Authorization = `Bearer ${token}`
      }
    })
    return config
  }, error => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use((response) => {
  return response;
}, async error => {
  if (error.response !== undefined && error.response.status === 401) {
    await AsyncStorage.getItem("voteroom:uname").then(async uname => {
      await AsyncStorage.getItem("voteroom:name").then(async name => {
        await AsyncStorage.getItem("voteroom:photo").then(async photo => {
          localStorage.clear()
          await AsyncStorage.setItem("voteroom:uname", uname)
          await AsyncStorage.setItem("voteroom:name", name)
          await AsyncStorage.setItem("voteroom:photo", photo)
          AppContext.USER.changeLogoffStatus(true)
          window.location = ''
        })
      })
    })
  }
  return Promise.reject(error)
})

export default API