import React, {Component} from "react"
import classes from "./Carousel.module.scss"
import Slider from "@ant-design/react-slick"
import CardPartai from "./Card";

class Carousel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			my_list: [
				{id: 1, color: '#2F308A', name: 'Demokdrat', image: 'demokrat.png'},
				{id: 2, color: '#004426', name: 'PKB', image: 'pkb-circle.png'},
				{id: 3, color: '#950B00', name: 'Gerindra', image: 'gerindra.png'},
				{id: 4, color: '#FFBE0C', name: 'Golkar', image: 'golkar.png'},
				{id: 5, color: '#2F308A', name: 'Demokdrat', image: 'demokrat.png'},
				{id: 6, color: '#004426', name: 'PKB', image: 'pkb-circle.png'},
				{id: 7, color: '#950B00', name: 'Gerindra', image: 'gerindra.png'},
				{id: 8, color: '#FFBE0C', name: 'Golkar', image: 'golkar.png'},
			]
		}
	}

	render() {
		const settings = {
			slide: true,
			dots: false,
			infinite: true,
			slidesToShow: 6,
			slidesToScroll: 6,
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 5000,
			cssEase: "ease-in"
		};

		return (
			<div className={classes.Carousel_container}>
				<div className={classes.Carousel_background}/>
				<div className={classes.Content}>
					<div style={{maxWidth: '1225px', zIndex: '9', height: '100%', border: '0', margin: '0 auto'}}>
						<Slider arrows={false} {...settings} style={{maxWidth: "1800px", boxSizing: "border-box", height: '100%', margin: '0 auto'}}>
							{this.state.my_list.map((item) => {
								return (
									<CardPartai key={item.id} data={item}/>
								)
							})}
						</Slider>
					</div>
				</div>
			</div>
		)
	}
}

export default Carousel