import React from "react"
import "./style.scss"
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom"
import IdleTimer from "react-idle-timer"
import AppContext from "../../../AppContext"
import {logout} from "../../../Services/Auth"
import Main from "../Contents/Main"
import Summary from "../Contents/Summary"
import Dashboard from "../Contents/Dashboard/Dashboard"
import Management from "../Contents/Management"

class MainContent extends React.PureComponent {
	constructor(props) {
		super(props)
		this.idleTimer = null
		this.state = {
			timeout: 50000000,
			remaining: null,
			isIdle: false,
			lastActive: null,
			redirect: null,
		}
		this.onActive = this._onActive.bind(this)
		this.onIdle = this._onIdle.bind(this)
	}

	static contextType = AppContext

	_onActive() {
		this.setState({isIdle: false})
	}

	_onIdle() {
		this.setState({isIdle: true})
	}



	componentDidMount() {
		this.setState({
			remaining: this.idleTimer.getRemainingTime(),
			lastActive: this.idleTimer.getLastActiveTime(),
		})

		// this.activeStatusInterval = setInterval(() => {
		// 	this.setState(
		// 		{
		// 			remaining: this.idleTimer.getRemainingTime(),
		// 			lastActive: this.idleTimer.getLastActiveTime(),
		// 		},
		// 		() => {
		// 			if (this.state.remaining === 0) {
		// 				clearInterval(this.activeStatusInterval)
		// 				this.context.USER.changeLogoffStatus(() => {
		// 					logout()
		// 					this.setState({
		// 						redirect: "/",
		// 					})
		// 				})
		// 			}
		// 		}
		// 	)
		// }, 1000)
	}

	// componentWillUnmount() {
	// 	clearInterval(this.activeStatusInterval)
	// }
	render() {
		return this.state.redirect ? (
			<Redirect to={this.state.redirect}/>
		) : (
			<React.Fragment>
				<IdleTimer
					ref={(ref) => {
						this.idleTimer = ref
					}}
					onActive={this.onActive}
					onIdle={this.onIdle}
					timeout={this.state.timeout}
					startOnLoad
				/>
				<div className="MainContent">
					<Router>
						<Switch>
							<Route path="/main" component={Main}/>
							<Route path="/dashboard" component={Dashboard}/>
							<Route path="/summary" component={Summary}/>
							<Route path="/management" component={Management} />
						</Switch>
					</Router>
				</div>
			</React.Fragment>
		)
	}
}

export default MainContent
