import React from "react"
import "./style.scss"
import {withRouter} from "react-router-dom"
import HeadContent from "./HeadContent"
import BodyContent from "./BodyContent"
import MainContext from "../../MainContext"

class Summary extends React.PureComponent {

  render() {
    return (
      <MainContext.Consumer>
        {(mainContext) => (
          <div className="Main ExtraLarge">
            <HeadContent/>
            <BodyContent/>
          </div>
        )}
      </MainContext.Consumer>
    )
  }
}

export default withRouter(Summary)
