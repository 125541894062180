import API from "./API"
import { API_Voteroom, API_Generals } from "./BaseURL"

const getUserManagement = async (limit = 10, page = 1,name=null) => {
  //   console.log(limitUser, pageUser)
  try {
    const results = await API({
      baseURL: API_Generals,
      url: "/users",
      method: "GET",
      params: {
        body: {
          query: name,
            // search: {
            //   groupOp: "AND",
            //   rules: [
            //     {
            //       field: "name",
            //       op: "like",
            //       data: `%${name}%`,
            //     },
            //   ],
            //   groups: [],
            // },
          page,
          limit,
          sorts: [
            {
              field: "name",
              mode: "ASC",
            },
          ],
        },
      },
    })
    return results.data
  } catch (err) {
    return err
  }
}
const getPaslonManagement = async (limit = 10, page = 1,name =null) => {
  //   console.log(limitUser, pageUser)
  try {
    const results = await API({
      baseURL: API_Voteroom,
      url: "/paslon",
      method: "GET",
      params: {
        body: {
          query: name,
          // search: {
          //   groupOp: "AND",
          //   rules: [
          //     {
          //       field: "paslonID",
          //       op: "eq",
          //       data: "value",
          //     },
          //   ],
          //   groups: [],
          // },
          page,
          limit,
          sorts: [
            {
              field: "paslonID",
              mode: "ASC",
            },
          ],
        },
      },
    })

    // console.log(results  )
    return results.data
  } catch (err) {
    return err
  }
}

const createUpdateUserManagement = (form, type, next) => {
  API({
    baseURL: API_Generals,
    url: "/users",
    method: type,
    data: form,
  })
    .then((res) => {
      console.log(res)
      next(true)
    })
    .catch(() => {
      next(false)
    })
}
const deleteUserManagement = (userID, next) => {
  API({
    baseURL: API_Generals,
    url: "/users",
    method: "DELETE",
    data: {
      userID,
    },
  })
    .then((res) => {
      console.log(res)
      next(true)
    })
    .catch(() => {
      next(false)
    })
}

const createUpdatePaslonManagement = (form, type, next) => {
  API({
    baseURL: API_Voteroom,
    url: "/paslon",
    method: type,
    data: form,
  })
    .then((res) => {
      console.log(res)
      next(true)
    })
    .catch(() => {
      next(false)
    })
}

const deletePaslonManagement = (paslonID, next) => {
  API({
    baseURL: API_Voteroom,
    url: "/paslon",
    method: "DELETE",
    data: {
      paslonID,
    },
  })
    .then((res) => {
      console.log(res)
      next(true)
    })
    .catch(() => {
      next(false)
    })
}

// const deleteUserManagement

export {
  getUserManagement,
  createUpdateUserManagement,
  deleteUserManagement,
  getPaslonManagement,
  createUpdatePaslonManagement,
  deletePaslonManagement,
}