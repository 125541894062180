import React from "react"
import "./style.scss"
import Icon from "@material-ui/core/Icon"
import PolicyIcon from "@material-ui/icons/Policy"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import ReactToPrint from "react-to-print"
// import ReactToPrint, { PrintContextConsumer, useReactToPrint } from "react-to-print"
import AppContext from "../../AppContext"

class PrintAction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrintActionOptionActive: false,
    }
    this.printActionRef = React.createRef()
  }

  render() {
    return (
      <AppContext.Consumer>
        {(appContext) => (
          <React.Fragment>
            <div
              className={`PrintAction${
                this.state.isPrintActionOptionActive ? " Active" : ""
              }`}
              ref={this.printActionRef}
							style={{zIndex: 999999}}>
              <ul className="GoToWrapper">
                <li className="GoTo">
                  <ReactToPrint
                    trigger={() => 
                      <div>
                        <span className="Icon"><Icon><VerifiedUserIcon /></Icon></span>
                        <span className="Name">Analisa Kemenangan</span>
                      </div>
                    }
                    content={() => this.props.printRefKemenangan}
                    bodyClass={"ant-table"}
                  />
                </li>

                <li className="GoTo">
                  <ReactToPrint
                    trigger={() => 
                      <div>
                        <span className="Icon"><Icon><PolicyIcon /></Icon></span>
                        <span className="Name">Deteksi Dini Kejanggalan</span>
                      </div>
                    }
                    content={() => this.props.printRefKejanggalan}
                    bodyClass={"ant-table"}
                  />
                </li>
              </ul>
            </div>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    )
  }
}

export default PrintAction