import React from "react"
import "./style.scss"
import {AsyncStorage} from "AsyncStorage"
import IconButton from "@material-ui/core/IconButton"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import swal from "sweetalert"
import { Redirect } from "react-router-dom"
import AppContext from "../../../../AppContext"
import CircularProgress from "@material-ui/core/CircularProgress"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { BallClipRotateMultiple } from "react-pure-loaders"
import jwt_decode from "jwt-decode"
import { login, getAccount, getRoles } from "../../../../Services/Auth"
import { API_Voteroom } from "../../../../Services/BaseURL"

class Logoff extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      kataSandi: "",
      isLoadingLogoff: true,
      isSubmitButtonActionLoading: false,
    }
    this.handleChangeKataSandi = this.handleChangeKataSandi.bind(this)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
    this.handleKeyUpTextInput = this.handleKeyUpTextInput.bind(this)
    this.handleResetData = this.handleResetData.bind(this)
    this.handleClickLoginWithAnotherAccount = this.handleClickLoginWithAnotherAccount.bind(this)
  }

  static contextType = AppContext

  handleResetData() {
    this.setState({
      kataSandi: "",
      isSubmitButtonActionLoading: false,
    })
  }

  handleKeyUpTextInput(event) {
    if (event.keyCode === 13) {
      this.handleSubmitForm()
      event.preventDefault()
    }
  }

  handleChangeKataSandi(event) {
    this.setState({
      kataSandi: event.target.value,
    })
  }

  handleSubmitForm() {
    if (this.state.kataSandi === "") {
      swal("Oops!", "Kata Sandi wajib diisi.", "warning")
    } else {
      this.setState(
        {
          isSubmitButtonActionLoading: true,
        }, () => {
          setTimeout(async () => {
            await login({
              username: this.state.uname,
              password: this.state.kataSandi,
            }).then(async res => {
              if (res !== undefined) {
                if (res.meta === undefined || parseInt(res.meta.code) !== 200) {
                  swal(
                    "Oops!",
                    `${res.meta.message === "Login Invalid"
                      ? "Nama Pengguna / Kata Sandi tidak valid."
                      : res.meta.message
                    }`,
                    "warning"
                  )
                  this.setState({
                    isSubmitButtonActionLoading: false,
                  })
                } else {
                  if (res.data.token) {
                    const currentToken = res.data.token
                    await getAccount(currentToken)
                    .then(async account => {
                      if (account === undefined) {
                        swal("Oops!", "Nama Pengguna / Kata Sandi tidak valid.", "warning")
                        this.setState({
                          isSubmitButtonActionLoading: false,
                        })
                      } else {
                        const userID = account.data.userID
                        if (account.meta.code === 200) {
                          await getRoles(currentToken, userID)
                          .then(roles => {
                            if (roles === undefined) {
                              swal("Oops!", "Nama Pengguna / Kata Sandi tidak valid.", "warning")
                              this.setState({
                                isSubmitButtonActionLoading: false,
                              })
                            } else {
                              let userTPS = false
                              const promiseRoles = roles.data.rows.map(item => (
                                userTPS = item.pollstationID !== null ? true : false
                              ))
                            
                              Promise.all(promiseRoles).then(() => {
                                if (!userTPS) {
                                  this.context.setToken(currentToken, () => {
                                    // console.info('this.context.token:',this.context.token)
                                    this.setState({
                                      redirect: "/main",
                                    }, async () => {
                                      const tokenDecode = jwt_decode(currentToken).user
                                      let initData = {
                                        userID: tokenDecode.userID,
                                        cadres: tokenDecode.cadres,
                                        username: this.state.namaPengguna,
                                        name: tokenDecode.name,
                                        photo: tokenDecode.photo,
                                        level: tokenDecode.level,
                                        roles: roles.data.rows
                                      }
                                      await AsyncStorage.setItem("voteroom:uname", this.state.namaPengguna)
                                      await AsyncStorage.setItem("voteroom:name", tokenDecode.name)
                                      await AsyncStorage.setItem("voteroom:photo", tokenDecode.photo)
                                      await AsyncStorage.setItem("voteroom:secret", currentToken)
                                      this.context.initData(initData, "/main", false)
                                    })
                                  })
                                } else {
                                  swal("Oops!", "Saksi TPS tidak bisa mengakses Dashboard!", "warning")
                                }
                                this.setState({
                                  isSubmitButtonActionLoading: false,
                                })
                              })
                            }
                          })
                        }
                      }
                    })
                  }
                }
              }
            })
          }, 200)
        }
      )
    }
  }

  handleClickLoginWithAnotherAccount() {
    this.context.resetData(["activeUser", "activePage", "isLogoff"])
  }

  async componentDidMount() {
    await AsyncStorage.getItem("voteroom:uname").then(async uname => {
      this.setState({
        uname: uname
      })

      await AsyncStorage.getItem("voteroom:name").then(async name => {
        this.setState({
          name: name
        })
  
        await AsyncStorage.getItem("voteroom:photo").then(async photo => {
          this.setState({
            photo: photo
          }, () => {
            this.setState({
              isLoadingLogoff: false,
            })
          })
        })
      })
    })

    /* setTimeout(() => {
      if (this.state.isLoadingLogoff) {
        this.setState({
          isLoadingLogoff: false,
        })
      }
    }, 1750) */
  }

  render() {
    return this.state.redirect ? (
      <Redirect to={this.state.redirect} />
    ) : this.state.isLoadingLogoff ? (
      <div
        className={`Logoff-Loader${this.state.isLoadingLogoff ? " Active" : ""
          }`}
      >
        <BallClipRotateMultiple
          color={"#b71c1c"}
          loading={this.state.isLoadingLogoff}
        />
      </div>
    ) : (
          <div className="Logoff">
            <div className="InnerBottomLogoff">
              <div className="LeftSection">
                <input
                  type="text"
                  autoComplete="off"
                  id="logoff_namaPengguna"
                  defaultValue={this.state.name}
                  placeholder="Nama Pengguna"
                  readOnly
                />
              </div>
              <div className="PhotoSection">
                <div
                  className="Photo"
                  style={{
                    //backgroundImage: `url(https://generalsapi.pantimarhaen.id/${this.state.photo})`,
                    backgroundImage: `url(${API_Voteroom}/${this.state.photo})`,
                  }}
                ></div>
              </div>
              <div className="RightSection">
                <input
                  type="password"
                  id="logoff_kataSandi"
                  value={this.state.kataSandi}
                  onChange={this.handleChangeKataSandi}
                  onKeyUp={this.handleKeyUpTextInput}
                  placeholder="Kata Sandi"
                />
                <div className="SubmitButton">
                  <IconButton onClick={this.handleSubmitForm}>
                    {
                      this.state.isSubmitButtonActionLoading ?
                      <CircularProgress size={20} className="LoadingCircle" />
                      : <ArrowForwardIcon />
                    }
                  </IconButton>
                </div>
                <div
                  className="LoginWithAnotherAccountText"
                  onClick={this.handleClickLoginWithAnotherAccount}
                >
                  <span>Masuk dengan akun lain</span>
                </div>
                <div className="showPassword "onClick={() => this.setState({
                    isShowPassword: !this.state.isShowPassword
                  })}
                  style={{color: !this.state.isShowPassword ? '#828282': '#e53935'}}>
                  {
                    this.state.isShowPassword ?
                    <div>
                      <VisibilityOffIcon fontSize="small"/>
                      <span> Sembunyikan</span>
                    </div>
                    : 
                    <div>
                      <VisibilityIcon fontSize="small"/>
                      <span> Tampilkan</span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )
  }
}

export default Logoff