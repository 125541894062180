import React, { Component } from "react"
import {
  createUpdatePaslonManagement,
  deletePaslonManagement,
  getPaslonManagement,
} from "../../Services/Management"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { Table, Button, Image, Form, Input, Row, Col, Select } from "antd"
import { API_Generals, API_Voteroom } from "../../Services/BaseURL"
import API from "../../Services/API"
import { Line } from "react-chartjs-2"
import { debounce } from "../../Services/functions"
import { Box } from "@material-ui/core"

const { Column } = Table

export default class PaslonManagementContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dataPaslon: {},
      modal: false,
      nameTitle: "",
      loadingTable: true,
      listRegional: [],
      formPaslon: {
        provinceID: "182be0c5cdcd5072bb1864cdee4d3d6e",
        districtID: "",
        periode: "",
        election: "",
        number: "",
        name: "",
        photo: "",
        color: "#000000",
      },
      paslonID: "",
    }
  }

  async getRegional() {
    try {
      const data = await API({
        baseURL: API_Generals,
        url: "/regionals",
        method: "GET",
        params: {
          body: {
            search: {
              groupOp: "AND",
              rules: [
                {
                  field: "parentID",
                  op: "eq",
                  data: "182be0c5cdcd5072bb1864cdee4d3d6e",
                },
              ],
              groups: [],
            },
            page: 1,
            limit: 100,
            sorts: [
              {
                field: "name",
                mode: "ASC",
              },
            ],
          },
          // body: {
          //   search: {
          //     groupOp: "AND",
          //     rules: [
          //       {
          //         field: "type",
          //         op: "eq",
          //         data: "Provinsi",
          //       },
          //       {
          //         field: "code",
          //         op: "eq",
          //         data: 33,
          //       },
          //     ],
          //     groups: [],
          //   },
          //   page: 1,
          //   limit: 10,
          // },
        },
      })

      // console.log()

      this.setState({
        listRegional: data.data.data.rows,
      })
    } catch (error) {
      console.log(error)
    }
  }

  async getPaslon(limit = 10, page = 1,name=null) {
    this.setState({
      loadingTable: true,
    })

    const data = await getPaslonManagement(Line, page,name)

    this.setState({
      dataPaslon: data.data,
      loadingTable: false,
    })
  }

  onActionModal(status, title) {
    this.setState({
      modal: status,
      nameTitle: title,
    })
  }

  uploadFile(e) {
    // console.log(e.target.files[0])
    return e.target.files[0]
  }


  handleChange =debounce((e)=>{
   this.getPaslon(10,1,e)
      },1500)

  componentDidMount() {
    this.getPaslon()
    this.getRegional()
  }

  render() {
    // console.log(this.state)
    return (
      <div style={{
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}>
        <div
          style={{
            color: "white",
            borderRadius: "5px",
          }}
        >
              <Box display={'flex'} justifyContent={'end'} style={{gap:10}}>
              <input placeholder="Search Name" style={{
              width:300,
        
              borderRadius:5,
              paddingInline:10,
              color:"#414141",
              borderColor:'#414141'
              
            }} onChange={(e)=>{
              this.handleChange(e.target.value)
            }}/>
          {/* <Button
            style={{
              backgroundColor: "#B71C1C",
              color: "white",
         
            }}
            onClick={() => {
              this.setState({
                formPaslon: {
                  provinceID: "182be0c5cdcd5072bb1864cdee4d3d6e",
                  districtID: "",
                  periode: "",
                  election: "",
                  number: "",
                  name: "",
                  photo: "",
                  color: "#000000",
                },
              })
              this.onActionModal(true, "ADD USER")
            }}
          >
            Create Paslon
          </Button> */}
                    </Box>

          {/* <Button color="danger">Click Me</Button> */}
          <Modal isOpen={this.state.modal}>
            <ModalHeader>{this.state.nameTitle}</ModalHeader>
            <ModalBody>
              <Form
                layout="vertical"
                initialValues={this.state.formPaslon}
                onFinish={async (e) => {
                  // console.log(this.state.nameTitle)
                  if (this.state.nameTitle === "ADD USER") {
                    createUpdatePaslonManagement(e, "POST", (e) => {
                      this.setState({
                        modal: false,
                      })
                      this.getPaslon()
                    })
                  } else {
                    console.log("test", this.state.paslonID)
                    createUpdatePaslonManagement(
                      { ...e, paslonID: this.state.paslonID },
                      "PUT",
                      (e) => {
                        this.setState({
                          modal: false,
                        })
                        this.getPaslon()
                      }
                    )
                  }
                }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={"name"}
                      label="Username"
                      rules={[
                        {
                          required: true,
                          message: "Username harus diiisi",
                        },
                      ]}
                    >
                      <Input placeholder="username" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Photo"
                      name="photo"
                      // valuePropName="fileList"
                      // getValueFromEvent={this.uploadFile}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Photo harus diiisi",
                      //   },
                      // ]}
                    >
                      {/* <input type="file" /> */}
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name={"election"}
                      label="Tingkatan"
                      rules={[
                        {
                          required: true,
                          message: "Tingkatan harus diiisi",
                        },
                      ]}
                    >
                      <Select>
                        {[
                          "PILKADA BUPATI/WALIKOTA",
                          "PILKADA GUBERNUR",
                          "PILPRES",
                        ].map((item) => {
                          return (
                            <Select.Option value={item} key={item}>
                              {item}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"number"}
                      label="Nomor"
                      rules={[
                        {
                          required: true,
                          message: "Nomor harus diiisi",
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"periode"}
                      label="Periode"
                      rules={[
                        {
                          required: true,
                          message: "Periode harus diiisi",
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Color"
                      name={"color"}
                      rules={[
                        {
                          required: true,
                          message: "Color harus diiisi",
                        },
                      ]}
                    >
                      {/* <Input /> */}
                      <input type="color" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Provinsi"
                      name={"provinceID"}
                      rules={[
                        {
                          required: true,
                          message: "Level harus diiisi",
                        },
                      ]}
                    >
                      <Select disabled={true}>
                        {["Jawa Tengah"].map((item) => {
                          return (
                            <Select.Option
                              value={"182be0c5cdcd5072bb1864cdee4d3d6e"}
                              key={item}
                            >
                              {item}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Kabupaten"
                      name={"districtID"}
                      rules={[
                        {
                          required: true,
                          message: "Kabupaten harus diiisi",
                        },
                      ]}
                    >
                      <Select>
                        {this.state.listRegional.map((item) => {
                          return (
                            <Select.Option
                              value={item.regionalID}
                              key={item.regionalID}
                            >
                              {item.name}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item>
                      <Button
                        onClick={() => {
                          this.onActionModal(false, "")
                        }}
                        type="ghost"
                      >
                        Tutup
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Simpan
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </ModalBody>

            {/* <ModalFooter>
              <Button color="primary">SAVE</Button>
              <Button
                onClick={() => this.onActionModal(false, "")}
                color="secondary"
              >
                Cancel
              </Button>
            </ModalFooter> */}
          </Modal>
        </div>
        <Table
          loading={this.state.loadingTable}
          pagination={{
            current: this.state.dataPaslon?.page,
            total: this.state.dataPaslon?.total,
            showSizeChanger: false,
          }}
          onChange={(e) => {
            // console.log(e)
            // this.getDataUser(10, e.current)
            this.getPaslon(10, e.current)
          }}
          dataSource={this.state.dataPaslon?.rows}
          rowKey={"paslonID"}
        >
          <Column title="PILKADA" render={(rec) => rec?.election} />
          <Column title="KAB/KOTA" render={(rec) => rec?.district?.name} />
          <Column
            title="CAKADA"
            render={(rec) => {
              const name = rec?.name.split("-")
              return name[0]
            }}
          />
          <Column
            title="CAWAKADA"
            render={(rec) => {
              const name = rec?.name.split("-")
              return name[1]
            }}
          />
          <Column
            title="PHOTO PASLON"
            render={(rec) => (
              <>
                <Image
                  width={40}
                  style={{ borderRadius: "100%" }}
                  src={`${API_Voteroom}/assets/${rec.photo}`}
                />
              </>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(_, record) => {
              return (
                <Row gutter={[5, 5]}>
                  <Col>
                    <Button
                      onClick={() => {
                        this.setState({
                          paslonID: record.paslonID,
                          nameTitle: "EDIT PASLON",
                          modal: true,
                          formPaslon: {
                            provinceID: record.provinceID,
                            districtID: record.districtID,
                            periode: record.periode,
                            election: record.election,
                            number: record.number,
                            name: record.name,
                            photo: "",
                            color: record.color,
                          },
                        })
                      }}
                      style={{ backgroundColor: "#4287f5", color: "white" }}
                    >
                      Update
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        deletePaslonManagement(record.paslonID, () => {
                          this.getPaslon()
                        })
                      }}
                      style={{ backgroundColor: "#B71C1C", color: "white" }}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              )
            }}
          />
        </Table>
      </div>
    )
  }
}