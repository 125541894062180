import React from "react"
import classes from './FootCyberjuang.module.scss'

class FootCyberjuang extends React.PureComponent {
	render() {
		return (
			<div className={classes.InnerFootLeft}>
				<div className={classes.CyberjuangLogoSection}>
					<img
						className={classes.logo}
						src={require("../../Assets/Logos/Cyberjuang.svg")}
						alt="logo_cyberjuang"
					/>
					<ul className={classes.TextWrapper}>
						<li>Cyberjuang</li>
						<li>DPD PDI Perjuangan</li>
						<li>Jawa Tengah</li>
					</ul>
				</div>
			</div>
		)
	}
}

export default FootCyberjuang