import React from "react"
import "./style.scss"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faTh, faBell } from "@fortawesome/free-solid-svg-icons"
import swal from "sweetalert"
import AppContext from "../../../../../AppContext"
import UserAction from "../../../../../Components/UserAction"
import PopupMenu from "../../../../../Components/PopupMenu"
import { withRouter } from "react-router"
import { API_Generals, API_Voteroom } from "../../../../../Services/BaseURL"
import BANTENG from '../../../../../Assets/Logos/logo_pdi.png'
import { AccountCircle, AccountCircleOutlined } from "@material-ui/icons"

class HeadContent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isOutsidePortalOpen: false,
		}
		this.UserActionRef = React.createRef()
		this.PopupMenuRef = React.createRef()

		this.handleToggleUserActionArea = this.handleToggleUserActionArea.bind(this)
		this.handleOutsideUserActionAreaClick = this.handleOutsideUserActionAreaClick.bind(this)
		this.handleAddEventClickUserActionArea = this.handleAddEventClickUserActionArea.bind(this)
		this.handleRemoveEventClickUserActionArea = this.handleRemoveEventClickUserActionArea.bind(this)

		this.handleOutsidePortalOpen = this.handleOutsidePortalOpen.bind(this)
		this.handleOutsidePortalExit = this.handleOutsidePortalExit.bind(this)

		this.handleTogglePopupMenuArea = this.handleTogglePopupMenuArea.bind(this)
		this.handleOutsidePopupMenuAreaClick = this.handleOutsidePopupMenuAreaClick.bind(this)
		this.handleAddEventClickPopupMenuArea = this.handleAddEventClickPopupMenuArea.bind(this)
		this.handleRemoveEventClickPopupMenuArea = this.handleRemoveEventClickPopupMenuArea.bind(this)
	}

  static contextType = AppContext

	handleOutsidePortalOpen() {
		this.setState({
			isOutsidePortalOpen: true,
		})
	}

	handleOutsidePortalExit() {
		this.setState({
			isOutsidePortalOpen: false,
		})
	}

	handleOutsideUserActionAreaClick(event) {
		// console.info("WAH")
		// if (
		//   this.UserActionRef.current.userActionRef.current.contains(event.target) ||
		//   this.state.isOutsidePortalOpen
		// ) {
		//   return
		// }

		// this.handleToggleUserActionArea()
	}

	handleOutsidePopupMenuAreaClick(event) {
		// console.info("WAH")
		// if (
		//   this.UserActionRef.current.userActionRef.current.contains(event.target) ||
		//   this.state.isOutsidePortalOpen
		// ) {
		//   return
		// }

		// this.handleToggleUserActionArea()
	}

	handleAddEventClickUserActionArea() {
		document.addEventListener(
			"mousedown",
			this.handleOutsideUserActionAreaClick,
			false
		)
	}

	handleAddEventClickPopupMenuArea() {
		document.addEventListener(
			"mousedown",
			this.handleOutsidePopupMenuAreaClick,
			false
		)
	}

	handleRemoveEventClickUserActionArea() {
		document.removeEventListener(
			"mousedown",
			this.handleOutsideUserActionAreaClick,
			false
		)
	}

	handleRemoveEventClickPopupMenuArea() {
		document.removeEventListener(
			"mousedown",
			this.handleOutsidePopupMenuAreaClick,
			false
		)
	}

	handleToggleUserActionArea() {
		this.UserActionRef.current.setState((prevState) => ({
			isUserActionOptionActive: !prevState.isUserActionOptionActive,
		}))
		this.PopupMenuRef.current.setState({ isPopupMenuOptionActive: false, })

		if (!this.UserActionRef.current.state.isUserActionOptionActive) {
			this.handleAddEventClickUserActionArea()
		} else {
			this.handleRemoveEventClickUserActionArea()
		}
	}

	handleTogglePopupMenuArea() {
		this.PopupMenuRef.current.setState((prevState) => ({
			isPopupMenuOptionActive: !prevState.isPopupMenuOptionActive,
		}))
		this.UserActionRef.current.setState({ isUserActionOptionActive: false, })

		if (!this.PopupMenuRef.current.state.isPopupMenuOptionActive) {
			this.handleAddEventClickPopupMenuArea()
		} else {
			this.handleRemoveEventClickPopupMenuArea()
		}
	}

	componentWillUnmount() {
		document.removeEventListener(
			"click",
			this.handleOutsideUserActionAreaClick,
			false
		)
	}

	goHome = () => {
		this.props.history.replace('/main')
	}

  goTo = election => {
		if (election === 'PILKADA BUPATI/WALIKOTA') {
			this.context.changeElection(election, () => {
				this.context.PAGE.changeActivePage('/dashboard', () => {
					this.props.history.push('/dashboard')
				})
			})
		} else {
			swal("Oops!","Saat ini baru ada PILKADA BUPATI/WALIKOTA Boss!.", "warning")
		}
  }

	render() {
		return (
			<AppContext.Consumer>
				{(appContext) => (
					<div className="HeadContent">
						<div className="Container">
							<div className="InnerHead">
								<div className="InnerHeadLeft">
									<div className="PDIPLogoSection" onClick={() => this.goHome()}>
										<img
											className="logo"
											src={require("../../../../../Assets/Logos/logo_pdi.png")}
											alt="logo_pdi"
										/>
										<ul className="TextWrapper">
											<li>DPD PDI Perjuangan</li>
											<li>Jawa Tengah</li>
										</ul>
									</div>
								</div>
								<div className="Title">
									{appContext.election !== null ? <h5>REKAPITULASI PEROLEHAN SUARA <span>{appContext.election}</span> <span>{appContext.regionElection.name !== undefined ? appContext.regionElection.name.toUpperCase() : ' SE-JAWA TENGAH'}</span></h5> : ''}
								</div>
								<div className="InnerHeadRight">
									<div className="UserArea">
										{
											/* <span className="NotificationButton">
												<FontAwesomeIcon icon={faBell} />
											</span> */
										}
										{/* <span className="GridButton" onMouseDown={this.handleTogglePopupMenuArea}>
											<FontAwesomeIcon icon={faTh} />
										</span> */}
										<div className="UserPhoto"
											//style={{ backgroundImage: `url(https://generalsapi.pantimarhaen.id/${appContext.USER.activeUser.photo})`, }}
											// style={{ backgroundImage: `url(${API_Voteroom}/${appContext.USER.activeUser.photo})`, }}
											style={{ backgroundImage: `url(${API_Generals}/${appContext.USER.activeUser.photo})`, }}
											onMouseDown={this.handleToggleUserActionArea}>
										{/* <AccountCircle style={{color:"white",width:"100%"}}  /> */}
										</div>

										{/* <img width={35} height={35} src={BANTENG}/> */}

									</div>
									<UserAction
										ref={this.UserActionRef}
										gantiPasswordModalRef={this.props.gantiPasswordModalRef}
										handleOutsidePortalOpen={this.handleOutsidePortalOpen}
										handleOutsidePortalExit={this.handleOutsidePortalExit}
										handleRemoveEventClickUserActionArea={this.handleRemoveEventClickUserActionArea}
									/>
									<PopupMenu
										ref={this.PopupMenuRef}
										goTo={this.goTo}
										handleOutsidePortalOpen={this.handleOutsidePortalOpen}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</AppContext.Consumer>
		)
	}
}

export default withRouter(HeadContent)
