import React from "react"
import AppContext from "../../../../AppContext"
import {AsyncStorage} from "AsyncStorage"
import "./style.scss"
import IconButton from "@material-ui/core/IconButton"
import CircularProgress from "@material-ui/core/CircularProgress"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { Redirect } from "react-router-dom"
import { BallClipRotateMultiple } from "react-pure-loaders"
import swal from "sweetalert"
import jwt_decode from "jwt-decode"
import BgHeadLogin from "../../../../Assets/Miscellaneous/head_login.svg"
import LogoPDI from "../../../../Assets/Logos/logo_pdi.png"
import { login, getAccount, getRoles } from "../../../../Services/Auth"
import {withRouter} from 'react-router-dom'
class Login extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      namaPengguna: "",
      isClearNamaPenggunaActive: false,
      namaPenggunaError: "",
      kataSandi: "",
      isClearKataSandiActive: false,
      kataSandiError: "",
      showKataSandi: false,
      isLoadingLogin: true,
      isShowPassword: false,
      isSubmitButtonActionLoading: false,
      redirect: null,
    }
    this.handleToggleClearTextField = this.handleToggleClearTextField.bind(this)
    this.handleHideClearTextField = this.handleHideClearTextField.bind(this)
    this.handleClickClearTextField = this.handleClickClearTextField.bind(this)
    this.handleBlurNamaPengguna = this.handleBlurNamaPengguna.bind(this)
    this.handleChangeNamaPengguna = this.handleChangeNamaPengguna.bind(this)
    this.handleBlurKataSandi = this.handleBlurKataSandi.bind(this)
    this.handleChangeKataSandi = this.handleChangeKataSandi.bind(this)
    this.handleFormValidation = this.handleFormValidation.bind(this)
    this.handleToggleShowKataSandi = this.handleToggleShowKataSandi.bind(this)
    this.handleKeyUpTextInput = this.handleKeyUpTextInput.bind(this)
    this.handleResetData = this.handleResetData.bind(this)
  }

  static contextType = AppContext

  handleResetData() {
    this.setState({
      namaPengguna: "",
      isClearNamaPenggunaActive: false,
      namaPenggunaError: "",
      kataSandi: "",
      isClearKataSandiActive: false,
      kataSandiError: "",
      showKataSandi: false,
      isSubmitButtonActionLoading: false,
    })
  }

  handleToggleClearTextField(textTarget, clearTarget) {
    this.setState({
      [clearTarget]: this.state[textTarget] ? true : false,
    })
  }

  handleHideClearTextField(clearTarget) {
    this.setState({
      [clearTarget]: false,
    })
  }

  handleClickClearTextField(target, value, callback) {
    this.setState(
      {
        [target]: value,
      },
      () => {
        if (callback) callback()
        this.handleFormValidation(target)
      }
    )
  }

  handleKeyUpTextInput(event) {
    if (event.keyCode === 13) {
      this.handleSubmitForm()
    }
  }

  handleBlurNamaPengguna(event) {
    this.handleFormValidation("namaPengguna")
  }

  handleChangeNamaPengguna(event) {
    this.setState(
      {
        namaPengguna: event.target.value,
      },
      () => {
        this.handleToggleClearTextField(
          "namaPengguna",
          "isClearNamaPenggunaActive"
        )
        this.handleFormValidation("namaPengguna")
      }
    )
  }

  handleBlurKataSandi(event) {
    this.handleFormValidation("kataSandi")
  }

  handleChangeKataSandi(event) {
    this.setState(
      {
        kataSandi: event.target.value,
      },
      () => {
        this.handleToggleClearTextField("kataSandi", "isClearKataSandiActive")
        this.handleFormValidation("kataSandi")
      }
    )
  }

  handleToggleShowKataSandi(event) {
    this.setState((prevState) => ({
      showKataSandi: !prevState.showKataSandi,
    }))
  }

  handleFormValidation(target) {
    let countError = 0

    if (target.includes("namaPengguna")) {
      if (this.state.namaPengguna === "") {
        this.setState({
          namaPenggunaError: "Nama Pengguna wajib diisi.",
        })
        ++countError
      } else {
        this.setState({
          namaPenggunaError: "",
        })
      }
    }

    if (target.includes("kataSandi")) {
      if (this.state.kataSandi === "") {
        this.setState({
          kataSandiError: "Kata Sandi wajib diisi.",
        })
        ++countError
      } else {
        this.setState({
          kataSandiError: "",
        })
      }
    }

    return { countError }
  }

  handleSubmitForm = () => {
    this.context.initLocalStorage()
    const validationResult = this.handleFormValidation([
      "namaPengguna",
      "kataSandi",
    ])

    if (validationResult.countError > 0) {
      swal("Oops!", "Nama Pengguna / Kata Sandi wajib diisi.", "warning")
    } else {
      this.setState(
        {
          isSubmitButtonActionLoading: true,
        },
        async () => {
          await login({
            username: this.state.namaPengguna,
            password: this.state.kataSandi,
          }).then(async res => {
            if (res !== undefined) {
              if (res.meta === undefined || parseInt(res.meta.code) !== 200) {
                swal(
                  "Oops!",
                  `${res.meta.message === "Login Invalid"
                    ? "Nama Pengguna / Kata Sandi tidak valid."
                    : res.meta.message
                  }`,
                  "warning"
                )
                this.setState({
                  isSubmitButtonActionLoading: false,
                })
              } else {
                if (res.data.token) {
                  const currentToken = res.data.token
                  await getAccount(currentToken)
                  .then(async account => {
                    if (account === undefined) {
                      swal("Oops!", "Nama Pengguna / Kata Sandi tidak valid.", "warning")
                      this.setState({
                        isSubmitButtonActionLoading: false,
                      })
                    } else {
                      const userID = account.data.userID
                      if (account.meta.code === 200) {
                        await getRoles(currentToken, userID)
                        .then(roles => {
                          if (roles === undefined) {
                            swal("Oops!", "Nama Pengguna / Kata Sandi tidak valid.", "warning")
                            this.setState({
                              isSubmitButtonActionLoading: false,
                            })
                          } else {
                            let userTPS = false
                            const promiseRoles = roles.data.rows.map(item => (
                              userTPS = item.pollstationID !== null ? true : false
                            ))
                            
                            Promise.all(promiseRoles).then(() => {
                              if (!userTPS) {
                                this.context.setToken(currentToken, () => {
                                  // console.info('this.context.token:',this.context.token)
                                  this.setState({
                                    redirect: "/main",
                                  }, async () => {
                                    const tokenDecode = jwt_decode(currentToken).user
                                    let initData = {
                                      userID: tokenDecode.userID,
                                      cadres: tokenDecode.cadres,
                                      username: this.state.namaPengguna,
                                      name: tokenDecode.name,
                                      photo: tokenDecode.photo,
                                      level: tokenDecode.level,
                                      roles: roles.data.rows
                                    }
                                    await AsyncStorage.setItem("voteroom:uname", this.state.namaPengguna)
                                    await AsyncStorage.setItem("voteroom:name", tokenDecode.name)
                                    await AsyncStorage.setItem("voteroom:photo", tokenDecode.photo)
                                    await AsyncStorage.setItem("voteroom:secret", currentToken)
                                    this.context.initData(initData, "/main", false)
                                  })
                                })
                              } else {
                                swal("Oops!", "Saksi TPS tidak bisa mengakses Dashboard!", "warning")
                              }
                              this.setState({
                                isSubmitButtonActionLoading: false,
                              })
                            })
                          }
                        })
                      }
                    }
                  })
                }
              }
            }
          })
        }
      )
    }
  }


  handleByPass =async(dataUser)=>{
    await login(dataUser).then(async res => {
      if (res !== undefined) {
        if (res.meta === undefined || parseInt(res.meta.code) !== 200) {
          swal(
            "Oops!",
            `${res.meta.message === "Login Invalid"
              ? "Nama Pengguna / Kata Sandi tidak valid."
              : res.meta.message
            }`,
            "warning"
          )
          this.setState({
            isSubmitButtonActionLoading: false,
          })
        } else {
          if (res.data.token) {
            const currentToken = res.data.token
            await getAccount(currentToken)
            .then(async account => {
              if (account === undefined) {
                swal("Oops!", "Nama Pengguna / Kata Sandi tidak valid.", "warning")
                this.setState({
                  isSubmitButtonActionLoading: false,
                })
              } else {
                const userID = account.data.userID
                if (account.meta.code === 200) {
                  await getRoles(currentToken, userID)
                  .then(roles => {
                    if (roles === undefined) {
                      swal("Oops!", "Nama Pengguna / Kata Sandi tidak valid.", "warning")
                      this.setState({
                        isSubmitButtonActionLoading: false,
                      })
                    } else {
                      let userTPS = false
                      const promiseRoles = roles.data.rows.map(item => (
                        userTPS = item.pollstationID !== null ? true : false
                      ))
                      
                      Promise.all(promiseRoles).then(() => {
                        if (!userTPS) {
                          this.context.setToken(currentToken, () => {
                            // console.info('this.context.token:',this.context.token)
                            this.setState({
                              redirect: "/main",
                            }, async () => {
                              const tokenDecode = jwt_decode(currentToken).user
                              let initData = {
                                userID: tokenDecode.userID,
                                cadres: tokenDecode.cadres,
                                username: this.state.namaPengguna,
                                name: tokenDecode.name,
                                photo: tokenDecode.photo,
                                level: tokenDecode.level,
                                roles: roles.data.rows
                              }
                              await AsyncStorage.setItem("voteroom:uname", this.state.namaPengguna)
                              await AsyncStorage.setItem("voteroom:name", tokenDecode.name)
                              await AsyncStorage.setItem("voteroom:photo", tokenDecode.photo)
                              await AsyncStorage.setItem("voteroom:secret", currentToken)
                              this.context.initData(initData, "/main", false)
                            })
                          })
                        } else {
                          swal("Oops!", "Saksi TPS tidak bisa mengakses Dashboard!", "warning")
                        }
                        this.setState({
                          isSubmitButtonActionLoading: false,
                        })
                      })
                    }
                  })
                }
              }
            })
          }
        }
      }
    })
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const ref = query.get("token"); // Mengambil nilai parameter query 'ref'

    if (ref) {
      // console.log(ref);

const decodeBase64 = atob(ref)
const parseDecode =JSON.parse(decodeBase64) 

this.handleByPass(parseDecode)

// console.log(decodeBase64)

    }
   
    const bgHeadLogin = new Image()
    const LogoPDI = new Image()

    if (
      bgHeadLogin.naturalWidth !== 0 &&
      bgHeadLogin.naturalHeight !== 0 &&
      LogoPDI.naturalWidth !== 0 &&
      LogoPDI.naturalHeight !== 0
    ) {
      this.setState({
        isLoadingLogin: false,
      })
    } else {
      bgHeadLogin.onload = () => {
        LogoPDI.onload = () => {
          this.setState({
            isLoadingLogin: false,
          })
        }
      }

      bgHeadLogin.src = BgHeadLogin
      LogoPDI.src = LogoPDI
    }

    setTimeout(() => {
      if (this.state.isLoadingLogin) {
        this.setState({
          isLoadingLogin: false,
        })
      }
    }, 2500)
  }

  render() {

    
    return this.state.redirect ? (
      <Redirect to={this.state.redirect} />
    ) : (
        <React.Fragment>
          <div
            className={`BoxLogin-Loader${this.state.isLoadingLogin ? " Active" : ""
              }`}
          >
            <BallClipRotateMultiple
              color={"#b71c1c"}
              loading={this.state.isLoadingLogin}
            />
          </div>
          {!this.state.isLoadingLogin && (
            <div className="Login-BodyContent">
              <div className="Login-Container">
                <div className="InnerBody">
                  <div className="BoxLogin">
                    <div className="InnerCenterLogin">
                      <div className="LeftSection">
                        <input
                          type="text"
                          autoComplete="off"
                          id="login_namaPengguna"
                          value={this.state.namaPengguna}
                          placeholder="Nama Pengguna"
                          onChange={this.handleChangeNamaPengguna}
                          onKeyUp={this.handleKeyUpTextInput}
                        />
                      </div>
                      <div className="PhotoSection">
                        <img src={LogoPDI} className="Photo" alt="LogoPDIP"/>
                      </div>
                      <div className="RightSection">
                        <input
                          type={!this.state.isShowPassword ? "password" : "text"}
                          id="login_kataSandi"
                          value={this.state.kataSandi}
                          onChange={this.handleChangeKataSandi}
                          onKeyUp={this.handleKeyUpTextInput}
                          placeholder="Kata Sandi"
                        />
                        <div className="SubmitButton">
                          <IconButton onClick={this.handleSubmitForm}>
                            {this.state.isSubmitButtonActionLoading ? (
                              <CircularProgress size={20} className="LoadingCircle" />
                            ) : (
                                <ArrowForwardIcon />
                              )}
                          </IconButton>
                        </div>
                        <div className="showPassword "onClick={() => this.setState({
                            isShowPassword: !this.state.isShowPassword
                          })}
                          style={{color: !this.state.isShowPassword ? '#828282': '#e53935'}}>
                          {
                            this.state.isShowPassword ?
                            <div>
                              <VisibilityOffIcon fontSize="small"/>
                              <span> Sembunyikan</span>
                            </div>
                            : 
                            <div>
                              <VisibilityIcon fontSize="small"/>
                              <span> Tampilkan</span>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )
  }
}

export default withRouter(Login);
