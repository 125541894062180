import React from "react"
import ItemRegional from "./item"

class SubRegionals extends React.PureComponent {
  render() {
    return (
      this.props.data.map((item, index) => (
        <ItemRegional region={this.props.self ? (item.regionalID !== undefined ? item.regionalID : item.pollstationID) : null} key={index} data={item} index={index}/>
      ))
    )
  }
}

export default SubRegionals