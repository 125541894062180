import React from "react"
import { AsyncStorage } from "AsyncStorage"
import JatengMaps from "./Assets/Maps/JatengMaps.json"
import { electionRegion, downline } from "./Services/Others"
import { votes } from "./Services/Votes"

var numeral = require('numeral')
numeral.register('locale', 'id', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  currency: {
    symbol: 'Rp.'
  }
})
numeral.locale('id')

// import openSocket from "socket.io-client"
// const socket = openSocket("pantimarhaen.id:7073", {
// 	log: false, agent: false, origins: '*:*',
// 	transports: ['websocket', 'htmlfile', 'xhr-polling', 'jsonp-polling', 'polling']
// })

const AppContext = React.createContext()

class AppProvider extends React.PureComponent {
  constructor(props) {
    super(props)
    let _this = this
    this.state = {
      roles:"",
      isLoadingImages: true,
      isLoading: true,
      isMapLoading: true,
      activeUser: null,
      activePage: "/",
      isLogoff: false,
      token: null,
      election: '',
      currentResults: {},
      regionElection: {},
      voteResults: {},
      currentSelected: {},
      currentDownline: [],
      viewMode: 'dashboard',
      // viewMode: 'summary',
      showBreadcrumbs: {
        provinsi: true,
        kabupaten: false,
        kecamatan: false,
        kelurahan: false,
      },
      defaultSelected: {
        provinceID: '182be0c5cdcd5072bb1864cdee4d3d6e',
        districtID: null,
        subdistrictID: null,
        villageID: null,
        pollstationID: null,
        zoneID: null,
        periodeID: null,
      },
      selectedIndex: {
        provinces: 0,
        districts: 0,
        subdistricts: 0,
        villages: 0,
        pollstations: 0,
        zones: 0,
        periodes: 0,
      },
      globalState: {
        provinces: [{
          regionalID: "182be0c5cdcd5072bb1864cdee4d3d6e",
          parentID: null,
          kpuID: 32676,
          code: "33",
          name: "JAWA TENGAH",
          type: "Provinsi",
          updated_at: null,
          deleted_at: null
      }],
        districts: [],
        subdistricts: [],
        villages: [],
        pollstations: [],
        zones: [],
        periodes: [],
      },
      regionVotes: [],
      mapVotes: [],
      imageDocuments: [],
      mapOptions: {
        chart: {
          map: JatengMaps,
          inverted: false,
          reflow: true,
          zoomType: null,
          backgroundColor: '#252525',
        },
        title: {
          text: null
        },
        credits: {
          enabled: false,
          text: 'PT. DJAVIS INDONESIA',
          href: 'djavis.id'
        },
        legend: {
          enabled: false
        },
        /* navigation: {
          buttonOptions: {
            height: 40,
            width: 48,
            y: 100,
            symbolSize: 24,
            symbolX: 23,
            symbolY: 21,
            symbolStrokeWidth: 2,
            fill: '#e0e0e0',
            // theme: {
            //   states: {
            //     hover: {
            //       symbolFill: '#fff',
            //       fill: '#f50057'
            //     },
            //   }
            // }
          },
        }, */
        tooltip: {
          headerFormat: null,
          formatter: function () {
            return (
              '<strong>' + this.point.properties.Name + '</strong><br/>' +
              (
                this.point.winner !== null ? (
                  this.point.winner.name + '<br/>' +
                  (this.point.winner.value > 0 ? numeral(this.point.winner.value).format() + ' Suara' : '')
                  // (this.point.winner.value > 0 ? _this.abbreviateNumber(this.point.winner.value) + ' suara' : '')
                ) : '<div>Tidak ada data</div>'
              )
            )
          }
        },
        mapNavigation: {
          enabled: true,
          enableButtons: false,
          enableDoubleClickZoom: false,
          enableDoubleClickZoomTo: false,
          enableTouchZoom: true,
        },
        plotOptions: {
          map: {
            cursor: 'pointer',
            allAreas: false,
            joinBy: ['ID', 'regionalID'],
            states: {
              hover: {
                color: '#FFF9C4'
              }
            },
            events: {
              click: function (e) {
                if (e.point.type !== 'Desa' || e.point.type !== 'Kelurahan') {
                  _this.updateSourceMaps(e.point)
                }
              }
            }
          },
        },
        series: {}
      }
    }
    this.initLocalStorage()

    this.initLocalStorage = this.initLocalStorage.bind(this)
    this.handleChangeLogoffStatus = this.handleChangeLogoffStatus.bind(this)
    this.handleChangeActivePage = this.handleChangeActivePage.bind(this)
    this.handleChangeActiveUser = this.handleChangeActiveUser.bind(this)
    this.handleResetData = this.handleResetData.bind(this)
    this.handleInitData = this.handleInitData.bind(this)

    this.handleImageDocuments = this.handleImageDocuments.bind(this)
    this.handleResetImageDocuments = this.handleResetImageDocuments.bind(this)
    this.changeLoadingImages = this.changeLoadingImages.bind(this)

    this.typeToDoc = this.typeToDoc.bind(this)
    this.getVotes = this.getVotes.bind(this)

    this.handleSwitchMode = this.handleSwitchMode.bind(this)
    this.handleDropdownClick = this.handleDropdownClick.bind(this)
    this.changeLoading = this.changeLoading.bind(this)

    this.handleChangeDefaultSelected = this.handleChangeDefaultSelected.bind(this)
    this.handleChangeSelectedIndex = this.handleChangeSelectedIndex.bind(this)
    this.handleChangeGlobalState = this.handleChangeGlobalState.bind(this)
    this.handleShowBreadcrumbs = this.handleShowBreadcrumbs.bind(this)
    this.handleChangeElection = this.handleChangeElection.bind(this)
    this.handleChangeRegionElection = this.handleChangeRegionElection.bind(this)
    this.handleChangeVoteResults = this.handleChangeVoteResults.bind(this)
    this.handleSetToken = this.handleSetToken.bind(this)
    this.handleChangeCurrentSelected = this.handleChangeCurrentSelected.bind(this)
    this.handleChangeCurrentDownline = this.handleChangeCurrentDownline.bind(this)

    this.abbreviateNumber = this.abbreviateNumber.bind(this)

    this.updateSourceMaps = this.updateSourceMaps.bind(this)
    this.updateRegionVotes = this.updateRegionVotes.bind(this)
    this.resetMaps = this.resetMaps.bind(this)

    this.goToLogout = this.goToLogout.bind(this)

    this.handleCurrentResults = this.handleCurrentResults.bind(this)
  }

  initLocalStorage = () => {
    this.getAsyncStorage('voteroom:roles','', true).then(val => {
      this.setState({ roles: val?.[0]?.user?.access })
    })
    this.getAsyncStorage('isLogoff', false).then(val => {
      this.setState({ isLogoff: val })
    })
    this.getAsyncStorage('voteroom:election', 'PILKADA BUPATI/WALIKOTA').then(val => {
      this.setState({ election: val })
    })
    this.getAsyncStorage('voteroom:regionElection', {}, true).then(val => {
      this.setState({ regionElection: val })
    })
    this.getAsyncStorage('voteroom:currentSelected', {}, true).then(val => {
      this.setState({ currentSelected: val })
    })
    this.getAsyncStorage('voteroom:currentDownline', [], true).then(val => {
      this.setState({ currentDownline: val })
    })
    this.getAsyncStorage('voteroom:viewMode', 'dashboard').then(val => {
      this.setState({ viewMode: val })
    })
    this.getAsyncStorage('voteroom:showBreadcrumbs', {
      provinsi: true,
      kabupaten: false,
      kecamatan: false,
      kelurahan: false,
    }, true).then(val => {
      this.setState({ showBreadcrumbs: val })
    })
    this.getAsyncStorage('voteroom:defaultSelected:provinceID', '182be0c5cdcd5072bb1864cdee4d3d6e').then(val => {
      this.setState({
        defaultSelected: {
          provinceID: val,
          districtID: this.state.defaultSelected.districtID,
          subdistrictID: this.state.defaultSelected.subdistrictID,
          villageID: this.state.defaultSelected.villageID,
          pollstationID: this.state.defaultSelected.pollstationID,
          zoneID: this.state.defaultSelected.zoneID,
          periodeID: this.state.defaultSelected.periodeID
        }
      })
    })
    this.getAsyncStorage('voteroom:defaultSelected:districtID', null).then(val => {
      this.setState({
        defaultSelected: {
          provinceID: this.state.defaultSelected.provinceID,
          districtID: val,
          subdistrictID: this.state.defaultSelected.subdistrictID,
          villageID: this.state.defaultSelected.villageID,
          pollstationID: this.state.defaultSelected.pollstationID,
          zoneID: this.state.defaultSelected.zoneID,
          periodeID: this.state.defaultSelected.periodeID
        }
      })
    })
    this.getAsyncStorage('voteroom:defaultSelected:subdistrictID', null).then(val => {
      this.setState({
        defaultSelected: {
          provinceID: this.state.defaultSelected.provinceID,
          districtID: this.state.defaultSelected.districtID,
          subdistrictID: val,
          villageID: this.state.defaultSelected.villageID,
          pollstationID: this.state.defaultSelected.pollstationID,
          zoneID: this.state.defaultSelected.zoneID,
          periodeID: this.state.defaultSelected.periodeID
        }
      })
    })
    this.getAsyncStorage('voteroom:defaultSelected:villageID', null).then(val => {
      this.setState({
        defaultSelected: {
          provinceID: this.state.defaultSelected.provinceID,
          districtID: this.state.defaultSelected.districtID,
          subdistrictID: this.state.defaultSelected.subdistrictID,
          villageID: val,
          pollstationID: this.state.defaultSelected.pollstationID,
          zoneID: this.state.defaultSelected.zoneID,
          periodeID: this.state.defaultSelected.periodeID
        }
      })
    })
    this.getAsyncStorage('voteroom:defaultSelected:pollstationID', null).then(val => {
      this.setState({
        defaultSelected: {
          provinceID: this.state.defaultSelected.provinceID,
          districtID: this.state.defaultSelected.districtID,
          subdistrictID: this.state.defaultSelected.subdistrictID,
          villageID: this.state.defaultSelected.villageID,
          pollstationID: val,
          zoneID: this.state.defaultSelected.zoneID,
          periodeID: this.state.defaultSelected.periodeID
        }
      })
    })
    this.getAsyncStorage('voteroom:defaultSelected:zoneID', null).then(val => {
      this.setState({
        defaultSelected: {
          provinceID: this.state.defaultSelected.provinceID,
          districtID: this.state.defaultSelected.districtID,
          subdistrictID: this.state.defaultSelected.subdistrictID,
          villageID: this.state.defaultSelected.villageID,
          pollstationID: this.state.defaultSelected.pollstationID,
          zoneID: val,
          periodeID: this.state.defaultSelected.periodeID
        }
      })
    })
    this.getAsyncStorage('voteroom:defaultSelected:periodeID', null).then(val => {
      this.setState({
        defaultSelected: {
          provinceID: this.state.defaultSelected.provinceID,
          districtID: this.state.defaultSelected.districtID,
          subdistrictID: this.state.defaultSelected.subdistrictID,
          villageID: this.state.defaultSelected.villageID,
          pollstationID: this.state.defaultSelected.pollstationID,
          zoneID: this.state.defaultSelected.zoneID,
          periodeID: val
        }
      })
    })
    this.getAsyncStorage('voteroom:selectedIndex:provinces', 0).then(val => {
      this.setState({
        selectedIndex: {
          provinces: val,
          districts: this.state.selectedIndex.districts,
          subdistricts: this.state.selectedIndex.subdistricts,
          villages: this.state.selectedIndex.villages,
          pollstations: this.state.selectedIndex.pollstations,
          zones: this.state.selectedIndex.zones,
          periodes: this.state.selectedIndex.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:selectedIndex:districts', 0).then(val => {
      this.setState({
        selectedIndex: {
          provinces: this.state.selectedIndex.provinces,
          districts: val,
          subdistricts: this.state.selectedIndex.subdistricts,
          villages: this.state.selectedIndex.villages,
          pollstations: this.state.selectedIndex.pollstations,
          zones: this.state.selectedIndex.zones,
          periodes: this.state.selectedIndex.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:selectedIndex:subdistricts', 0).then(val => {
      this.setState({
        selectedIndex: {
          provinces: this.state.selectedIndex.provinces,
          districts: this.state.selectedIndex.districts,
          subdistricts: val,
          villages: this.state.selectedIndex.villages,
          pollstations: this.state.selectedIndex.pollstations,
          zones: this.state.selectedIndex.zones,
          periodes: this.state.selectedIndex.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:selectedIndex:villages', 0).then(val => {
      this.setState({
        selectedIndex: {
          provinces: this.state.selectedIndex.provinces,
          districts: this.state.selectedIndex.districts,
          subdistricts: this.state.selectedIndex.subdistricts,
          villages: val,
          pollstations: this.state.selectedIndex.pollstations,
          zones: this.state.selectedIndex.zones,
          periodes: this.state.selectedIndex.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:selectedIndex:pollstations', 0).then(val => {
      this.setState({
        selectedIndex: {
          provinces: this.state.selectedIndex.provinces,
          districts: this.state.selectedIndex.districts,
          subdistricts: this.state.selectedIndex.subdistricts,
          villages: this.state.selectedIndex.villages,
          pollstations: val,
          zones: this.state.selectedIndex.zones,
          periodes: this.state.selectedIndex.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:selectedIndex:zones', 0).then(val => {
      this.setState({
        selectedIndex: {
          provinces: this.state.selectedIndex.provinces,
          districts: this.state.selectedIndex.districts,
          subdistricts: this.state.selectedIndex.subdistricts,
          villages: this.state.selectedIndex.villages,
          pollstations: this.state.selectedIndex.pollstations,
          zones: val,
          periodes: this.state.selectedIndex.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:selectedIndex:periodes', 0).then(val => {
      this.setState({
        selectedIndex: {
          provinces: this.state.selectedIndex.provinces,
          districts: this.state.selectedIndex.districts,
          subdistricts: this.state.selectedIndex.subdistricts,
          villages: this.state.selectedIndex.villages,
          pollstations: this.state.selectedIndex.pollstations,
          zones: this.state.selectedIndex.zones,
          periodes: val
        }
      })
    })
    this.getAsyncStorage('voteroom:globalState:provinces', [], true).then(val => {
      this.setState({
        globalState: {
          provinces: val,
          districts: this.state.globalState.districts,
          subdistricts: this.state.globalState.subdistricts,
          villages: this.state.globalState.villages,
          pollstations: this.state.globalState.pollstations,
          zones: this.state.globalState.zones,
          periodes: this.state.globalState.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:globalState:districts', [], true).then(val => {
      this.setState({
        globalState: {
          provinces: this.state.globalState.provinces,
          districts: val,
          subdistricts: this.state.globalState.subdistricts,
          villages: this.state.globalState.villages,
          pollstations: this.state.globalState.pollstations,
          zones: this.state.globalState.zones,
          periodes: this.state.globalState.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:globalState:subdistricts', [], true).then(val => {
      this.setState({
        globalState: {
          provinces: this.state.globalState.provinces,
          districts: this.state.globalState.districts,
          subdistricts: val,
          villages: this.state.globalState.villages,
          pollstations: this.state.globalState.pollstations,
          zones: this.state.globalState.zones,
          periodes: this.state.globalState.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:globalState:villages', [], true).then(val => {
      this.setState({
        globalState: {
          provinces: this.state.globalState.provinces,
          districts: this.state.globalState.districts,
          subdistricts: this.state.globalState.subdistricts,
          villages: val,
          pollstations: this.state.globalState.pollstations,
          zones: this.state.globalState.zones,
          periodes: this.state.globalState.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:globalState:pollstations', [], true).then(val => {
      this.setState({
        globalState: {
          provinces: this.state.globalState.provinces,
          districts: this.state.globalState.districts,
          subdistricts: this.state.globalState.subdistricts,
          villages: this.state.globalState.villages,
          pollstations: val,
          zones: this.state.globalState.zones,
          periodes: this.state.globalState.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:globalState:zones', [], true).then(val => {
      this.setState({
        globalState: {
          provinces: this.state.globalState.provinces,
          districts: this.state.globalState.districts,
          subdistricts: this.state.globalState.subdistricts,
          villages: this.state.globalState.villages,
          pollstations: this.state.globalState.pollstations,
          zones: val,
          periodes: this.state.globalState.periodes
        }
      })
    })
    this.getAsyncStorage('voteroom:globalState:periodes', [], true).then(val => {
      this.setState({
        globalState: {
          provinces: this.state.globalState.provinces,
          districts: this.state.globalState.districts,
          subdistricts: this.state.globalState.subdistricts,
          villages: this.state.globalState.villages,
          pollstations: this.state.globalState.pollstations,
          zones: this.state.globalState.zones,
          periodes: val
        }
      })
    })
  }

  // getAsyncStorage = async (key, defaultValue, json = false) => {
  //   return await AsyncStorage.getItem(key).then(value => {
  //     let val = typeof value === 'undefined' || typeof value === undefined || value === 'undefined' ? defaultValue : value

  //     console.log(typeof value,'paklue')
  //     return (typeof value !== 'undefined') ? (json && !Array.isArray(val) ? JSON.parse(value) : value) : defaultValue
  //     // return defaultValue
  //   })
  // }
  getAsyncStorage = async (key, defaultValue, json = false) => {
    try {
      const value = await AsyncStorage.getItem(key);
  
      // Cek jika value null atau undefined, kembalikan defaultValue
      if (value === null || value === undefined || value === 'undefined') {
        return defaultValue;
      }
  
      // Jika json = true, coba parsing value menjadi JSON
      if (json) {
        try {
          return JSON.parse(value);
        } catch (error) {
          console.error('Failed to parse JSON:', error);
          return defaultValue; // Jika parsing gagal, kembalikan defaultValue
        }
      }
  
      // Jika tidak perlu parsing JSON, kembalikan nilai langsung
      return value;
    } catch (error) {
      console.error('Error fetching AsyncStorage item:', error);
      return defaultValue;
    }
  };
  

  handleInitData = (activeUser, activePage, isLogoff, callback) => {
    this.setState({
      activeUser,
      activePage,
      isLogoff,
    }, callback)
  }

  handleResetData = (target, callback) => {
    this.setState(
      (prevState) => ({
        activeUser: target.includes("activeUser") ? null : prevState.activeUser,
        activePage: target.includes("activePage") ? "/" : prevState.activePage,
        isLogoff: target.includes("isLogoff") ? false : prevState.isLogoff,
      }),
      callback
    )
  }

  handleChangeActiveUser = (activeUser, callback) => {
    this.setState({
      activeUser,
    }, callback)
  }

  handleChangeLogoffStatus = callback => {
    this.setState(
      (prevState) => ({
        isLogoff: !prevState.isLogoff,
      }), async () => {
        await AsyncStorage.setItem('isLogoff', this.state.isLogoff).then()
        callback()
      }
    )
  }

  handleChangeActivePage = (activePage, callback) => {
    this.setState({
      activePage,
    }, callback)
  }

  handleChangeDefaultSelected = async (key, value, callback) => {
    let newValue = this.state.defaultSelected
    newValue[key] = value

    await AsyncStorage.setItem("voteroom:defaultSelected:" + key, value)
    this.setState({
      defaultSelected: newValue
    }, callback)
  }

  handleChangeSelectedIndex = async (key, value, callback) => {
    let newValue = this.state.selectedIndex
    newValue[key] = parseInt(value)

    await AsyncStorage.setItem("voteroom:selectedIndex:" + key, parseInt(value))
    this.setState({
      selectedIndex: newValue
    }, callback)
  }

  handleChangeGlobalState = async (key, value, callback) => {
   
    console.log('change global state')
    let newValue = this.state.globalState

 
    newValue[key] = value


    await AsyncStorage.setItem("voteroom:globalState:" + key, JSON.stringify(value))
    this.setState({
      globalState: newValue
    }, callback)
  }

  handleShowBreadcrumbs = async (value, callback) => {
    await AsyncStorage.setItem("voteroom:showBreadcrumbs", JSON.stringify(value))
    this.setState({
      showBreadcrumbs: value
    }, callback)
  }

  handleChangeElection = async (value, callback) => {

    // console.log(value,'value handle change election')
    await AsyncStorage.setItem("voteroom:election", value)
    this.setState({
      election: value
    }, callback)
  }

  handleChangeRegionElection = async (value, callback) => {
    await AsyncStorage.setItem("voteroom:regionElection", JSON.stringify(value))
    this.setState({
      regionElection: value
    }, callback)
  }

  handleCurrentResults = (value, callback) => {

    console.log(value,'value currentresults')
    this.setState({
      currentResults: value
    }, callback)
  }

  handleChangeVoteResults = (value, callback) => {
    this.setState({
      voteResults: value
    }, callback)
  }

  handleChangeCurrentSelected = async (value, callback) => {
    await AsyncStorage.setItem("voteroom:currentSelected", JSON.stringify(value))
    this.setState({
      currentSelected: value
    }, callback)
  }

  handleChangeCurrentDownline = async (value, callback) => {
    await AsyncStorage.setItem("voteroom:currentDownline", JSON.stringify(value))
    this.setState({
      currentDownline: value
    }, () => {
      // value.map(item => {
      //   if (item.type !== 'TPS') {
      //     socket.on('update-'+item.regionalID, data => {
      //       this.getUpdate(item)
      //     })
      //   }
      // })

      callback()
    })
  }

	getUpdate = downline => {
    // console.log('update-'+downline.regionalID, downline)
		this.getVotes(
      downline.regionalID,
      downline.type,
      res => {

      
        if (res !== undefined) {
          this.updateFooter(downline, res.data)
          this.updateMaps(downline, res.data)
        }
      }, (downline.type !== undefined && (downline.type === "Kabupaten" || downline.type === "Kota") && this.state.election === "PILKADA BUPATI/WALIKOTA" ? downline.regionalID : null)
    )
  }
  
  updateFooter = (downline, data) => {
    var item = data
    item.region = downline

    if (this.state.regionVotes.length > 0) {
      var regionVoteIndex = this.state.regionVotes.findIndex(r => r.region.regionalID === downline.regionalID)
      var regionVotes = this.state.regionVotes
      regionVotes[regionVoteIndex] = item
      console.log('::this.context.regionVotes::',this.state.regionVotes)
      this.updateRegionVotes(regionVotes)
    }
  }

  updateMaps = (downline, data) => {
    var collection = downline
    collection.data = data

    var totalValue = 0
    data.results.map(result => {
      totalValue += parseInt(result.value)
      return true
    })

    if (totalValue > 0) {
      data.results.sort((a, b) => {
        return parseInt(b.value) - parseInt(a.value)
      })
      collection.winner = data.results[0]
      // collection.color = '#' + data.results[0].color
      collection.color = "#17fc03"
    } else {
      collection.winner = null
      // collection.color = '#3b3b3b'
      collection.color = '#17fc03'
    }
    data.results.sort((a, b) => {
      return parseInt(a.number) - parseInt(b.number)
    })

    var mapVoteIndex = this.state.mapVotes.findIndex(m => m.regionalID === downline.regionalID)
    console.log('old:mapVotes', this.state.mapVotes)
    var mapVotes = this.state.mapVotes
    mapVotes[mapVoteIndex] = collection

    console.log('old:update-'+downline.regionalID, this.state.mapVotes[mapVoteIndex])
    console.log('new:update-'+downline.regionalID, collection)
    this.setState({
      mapOptions: {
        ...this.state.mapOptions,
        series: {
          data: mapVotes,
          shadow: {
            // color: '#f7f7f7',
            color: '#17fc03',
            width: 5,
            offsetX: 0,
            offsetY: 0
          }
        }
      }
    }, () => {
     
      if (this.state.currentSelected.type !== 'Provinsi' && (this.state.election !== 'PILPRES' || this.state.election !== 'PILKADA GUBERNUR' || this.state.election !== 'DPD')) {
        this.getVotes()
      }
    })
  }

  handleSetToken = async (value, callback) => {
    await AsyncStorage.setItem("voteroom:secret", value)
    this.setState({
      token: value
    }, callback)
  }

  handleSwitchMode = async (value, callback) => {
    await AsyncStorage.setItem("voteroom:viewMode", value)
    this.setState({
      viewMode: value
    }, callback)
  }

  goToLogout = async (isLogoff = false) => {
    this.setState(
      (prevState) => ({
        isLogoff: !prevState.isLogoff,
      }), async () => {
        await AsyncStorage.setItem('isLogoff', this.state.isLogoff).then(async () => {
          await AsyncStorage.getItem("voteroom:uname").then(async uname => {
            await AsyncStorage.getItem("voteroom:name").then(async name => {
              await AsyncStorage.getItem("voteroom:photo").then(async photo => {
                localStorage.clear()
                if (isLogoff) {
                  await AsyncStorage.setItem("voteroom:uname", uname)
                  await AsyncStorage.setItem("voteroom:name", name)
                  await AsyncStorage.setItem("voteroom:photo", photo)
                  await AsyncStorage.setItem("isLogoff", isLogoff)
                }
              })
            })
          })
        })
      }
    )
  }

  getProvince = (index, entry = null) => {
    console.log('getprovince')
    this.handleShowBreadcrumbs({
      provinsi: true,
      kabupaten: false,
      kecamatan: false,
      kelurahan: false,
    })

    if (index >= 0) {
      if (this.state.election === 'PILKADA GUBERNUR' || this.state.election === 'DPRD PROVINSI') {
        this.handleChangeRegionElection(this.state.globalState.provinces[index], () => { })
      }

      this.handleChangeCurrentDownline([], () => {
        this.handleChangeCurrentSelected(this.state.globalState.provinces[index], async () => {
          if (this.state.election !== 'DPR RI' || this.state.election !== 'DPRD PROVINSI') {
            await electionRegion(
              this.state.globalState.periodes[this.state.selectedIndex.periodes],
              this.state.election,
              'Kabupaten,Kota',
              this.state.globalState.provinces[index].regionalID
            ).then(res => {
              if (res !== undefined) {
                if (res.meta === undefined || parseInt(res.meta.code) === 401) {
                  this.goToLogout()
                } else {
                  this.handleChangeCurrentDownline(res.data.rows, () => {
                    this.handleChangeGlobalState('districts', res.data.rows, () => {
                      this.handleShowBreadcrumbs({
                        provinsi: true,
                        kabupaten: true,
                        kecamatan: false,
                        kelurahan: false,
                      }, () => {
                        let districtIndex = (
                          this.state.defaultSelected.districtID !== null ?
                            this.state.globalState.districts.findIndex(x => x.regionalID === this.state.defaultSelected.districtID) :
                            this.state.selectedIndex.districts - 1
                        )

                        if (districtIndex !== -1) {
                          this.getDistrict(districtIndex)
                        } else {
                          if (this.state.election === 'PILPRES' || this.state.election === 'PILKADA GUBERNUR' || this.state.election === 'DPD') {
                            this.getVotes()
                          }
                          
                          if (this.state.viewMode === "dashboard") {
                            this.resetMaps(this.state.currentDownline)
                          } else if (typeof entry === "boolean") {
                            if (entry) {
                              this.handleSwitchMode('entry')
                            }
                          } else if (typeof entry === "function") {
                            entry()
                          }
                        }
                      })
                    })
                  })
                }
              }
            })
          } else {
            // console.info('pilih dapil DPR RI atau DPRD PROVINSI dulu')
          }
        })
      })
    }
  }

  getDistrict = (index, entry = null) => {
    console.log('getDist')
    this.handleShowBreadcrumbs({
      provinsi: true,
      kabupaten: true,
      kecamatan: false,
      kelurahan: false,
    })

    if (index >= 0) {
      if (this.state.election === 'PILKADA BUPATI/WALIKOTA' || this.state.election === 'DPRD KABUPATEN/KOTA') {
        console.log('getDist IF')
        this.handleChangeRegionElection(this.state.globalState.districts[index], () => { })
        
      }
      this.handleChangeCurrentDownline([], () => {
        this.handleChangeCurrentSelected(this.state.globalState.districts[index], async () => {
          if (this.state.election === 'PILKADA BUPATI/WALIKOTA' || this.state.election === 'PILKADA GUBERNUR' ) {
            await downline(
              this.state.globalState.periodes[this.state.selectedIndex.periodes],
              this.state.globalState.districts[index].regionalID,
              this.state.globalState.districts[index].type
            ).then(res => {
              if (res !== undefined) {
                if (res.meta === undefined || parseInt(res.meta.code) === 401) {
                  this.goToLogout()
                } else {
                  this.handleChangeCurrentDownline(res.data.downline, () => {
                    this.handleChangeGlobalState('subdistricts', res.data.downline, () => {
                      this.handleShowBreadcrumbs({
                        provinsi: true,
                        kabupaten: true,
                        kecamatan: true,
                        kelurahan: false,
                      }, () => {
                        let subdistrictIndex = (
                          this.state.defaultSelected.subdistrictID !== null ?
                            this.state.globalState.subdistricts.findIndex(x => x.regionalID === this.state.defaultSelected.subdistrictID) :
                            this.state.selectedIndex.subdistricts - 1
                        )

                        if (subdistrictIndex !== -1) {
                          this.getSubdistrict(subdistrictIndex)
                        } else {
                          this.getVotes()
                          if (this.state.viewMode === "dashboard") {
                            this.resetMaps(this.state.currentDownline)
                          } else if (typeof entry === "boolean") {
                            if (entry) {
                              this.handleSwitchMode('entry')
                            }
                          } else if (typeof entry === "function") {
                            entry()
                          }
                        }
                      })
                    })
                  })
                }
              }
            })
          } else {
            // console.info('pilih dapil DPRD KABUPATEN/KOTA dulu')
          }
        })
      })
    }
  }

  getSubdistrict = (index, entry = null) => {
    console.log('getSubdist')
    this.handleShowBreadcrumbs({
      provinsi: true,
      kabupaten: true,
      kecamatan: true,
      kelurahan: false,
    })

    if (index >= 0) {
      this.handleChangeCurrentDownline([], () => {
        this.handleChangeCurrentSelected(this.state.globalState.subdistricts[index], async () => {
          await downline(
            this.state.globalState.periodes[this.state.selectedIndex.periodes],
            this.state.globalState.subdistricts[index].regionalID,
            this.state.globalState.subdistricts[index].type
          ).then(res => {
            if (res !== undefined) {
              if (res.meta === undefined || parseInt(res.meta.code) === 401) {
                this.goToLogout()
              } else {
                this.handleChangeCurrentDownline(res.data.downline, () => {
                  this.handleChangeGlobalState('villages', res.data.downline, () => {
                    this.handleShowBreadcrumbs({
                      provinsi: true,
                      kabupaten: true,
                      kecamatan: true,
                      kelurahan: true,
                    }, () => {
                      let villageIndex = (
                        this.state.defaultSelected.villageID !== null ?
                          this.state.globalState.villages.findIndex(x => x.regionalID === this.state.defaultSelected.villageID) :
                          this.state.selectedIndex.villages - 1
                      )

                      if (villageIndex !== -1) {
                        this.getVillage(villageIndex)
                      } else {
                        this.getVotes()
                        if (this.state.viewMode === "dashboard") {
                          this.resetMaps(this.state.currentDownline)
                        } else if (typeof entry === "boolean") {
                          if (entry) {
                            this.handleSwitchMode('entry')
                          }
                        } else if (typeof entry === "function") {
                          entry()
                        }
                      }
                    })
                  })
                })
              }
            }
          })
        })
      })
    }
  }

  getVillage = (index, entry = null) => {
    console.log('getVillage')
    if (index >= 0) {
      this.handleChangeCurrentDownline([], () => {
        this.handleChangeCurrentSelected(this.state.globalState.villages[index], async () => {
          await downline(
            this.state.globalState.periodes[this.state.selectedIndex.periodes],
            this.state.globalState.villages[index].regionalID,
            this.state.globalState.villages[index].type
          ).then(res => {
            // console.log(res.data.downline,'response')
            if (res !== undefined) {
              if (res.meta === undefined || parseInt(res.meta.code) === 401) {
              this.goToLogout()
              } else {
                this.handleChangeCurrentDownline(res.data.downline, () => {
                  this.handleChangeGlobalState('pollstations', res.data.downline, () => {
                    this.getVotes()
                    if (this.state.viewMode === "dashboard") {
                      this.resetMaps()
                    } else if (typeof entry === "boolean") {
                      if (entry) {
                        this.handleSwitchMode('entry')
                      }
                    } else if (entry !== null) {
                      entry()
                    }
                  })
                })
              }
            }
          })
        })
      })
    }
  }

  handleDropdownClick = (index, type, entry = false) => {
    console.log('click ===>  index,type,entry', index, type, entry)
    if (type === 'Provinsi') {
      if (this.state.election === 'PILPRES' || this.state.election === 'PILKADA GUBERNUR' || this.state.election === 'DPD') {
        this.handleChangeSelectedIndex("districts", 0)
      }
      this.getProvince(index, entry)
    } else if (type === 'Kabupaten' || type === 'Kota') {
      this.handleChangeSelectedIndex("subdistricts", 0)
      this.getDistrict(index, entry)
    } else if (type === 'Kecamatan') {
      this.handleChangeSelectedIndex("villages", 0)
      this.getSubdistrict(index, entry)
    } else if (type === 'Desa' || type === 'Kelurahan') {
      this.getVillage(index, entry)
    }
  }

  updateSourceMaps = point => {
    var type = point.type, index = 0, id = point.regionalID

    if (type.toLowerCase() === 'provinsi') {
      index = this.state.globalState.provinces.findIndex(x => x.regionalID === id)
      this.handleChangeSelectedIndex("provinces", (index + 1), () => {
        this.getProvince(index, () => {
          this.resetMaps(this.state.currentDownline)
        })
      })
    } else if (type.toLowerCase() === 'kabupaten' || type.toLowerCase() === 'kota') {
      index = this.state.globalState.districts.findIndex(x => x.regionalID === id)
      this.handleChangeSelectedIndex("districts", (index + 1), () => {
        this.getDistrict(index, () => {
          this.resetMaps(this.state.currentDownline)
        })
      })
    } else if (type.toLowerCase() === 'kecamatan') {
      index = this.state.globalState.subdistricts.findIndex(x => x.regionalID === id)
      this.handleChangeSelectedIndex("subdistricts", (index + 1), () => {
        this.getSubdistrict(index, () => {
          this.resetMaps(this.state.currentDownline)
        })
      })
    } else if (type.toLowerCase() === 'desa' || type.toLowerCase() === 'kelurahan') {
      index = this.state.globalState.villages.findIndex(x => x.regionalID === id)
      this.handleChangeSelectedIndex("villages", (index + 1), () => {
        this.handleChangeCurrentSelected(this.state.globalState.villages[index], () => {
          this.resetMaps()
        })
      })
    }
  }

  resetMaps = async (dataDownline = []) => {
    console.log('reset maps')
    this.setState({ isMapLoading: true })
    var mapVotes = []

    if (this.state.currentSelected.type === 'Desa' || this.state.currentSelected.type === 'Kelurahan') {
      dataDownline = [this.state.currentSelected]
    }
    
    const promiseData = dataDownline.map(item => (
      this.getVotes(
        (item.regionalID !== undefined ? item.regionalID : item.pollstationID),
        (item.type !== undefined ? item.type : "TPS"),
        res => {

          console.log(res,'data')
          if (res !== undefined) {
            var collection = item
            collection.data = res.data

            var totalValue = 0
            res.data.results.map(result => {
              totalValue += parseInt(result.value)
              return true
            })

            if (totalValue > 0) {
              res.data.results.sort((a, b) => {
                return parseInt(b.value) - parseInt(a.value)
              })
              collection.winner = res.data.results[0]
              // collection.color = '#' + res.data.results[0].color
              collection.color = res.data.results[0].color
          
            } else {
              collection.winner = null
              collection.color = '#3b3b3b'
            }
            res.data.results.sort((a, b) => {
              return parseInt(a.number) - parseInt(b.number)
            })

            mapVotes.push(collection)
          }
        }, (item.type !== undefined && (item.type === "Kabupaten" || item.type === "Kota") && this.state.election === "PILKADA BUPATI/WALIKOTA" ? item.regionalID : null)
      )
    ))

    Promise.all(promiseData).then(() => {
      this.setState({
        isMapLoading: false,
        mapVotes: mapVotes
      }, () => {
        this.setState({
          mapOptions: {
            ...this.state.mapOptions,
            series: {
              data: mapVotes,
              shadow: {
                color: '#f7f7f7',
                width: 5,
                offsetX: 0,
                offsetY: 0
              }
            }
          }
        })
      })
    })
    
    if (this.state.currentSelected.type === 'Provinsi' && this.state.election === 'PILKADA BUPATI/WALIKOTA') {
      await downline(
        this.state.globalState[this.state.selectedIndex.periodes],
        this.state.currentSelected.regionalID,
        this.state.currentSelected.type
      ).then(res => {
        if (res !== undefined) {
          if (res.meta === undefined || parseInt(res.meta.code) === 401) {
            this.goToLogout()
          } else {
            res.data.downline.map(item => {
              var id = (item.regionalID !== undefined ? item.regionalID : item.pollstationID)
              var regionIndex = dataDownline.findIndex(r => r.regionalID === id)
              regionIndex = dataDownline.findIndex(r => r.regionalID === id)

              if (regionIndex === -1) {
                var collection = item
                collection.data = {}
                collection.winner = null
                collection.color = '#3b3b3b'
                mapVotes.push(collection)
              }
              return true
            })
            /* 
            const promiseData = res.data.downline.map(item => (
              this.getVotes(
                (item.regionalID !== undefined ? item.regionalID : item.pollstationID),
                (item.type !== undefined ? item.type : "TPS"),
                res => {
                  if (res !== undefined) {
                    var collection = item
                    collection.data = res.data

                    var totalValue = 0
                    res.data.results.map(result => {
                      totalValue += parseInt(result.value)
                    })

                    if (totalValue > 0) {
                      res.data.results.sort((a, b) => {
                        return parseInt(b.value) - parseInt(a.value)
                      })
                      collection.winner = res.data.results[0]
                      collection.color = '#' + res.data.results[0].color
                    } else {
                      collection.winner = null
                      collection.color = '#3b3b3b'
                    }
                    res.data.results.sort((a, b) => {
                      return parseInt(a.number) - parseInt(b.number)
                    })

                    mapVotes.push(collection)
                  }
                }, (item.type !== undefined && (item.type === "Kabupaten" || item.type === "Kota") && this.state.election === "PILKADA BUPATI/WALIKOTA" ? item.regionalID : null)
              )
            ))

            Promise.all(promiseData).then(() => {
              this.setState({
                isMapLoading: false,
                mapVotes: mapVotes
              }, () => {
                this.setState({
                  mapOptions: {
                    ...this.state.mapOptions,
                    series: {
                      data: mapVotes,
                      shadow: {
                        color: '#f7f7f7',
                        width: 5,
                        offsetX: 0,
                        offsetY: 0
                      }
                    }
                  }
                })
              })
            })
            */
          }
        }
      })
    /* } else {
      const promiseData = dataDownline.map(item => (
        this.getVotes(
          (item.regionalID !== undefined ? item.regionalID : item.pollstationID),
          (item.type !== undefined ? item.type : "TPS"),
          res => {
            if (res !== undefined) {
              var collection = item
              collection.data = res.data

              var totalValue = 0
              res.data.results.map(result => {
                totalValue += parseInt(result.value)
              })

              if (totalValue > 0) {
                res.data.results.sort((a, b) => {
                  return parseInt(b.value) - parseInt(a.value)
                })
                collection.winner = res.data.results[0]
                collection.color = '#' + res.data.results[0].color
              } else {
                collection.winner = null
                collection.color = '#3b3b3b'
              }
              res.data.results.sort((a, b) => {
                return parseInt(a.number) - parseInt(b.number)
              })

              mapVotes.push(collection)
            }
          }, (item.type !== undefined && (item.type === "Kabupaten" || item.type === "Kota") && this.state.election === "PILKADA BUPATI/WALIKOTA" ? item.regionalID : null)
        )
      ))

      Promise.all(promiseData).then(() => {
        this.setState({
          isMapLoading: false,
          mapVotes: mapVotes
        }, () => {
          this.setState({
            mapOptions: {
              ...this.state.mapOptions,
              series: {
                data: mapVotes,
                shadow: {
                  color: '#f7f7f7',
                  width: 5,
                  offsetX: 0,
                  offsetY: 0
                }
              }
            }
          })
        })  
      }) */
    }
   
  }

  updateRegionVotes = async (value, callback) => {
    this.setState({
      regionVotes: value
    }, callback)
  }

  getVotes = async (id = null, type = null, callback = null, regionElection = null) => {
    // console.log(this.state.viewMode,'viewwwwwwwwwwwwwwwwwwwwwww')
    
    await votes(
      this.state.globalState.periodes[this.state.selectedIndex.periodes],
      this.state.election,
      regionElection !== null ? regionElection : this.state.regionElection.regionalID,
      this.typeToDoc(type !== null ? type : this.state.currentSelected.type),
      id !== null ? id : this.state.currentSelected.regionalID,
      type !== null ? type : this.state.currentSelected.type,
      this.state.viewMode,
     
    ).then(res => {
      if (res !== undefined) {
        if (res.meta === undefined || parseInt(res.meta.code) === 401) {
          this.goToLogout()
        } else {
          if (res !== undefined) {
            if (callback !== null) {
              
              callback(res)
            } else {
              
              this.handleChangeVoteResults(res.data)
            }
          }
        }
      }
    })
  }

  abbreviateNumber = value => {
    let newValue = value, suffixNum = 0
    const suffixes = ["", "K", "M", "G", "T", "P", "E"]

    while (newValue >= 1000) {
      newValue /= 1000
      suffixNum++
    }

    newValue = suffixNum > 0 ? newValue.toPrecision(3) : newValue
    newValue += suffixes[suffixNum]

    return newValue
  }

  typeToDoc = type => {
    let doc
    if (type === 'Provinsi') {
      doc = 'DC'
    } else if (type === 'Kabupaten' || type === 'Kota') {
      doc = 'DB'
    } else if (type === 'Kecamatan') {
      doc = 'DA'
    } else if (type === 'Desa' || type === 'Kelurahan') {
      doc = 'DAA'
    } else {
      doc = 'C'
    }

    return doc
  }

  changeLoading = (status = true) => {
    this.setState({
      isLoading: status
    })
  }

  changeLoadingImages = (status = true) => {
    this.setState({
      isLoadingImages: status
    }, () => {
      console.log('imageDocuments::', this.state.imageDocuments)
    })
  }

  handleResetImageDocuments = () => {
    this.setState({
      imageDocuments: []
    })
  }

  handleImageDocuments = images => {
    this.setState({
      imageDocuments: [
        ...this.state.imageDocuments,
        images
      ]
    })
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          roles:this.state.roles,
          USER: {
            activeUser: this.state.activeUser,
            isLogoff: this.state.isLogoff,
            changeLogoffStatus: this.handleChangeLogoffStatus,
            changeActiveUser: this.handleChangeActiveUser,
          },
          PAGE: {
            activePage: this.state.activePage,
            changeActivePage: this.handleChangeActivePage,
          },
          initLocalStorage: this.initLocalStorage,
          showBreadcrumbs: this.state.showBreadcrumbs,
          setShowBreadcrumbs: this.handleShowBreadcrumbs,
          defaultSelected: this.state.defaultSelected,
          changeDefaultSelected: this.handleChangeDefaultSelected,
          selectedIndex: this.state.selectedIndex,
          changeSelectedIndex: this.handleChangeSelectedIndex,
          globalState: this.state.globalState,
          changeGlobalState: this.handleChangeGlobalState,
          election: this.state.election,
          changeElection: this.handleChangeElection,
          regionElection: this.state.regionElection,
          changeRegionElection: this.handleChangeRegionElection,
          voteResults: this.state.voteResults,
          changeVoteResults: this.handleChangeVoteResults,
          currentSelected: this.state.currentSelected,
          changeCurrentSelected: this.handleChangeCurrentSelected,
          currentDownline: this.state.currentDownline,
          changeCurrentDownline: this.handleChangeCurrentDownline,
          token: this.state.token,
          setToken: this.handleSetToken,
          viewMode: this.state.viewMode,
          switchMode: this.handleSwitchMode,
          isLoading: this.state.isLoading,
          isMapLoading: this.state.isMapLoading,
          setLoading: this.changeLoading,
          dropdownClick: this.handleDropdownClick,
          typeToDoc: this.typeToDoc,
          getVotes: this.getVotes,
          initData: this.handleInitData,
          resetData: this.handleResetData,

          setImageDocuments: this.handleImageDocuments,
          resetImageDocuments: this.handleResetImageDocuments,
          imageDocuments: this.state.imageDocuments,
          setLoadingImages: this.changeLoadingImages,
          isLoadingImages: this.state.isLoadingImages,

          abbreviateNumber: this.abbreviateNumber,

          mapVotes: this.state.mapVotes,
          mapOptions: this.state.mapOptions,
          updateSourceMaps: this.updateSourceMaps,
          resetMaps: this.resetMaps,
          regionVotes: this.state.regionVotes,
          updateRegionVotes: this.updateRegionVotes,

          goToLogout: this.goToLogout,
          setCurrentResults: this.handleCurrentResults,
          currentResults: this.state.currentResults,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

export { AppProvider }
export default AppContext
