import React from "react"
import "../style.scss"
import AppContext from "../../../../../../AppContext"
import { BallClipRotateMultiple } from "react-pure-loaders"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import mapModule from "highcharts/modules/map"
import proj4 from "proj4"
// import exporting from "highcharts/modules/exporting"
// import offline from "highcharts/modules/offline-exporting"
// exporting(Highcharts)
// offline(Highcharts)

// require('highcharts/modules/exporting')(Highcharts)
// require('highcharts/modules/offline-exporting')(Highcharts)

mapModule(Highcharts)
window.proj4 = window.proj4 || proj4

class Maps extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.mapRef = React.createRef()
    this.state = {
      loading: true
    }
    this.afterMapCreated = this.afterMapCreated.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    // this.context.resetMaps(this.context.currentDownline)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    /* if (this.context.currentSelected.type === 'Provinsi' && (this.context.election !== 'PILPRES' || this.state.election !== 'PILKADA GUBERNUR' || this.state.election !== 'DPD')) {
    } else {
      if (this.intervalTime) {
        clearInterval(this.intervalTime)
      }
		} */
  }

  afterMapCreated = chart => {
    console.log('afterMapCreated:',chart)
    /* if (this.context.currentSelected.type === 'Provinsi' && (this.context.election !== 'PILPRES' || this.state.election !== 'PILKADA GUBERNUR' || this.state.election !== 'DPD')) {
    } else {
      this.intervalTime = setInterval(() => {
        this.context.resetMaps(this.context.currentDownline)
      }, 15000)
    } */
  }

  render() {
    return (
      !this.context.isMapLoading ?
      <div className="MapsContent">
        <HighchartsReact
          highcharts={Highcharts}
          options={this.context.mapOptions}
          constructorType={'mapChart'}
          ref={this.mapRef}
          callback={this.afterMapCreated}
        />
      </div> :
      <div className="BoxLoader">
        <BallClipRotateMultiple
          color={"#fff"}
          loading={this.context.isMapLoading}
        />
      </div>
    )
  }
}

export default Maps