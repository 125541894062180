import React from "react"
import "./style.scss"
import {withRouter} from "react-router-dom"
import HeadContent from "./HeadContent"
import BodyContent from "./BodyContent"
import FootContent from "./FootContent"
import MainContext from "../../MainContext"

class Dashboard extends React.PureComponent {
	render() {
		return (
			<MainContext.Consumer>
				{(mainContext) => (
					<div className="Main ExtraLarge">
						<HeadContent/>
						<BodyContent/>
						<FootContent/>
					</div>
				)}
			</MainContext.Consumer>
		)
	}
}

export default withRouter(Dashboard)
