import React from "react"
import {Pie} from "react-chartjs-2"

class ChartPie extends React.PureComponent {
  getData = () => {
    let candidates = [], values = [], bgColors = []

    this.props.data.map(item => {

      // console.log(item,'test')
      candidates.push(item.name)
      values.push(item.value)
      bgColors.push(item.color)
      return true
    })

    return {
      labels: candidates,
      datasets: [
        {
          data: values,
          backgroundColor: bgColors,
          hoverBackgroundColor: bgColors
        }
      ]
    }
  }

  render() {

    // console.log(this.props,'proposs')
    return (
      <Pie data={this.getData()} type="pie"  options={{legend: {display: false}}}/>
    )
  }
}

export default ChartPie