import React, { Component } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  // Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { Table, Button, Image, Form, Input, Row, Col, Select } from "antd"
import {
  createUpdateUserManagement,
  deleteUserManagement,
  getUserManagement,
} from "../../Services/Management"
import { API_Generals } from "../../Services/BaseURL"
import { Box } from "@material-ui/core"
import { debounce } from "../../Services/functions"

const { Column } = Table
export default class UserManagementContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      nameTitle: "",
      dataUser: null,
      loadingTable: true,
      formUser: {
        name: "",
        secret: "koreakorea",
        level: "",
      },
      userID: "",
    }
    this.onActionModal = this.onActionModal.bind(this)
  }

  onActionModal(status, title) {
    this.setState({
      modal: status,
      nameTitle: title,
    })
  }

  handleChange =debounce((e)=>{
// console.log(e)
this.getDataUser(10,1,e)
  },1500)

  async getDataUser(limit = 10, page = 1,name=null) {
    this.setState({
      loadingTable: true,
    })
    const data = await getUserManagement(limit, page,name)
    this.setState({
      dataUser: data,
      loadingTable: false,
    })
  }

  componentDidMount() {
    this.getDataUser()
  }

  render() {
    // console.log(this.state.dataUser)
    return (
      <div
        style={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <div
          style={{
            color: "white",
            borderRadius: "5px",
          }}
        >
          <Box display={'flex'} justifyContent={'end'} style={{gap:10}}>
        
            <input placeholder="Search Name" style={{
              width:300,
              borderRadius:5,
              paddingInline:10,
              color:"#414141",
              borderColor:'#414141'
              
            }} onChange={(e)=>{
              this.handleChange(e.target.value)
            }}/>
              <Button
            style={{ backgroundColor: "#B71C1C", color: "white" }}
            onClick={() => {
              this.setState({
                formUser: {
                  ...this.state.formUser,
                  name: "",
                  level: "",
                  secret: "koreakorea",
                },
              })
              this.onActionModal(true, "ADD USER")
            }}
          >
            Create User
          </Button>
            </Box>
          {/* <Button color="danger">Click Me</Button> */}
          <Modal isOpen={this.state.modal}>
            <ModalHeader>{this.state.nameTitle}</ModalHeader>
            <ModalBody>
              <Form
                layout="vertical"
                initialValues={this.state.formUser}
                onFinish={async (e) => {
                  let valueForm
                  if (this.state.nameTitle === "ADD USER") {
                    valueForm = e
                  } else {
                    valueForm = { ...e, userID: this.state.userID }
                  }
                  createUpdateUserManagement(
                    valueForm,
                    this.state.nameTitle === "ADD USER" ? "POST" : "PUT",
                    (res) => {
                      // console.log(res)
                      this.setState({
                        modal: false,
                      })
                      this.getDataUser()
                    }
                  )
                }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={"name"}
                      label="Username"
                      rules={[
                        {
                          required: true,
                          message: "Username harus diiisi",
                        },
                      ]}
                    >
                      <Input placeholder="username" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={
                        this.state.nameTitle === "ADD USER"
                          ? "secret"
                          : "new_secret"
                      }
                      label="Password"
                      rules={[
                        {
                          required:
                            this.state.nameTitle === "ADD USER" ? true : false,
                          message: "Password harus diiisi",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Select"
                      name={"level"}
                      rules={[
                        {
                          required: true,
                          message: "Level harus diiisi",
                        },
                      ]}
                    >
                      <Select>
                        {[
                          "DPD",
                          "DPC",
                          "PAC",
                          "Ranting",
                          "TPS",
                          "Operator",
                        ].map((item) => {
                          return (
                            <Select.Option value={item} key={item}>
                              {item}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button
                        onClick={() => {
                          this.onActionModal(false, "")
                        }}
                        type="ghost"
                      >
                        Tutup
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Simpan
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </ModalBody>

            {/* <ModalFooter>
              <Button color="primary">SAVE</Button>
              <Button
                onClick={() => this.onActionModal(false, "")}
                color="secondary"
              >
                Cancel
              </Button>
            </ModalFooter> */}
          </Modal>
        </div>
        <Table
          loading={this.state.loadingTable}
          pagination={{
            current: this.state.dataUser?.data?.page,
            total: this.state.dataUser?.data?.total,
            showSizeChanger: false,
          }}
          onChange={(e) => {
            // console.log(e)
            this.getDataUser(10, e.current)
          }}
          dataSource={this.state.dataUser?.data?.rows}
          rowKey={"userID"}
        >
          <Column title="Name" render={(rec) => rec.name} />
          <Column title="Level" render={(rec) => rec.level} />
          <Column
            title="photo"
            render={(rec) => (
              <>
                <Image
                  width={40}
                  style={{ borderRadius: "100%" }}
                  src={`${API_Generals}/${rec.photo}`}
                />
              </>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(_, record) => {
              return (
                <Row gutter={[5, 5]}>
                  <Col>
                    <Button
                      onClick={() => {
                        // console.log(record)

                        this.setState({
                          userID: record.userID,
                          formUser: {
                            name: record.name,
                            secret: record.secret,
                            level: record.level,
                            // userID: record.userID,
                          },
                        })

                        this.onActionModal(true, "Update User")
                      }}
                      style={{ backgroundColor: "#4287f5", color: "white" }}
                    >
                      Update
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        deleteUserManagement(record.userID, (res) => {
                          this.getDataUser()
                        })
                      }}
                      style={{ backgroundColor: "#B71C1C", color: "white" }}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              )
            }}
          />
        </Table>
      </div>
    )
  }
}