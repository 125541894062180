import React from "react"
import AppContext from "../../../../../../../AppContext"
import Grid from "@material-ui/core/Grid"

var numeral = require('numeral')

class DetailVote extends React.PureComponent {
  static contextType = AppContext

  render() {
    return (
      <div>
        <div>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={6}>
              <h4>Pemilih dan Pengguna Hak Pilih</h4>
            </Grid>
            <Grid item xs={6}>
              <h4>Suara Sah dan Tidak Sah</h4>
            </Grid>
          </Grid>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>1</span>
              <div style={{float: 'left'}}>Pemilih</div>
            </Grid>
            <Grid item xs={2}>
              {
                numeral(
                  parseInt(this.props.data.dpt.data.dptL)+
                  parseInt(this.props.data.dpt.data.dptP)+
                  parseInt(this.props.data.dpt.data.dptbL)+
                  parseInt(this.props.data.dpt.data.dptbP)+
                  parseInt(this.props.data.dpt.data.dpkL)+
                  parseInt(this.props.data.dpt.data.dpkP)+
                  parseInt(this.props.data.dpt.data.diffableL)+
                  parseInt(this.props.data.dpt.data.diffableP)
                ).format()
                /* this.context.abbreviateNumber(
                  parseInt(this.props.data.dpt.data.dptL)+
                  parseInt(this.props.data.dpt.data.dptP)+
                  parseInt(this.props.data.dpt.data.dptbL)+
                  parseInt(this.props.data.dpt.data.dptbP)+
                  parseInt(this.props.data.dpt.data.dpkL)+
                  parseInt(this.props.data.dpt.data.dpkP)+
                  parseInt(this.props.data.dpt.data.diffableL)+
                  parseInt(this.props.data.dpt.data.diffableP)
                ) */
              }
            </Grid>
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>1</span>
              <div>Suara Sah</div>
            </Grid>
            <Grid item xs={2}>
              {numeral(this.props.data.use.valid).format()}
              {/* {this.context.abbreviateNumber(this.props.data.use.valid)} */}
            </Grid>
          </Grid>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
            style={{marginTop:'-25px'}}
          >
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>2</span>
              <div style={{float: 'left'}}>Pengguna Hak Pilih</div>
            </Grid>
            <Grid item xs={2}>
              {
                numeral(
                  parseInt(this.props.data.dpt.input.dptL)+
                  parseInt(this.props.data.dpt.input.dptP)+
                  parseInt(this.props.data.dpt.input.dptbL)+
                  parseInt(this.props.data.dpt.input.dptbP)+
                  parseInt(this.props.data.dpt.input.dpkL)+
                  parseInt(this.props.data.dpt.input.dpkP)+
                  parseInt(this.props.data.dpt.input.diffableL)+
                  parseInt(this.props.data.dpt.input.diffableP)
                ).format()
                /* this.context.abbreviateNumber(
                  parseInt(this.props.data.dpt.input.dptL)+
                  parseInt(this.props.data.dpt.input.dptP)+
                  parseInt(this.props.data.dpt.input.dptbL)+
                  parseInt(this.props.data.dpt.input.dptbP)+
                  parseInt(this.props.data.dpt.input.dpkL)+
                  parseInt(this.props.data.dpt.input.dpkP)+
                  parseInt(this.props.data.dpt.input.diffableL)+
                  parseInt(this.props.data.dpt.input.diffableP)
                ) */
              }
            </Grid>
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>2</span>
              <div>Suara Tidak Sah</div>
            </Grid>
            <Grid item xs={2}>
              {numeral(this.props.data.use.invalid).format()}
              {/* {this.context.abbreviateNumber(this.props.data.use.invalid)} */}
            </Grid>
          </Grid>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
            style={{marginTop:'-25px'}}
          >
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>3</span>
              <div style={{float: 'left'}}>Partisipasi</div>
            </Grid>
            <Grid item xs={2}>
              {
                (parseInt(this.props.data.dpt.data.dptT)+parseInt(this.props.data.dpt.data.dpkT)+parseInt(this.props.data.dpt.data.dptbT)) !== 0 ?
                (((parseInt(this.props.data.dpt.input.dptT)+parseInt(this.props.data.dpt.input.dpkT)+parseInt(this.props.data.dpt.input.dptbT))/(parseInt(this.props.data.dpt.data.dptT)+parseInt(this.props.data.dpt.data.dpkT)+parseInt(this.props.data.dpt.data.dptbT)))*100).toFixed(2) : 0
              }%
            </Grid>
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>3</span>
              <div>Total Suara</div>
            </Grid>
            <Grid item xs={2}>
              {
                numeral(
                  parseInt(this.props.data.use.valid)+parseInt(this.props.data.use.invalid)
                ).format()
                /* this.context.abbreviateNumber(
                  parseInt(this.props.data.use.valid)+parseInt(this.props.data.use.invalid)
                ) */
              }
            </Grid>
          </Grid>
        </div>

        {/* Baris ke-2*/}
        <div style={{marginTop: '10px'}}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={6}>
              <h4>Disabilitas</h4>
            </Grid>
            <Grid item xs={6}>
              <h4>Surat Suara</h4>
            </Grid>
          </Grid>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>1</span>
              <div style={{float: 'left'}}>Pemilih</div>
            </Grid>
            <Grid item xs={2}>
              {
                numeral(
                  parseInt(this.props.data.dpt.data.diffableL)+
                  parseInt(this.props.data.dpt.data.diffableP)
                ).format()
                /* this.context.abbreviateNumber(
                  parseInt(this.props.data.dpt.data.diffableL)+
                  parseInt(this.props.data.dpt.data.diffableP)
                ) */
              }
            </Grid>
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>1</span>
              <div>Suara Sah</div>
            </Grid>
            <Grid item xs={2}>
              {numeral(this.props.data.use.allocated).format()}
              {/* {this.context.abbreviateNumber(this.props.data.use.allocated)} */}
              {/* {parseInt(this.props.data.use.allocated)+parseInt((parseInt(this.props.data.use.allocated)*0.025).toFixed(0))} */}
            </Grid>
          </Grid>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
            style={{marginTop:'-25px'}}
          >
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>2</span>
              <div style={{float: 'left'}}>Pengguna Hak Pilih</div>
            </Grid>
            <Grid item xs={2}>
              {
                numeral(
                  parseInt(this.props.data.dpt.input.diffableL)+
                  parseInt(this.props.data.dpt.input.diffableP)
                ).format()
                /* this.context.abbreviateNumber(
                  parseInt(this.props.data.dpt.input.diffableL)+
                  parseInt(this.props.data.dpt.input.diffableP)
                ) */
              }
            </Grid>
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>2</span>
              <div>Surat Dikembalikan</div>
            </Grid>
            <Grid item xs={2}>
              {numeral(this.props.data.use.broken).format()}
            </Grid>
          </Grid>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
            style={{marginTop:'-25px'}}
          >
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>3</span>
              <div style={{float: 'left'}}>Partisipasi</div>
            </Grid>
            <Grid item xs={2}>
              {
                parseInt(this.props.data.dpt.data.diffableT) !== 0 ?
                ((parseInt(this.props.data.dpt.input.diffableT)/parseInt(this.props.data.dpt.data.diffableT))*100).toFixed(2) : 0
              }%
            </Grid>
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>3</span>
              <div>Surat Digunakan</div>
            </Grid>
            <Grid item xs={2}>
              {numeral(this.props.data.use.used).format()}
              {/* {this.context.abbreviateNumber(this.props.data.use.used)} */}
            </Grid>
          </Grid>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
            style={{marginTop:'-25px'}}
          >
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={4}>
              <span
              style={{
                color: '#37474F',
                backgroundColor: '#FFE082',
                minWidth: '25px',
                marginRight: '10px',
                textAlign: 'center',
                padding: '2px',
                borderRadius: '3px',
                fontSize: '14px',
                float: 'left'
              }}>4</span>
              <div>Surat Tidak Digunakan</div>
            </Grid>
            <Grid item xs={2}>
              {numeral(this.props.data.use.unused).format()}
              {/* {this.context.abbreviateNumber(this.props.data.use.unused)} */}
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

export default DetailVote