import React from "react"
import "./style.scss"

class FootContent extends React.PureComponent {
  render() {
    return (
      <div className="Main-FootContent">
        <div className="InnerFoot">
          <div className="InnerFootLeft">
            <div className="CyberjuangLogoSection">
              <img
                className="logo"
                src={require("../../../../../Assets/Logos/Cyberjuang.svg")}
                alt="logo_cyberjuang"
              />
              <ul className="TextWrapper">
                <li>Cyberjuang</li>
                <li>DPD PDI Perjuangan</li>
                <li>Jawa Tengah</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FootContent
