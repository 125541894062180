import React from "react"
import PropTypes from "prop-types"
import classes from './Entry.module.scss'
import AppContext from "../../../../../../../AppContext"
import { withRouter } from "react-router-dom"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import TextField from "@material-ui/core/TextField"
// import TextField from "./ComponentInput"
import Button from "@material-ui/core/Button"
import SwipeableViews from "react-swipeable-views"
import swal from "sweetalert"
import { attachments, votes, postVotes } from "../../../../../../../Services/Votes"
import {DATA_PASLON}from '../../../../../../../Assets/dummy/paslon'
import NumberFormat from "react-number-format"
import { Table } from "antd"
import "antd/dist/antd.css"
import { API_Generals } from "../../../../../../../Services/BaseURL"

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value !== '' ? values.value : 0,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      prefix=""
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

function TabPanel(props) {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {children}
    </div>
  );
}

class FormEntry extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      loading: true,
      windowHeight: 285,
      windowWidth: undefined,
      columns: {
        firstTab: [
          {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
            width: 40,
            fixed: 'left',
            render: (text, record) => {
              return record.key + 1
            }
          },
          {
            title: 'Wilayah',
            dataIndex: 'regional',
            key: 'regional',
            width: '15%',
            fixed: 'left',
          },
          {
            title: 'Pemilih dalam DPT',
            children: [
              {
                title: 'LK',
                dataIndex: 'dptL',
                key: 'dptL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptL" + record.key}
                    name="dptL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    // InputProps={{disableUnderline: true, inputComponent: NumberFormatCustom}}
                    defaultValue={record.dptL}
                    value={this.state.rows[record.key].dptL}
                    onChange={event => this.handleChangeValue(event, record, 'dptL')} 
                    />
                }
              },
              {
                title: 'PR',
                dataIndex: 'dptP',
                key: 'dptP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptP" + record.key}
                    name="dptP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptP}
                    value={this.state.rows[record.key].dptP}
                    onChange={event => this.handleChangeValue(event, record, 'dptP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'dptT',
                key: 'dptT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptT" + record.key}
                    name="dptT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptT}
                    value={this.state.rows[record.key].dptT}
                    onChange={event => this.handleChangeValue(event, record, 'dptT')} />
                }
              },
            ],
          },
          {
            title: 'Pemilih dalam DPK',
            children: [
              {
                title: 'LK',
                dataIndex: 'dpkL',
                key: 'dpkL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dpkL" + record.key}
                    name="dpkL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dpkL}
                    value={this.state.rows[record.key].dpkL}
                    onChange={event => this.handleChangeValue(event, record, 'dpkL')} />
                }
              },
              {
                title: 'PR',
                dataIndex: 'dpkP',
                key: 'dpkP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dpkP" + record.key}
                    name="dpkP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dpkP}
                    value={this.state.rows[record.key].dpkP}
                    onChange={event => this.handleChangeValue(event, record, 'dpkP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'dpkT',
                key: 'dpkT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dpkT" + record.key}
                    name="dpkT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dpkT}
                    value={this.state.rows[record.key].dpkT}
                    onChange={event => this.handleChangeValue(event, record, 'dpkT')} />
                }
              },
            ],
          },
          {
            title: 'Pemilih dalam DPTb',
            children: [
              {
                title: 'LK',
                dataIndex: 'dptbL',
                key: 'dptbL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptbL" + record.key}
                    name="dptbL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptbL}
                    value={this.state.rows[record.key].dptbL}
                    onChange={event => this.handleChangeValue(event, record, 'dptbL')} />
                }
              },
              {
                title: 'PR',
                dataIndex: 'dptbP',
                key: 'dptbP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptbP" + record.key}
                    name="dptbP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptbP}
                    value={this.state.rows[record.key].dptbP}
                    onChange={event => this.handleChangeValue(event, record, 'dptbP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'dptbT',
                key: 'dptbT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptbT" + record.key}
                    name="dptbT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptbT}
                    value={this.state.rows[record.key].dptbT}
                    onChange={event => this.handleChangeValue(event, record, 'dptbT')} />
                }
              },
            ],
          },
          {
            title: 'Jumlah Pemilih',
            children: [
              {
                title: 'LK',
                dataIndex: 'voterL',
                key: 'voterL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"voterL" + record.key}
                    name="voterL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.voterL}
                    value={this.state.rows[record.key].voterL}
                    onChange={event => this.handleChangeValue(event, record, 'voterL')} />
                }
              },
              {
                title: 'PR',
                dataIndex: 'voterP',
                key: 'voterP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"voterP" + record.key}
                    name="voterP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.voterP}
                    value={this.state.rows[record.key].voterP}
                    onChange={event => this.handleChangeValue(event, record, 'voterP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'voterT',
                key: 'voterT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"voterT" + record.key}
                    name="voterT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.voterT}
                    value={this.state.rows[record.key].voterT}
                    onChange={event => this.handleChangeValue(event, record, 'voterT')} />
                }
              },
            ],
          },
        ],
        secondTab: [
          {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
            width: 40,
            fixed: 'left',
            render: (text, record) => {
              return record.key + 1
            }
          },
          {
            title: 'Wilayah',
            dataIndex: 'regional',
            key: 'regional',
            width: '15%',
            fixed: 'left',
          },
          {
            title: 'Pengguna Hak Pilih dalam DPT',
            children: [
              {
                title: 'LK',
                dataIndex: 'dptVL',
                key: 'dptVL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptVL" + record.key}
                    name="dptVL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptVL}
                    value={this.state.rows[record.key].dptVL}
                    onChange={event => this.handleChangeValue(event, record, 'dptVL')} />
                }
              },
              {
                title: 'PR',
                dataIndex: 'dptVP',
                key: 'dptVP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptVP" + record.key}
                    name="dptVP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptVP}
                    value={this.state.rows[record.key].dptVP}
                    onChange={event => this.handleChangeValue(event, record, 'dptVP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'dptVT',
                key: 'dptVT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptVT" + record.key}
                    name="dptVT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptVT}
                    value={this.state.rows[record.key].dptVT}
                    onChange={event => this.handleChangeValue(event, record, 'dptVT')} />
                }
              },
            ],
          },
          {
            title: 'Pengguna Hak Pilih dalam DPK',
            children: [
              {
                title: 'LK',
                dataIndex: 'dpkVL',
                key: 'dpkVL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dpkVL" + record.key}
                    name="dpkVL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dpkVL}
                    value={this.state.rows[record.key].dpkVL}
                    onChange={event => this.handleChangeValue(event, record, 'dpkVL')} />
                }
              },
              {
                title: 'PR',
                dataIndex: 'dpkVP',
                key: 'dpkVP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dpkVP" + record.key}
                    name="dpkVP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dpkVP}
                    value={this.state.rows[record.key].dpkVP}
                    onChange={event => this.handleChangeValue(event, record, 'dpkVP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'dpkVT',
                key: 'dpkVT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dpkVT" + record.key}
                    name="dpkVT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dpkVT}
                    value={this.state.rows[record.key].dpkVT}
                    onChange={event => this.handleChangeValue(event, record, 'dpkVT')} />
                }
              },
            ],
          },
          {
            title: 'Pengguna Hak Pilih dalam DPTb',
            children: [
              {
                title: 'LK',
                dataIndex: 'dptbVL',
                key: 'dptbVL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptbVL" + record.key}
                    name="dptbVL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptbVL}
                    value={this.state.rows[record.key].dptbVL}
                    onChange={event => this.handleChangeValue(event, record, 'dptbVL')} />
                }
              },
              {
                title: 'PR',
                dataIndex: 'dptbVP',
                key: 'dptbVP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptbVP" + record.key}
                    name="dptbVP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptbVP}
                    value={this.state.rows[record.key].dptbVP}
                    onChange={event => this.handleChangeValue(event, record, 'dptbVP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'dptbVT',
                key: 'dptbVT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"dptbVT" + record.key}
                    name="dptbVT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.dptbVT}
                    value={this.state.rows[record.key].dptbVT}
                    onChange={event => this.handleChangeValue(event, record, 'dptbVT')} />
                }
              },
            ],
          },
          {
            title: 'Jumlah Pengguna Hak Pilih',
            children: [
              {
                title: 'LK',
                dataIndex: 'voterVL',
                key: 'voterVL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"voterVL" + record.key}
                    name="voterVL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.voterVL}
                    value={this.state.rows[record.key].voterVL}
                    onChange={event => this.handleChangeValue(event, record, 'voterVL')} />
                }
              },
              {
                title: 'PR',
                dataIndex: 'voterVP',
                key: 'voterVP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"voterVP" + record.key}
                    name="voterVP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.voterVP}
                    value={this.state.rows[record.key].voterVP}
                    onChange={event => this.handleChangeValue(event, record, 'voterVP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'voterVT',
                key: 'voterVT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"voterVT" + record.key}
                    name="voterVT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.voterVT}
                    value={this.state.rows[record.key].voterVT}
                    onChange={event => this.handleChangeValue(event, record, 'voterVT')} />
                }
              },
            ],
          },
        ],
        thirdTab: [
          {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
            width: 40,
            fixed: 'left',
            render: (text, record) => {
              return record.key + 1
            }
          },
          {
            title: 'Wilayah',
            dataIndex: 'regional',
            key: 'regional',
            width: '15%',
            fixed: 'left',
          },
          {
            title: 'Jumlah seluruh Pemilih Disabilitas dalam DPT, DPK dan DPTb',
            children: [
              {
                title: 'LK',
                dataIndex: 'diffableL',
                key: 'diffableL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"diffableL" + record.key}
                    name="diffableL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.diffableL}
                    value={this.state.rows[record.key].diffableL}
                    onChange={event => this.handleChangeValue(event, record, 'diffableL')} />
                }
              },
              {
                title: 'PR',
                dataIndex: 'diffableP',
                key: 'diffableP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"diffableP" + record.key}
                    name="diffableP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.diffableP}
                    value={this.state.rows[record.key].diffableP}
                    onChange={event => this.handleChangeValue(event, record, 'diffableP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'diffableT',
                key: 'diffableT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"diffableT" + record.key}
                    name="diffableT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.diffableT}
                    value={this.state.rows[record.key].diffableT}
                    onChange={event => this.handleChangeValue(event, record, 'diffableT')} />
                }
              },
            ],
          },
          {
            title: 'Jumlah seluruh Pengguna Hak Pilih Disabilitas',
            children: [
              {
                title: 'LK',
                dataIndex: 'diffableVL',
                key: 'diffableVL',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"diffableVL" + record.key}
                    name="diffableVL"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.diffableVL}
                    value={this.state.rows[record.key].diffableVL}
                    onChange={event => this.handleChangeValue(event, record, 'diffableVL')} />
                }
              },
              {
                title: 'PR',
                dataIndex: 'diffableVP',
                key: 'diffableVP',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"diffableVP" + record.key}
                    name="diffableVP"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.diffableVP}
                    value={this.state.rows[record.key].diffableVP}
                    onChange={event => this.handleChangeValue(event, record, 'diffableVP')} />
                }
              },
              {
                title: 'JML',
                dataIndex: 'diffableVT',
                key: 'diffableVT',
                align: 'center',
                width: 80,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"diffableVT" + record.key}
                    name="diffableVT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.diffableVT}
                    value={this.state.rows[record.key].diffableVT}
                    onChange={event => this.handleChangeValue(event, record, 'diffableVT')} />
                }
              },
            ],
          }
        ],
        fourthTab: [
          {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
            width: 40,
            fixed: 'left',
            render: (text, record) => {
              return record.key + 1
            }
          },
          {
            title: 'Wilayah',
            dataIndex: 'regional',
            key: 'regional',
            width: '15%',
            fixed: 'left',
          },
          {
            title: 'Data Penggunaan Surat Suara',
            children: [
              {
                title: 'Surat Suara yang diterima termasuk cadangan',
                dataIndex: 'allocated',
                key: 'allocated',
                align: 'center',
                width: 150,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"allocated" + record.key}
                    name="allocated"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.allocated}
                    value={this.state.rows[record.key].allocated}
                    onChange={event => this.handleChangeValue(event, record, 'allocated')} />
                }
              },
              {
                title: 'Surat Suara yang dikembalikan karena Rusak dan/atau Keliru Coblos',
                dataIndex: 'broken',
                key: 'broken',
                align: 'center',
                width: 150,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"broken" + record.key}
                    name="broken"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.broken}
                    value={this.state.rows[record.key].broken}
                    onChange={event => this.handleChangeValue(event, record, 'broken')} />
                }
              },
              {
                title: 'Surat Suara yang tidak digunakan termasuk sisa Surat Suara Cadangan',
                dataIndex: 'unused',
                key: 'unused',
                align: 'center',
                width: 150,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"unused" + record.key}
                    name="unused"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.unused}
                    value={this.state.rows[record.key].unused}
                    onChange={event => this.handleChangeValue(event, record, 'unused')} />
                }
              },
              {
                title: 'Surat Suara yang digunakan',
                dataIndex: 'used',
                key: 'used',
                align: 'center',
                width: 150,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"used" + record.key}
                    name="used"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.used}
                    value={this.state.rows[record.key].used}
                    onChange={event => this.handleChangeValue(event, record, 'used')} />
                }
              },
            ]
          },
          {
            title: 'Data Suara Sah dan Tidak Sah',
            children: [
              {
                title: 'Suara sah',
                dataIndex: 'valid',
                key: 'valid',
                align: 'center',
                width: 150,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"valid" + record.key}
                    name="valid"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.valid}
                    value={this.state.rows[record.key].valid}
                    onChange={event => this.handleChangeValue(event, record, 'valid')} />
                }
              },
              {
                title: 'Suara Tidak Sah',
                dataIndex: 'invalid',
                key: 'invalid',
                align: 'center',
                width: 150,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"invalid" + record.key}
                    name="invalid"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.invalid}
                    value={this.state.rows[record.key].invalid}
                    onChange={event => this.handleChangeValue(event, record, 'invalid')} />
                }
              },
              {
                title: 'Jumlah seluruh Suara Sah dan Tidak Sah',
                dataIndex: 'usedT',
                key: 'usedT',
                align: 'center',
                width: 150,
                render: (text, record) => {
                  return <TextField
                    color="secondary"
                    id={"usedT" + record.key}
                    name="usedT"
                    fullWidth
                    inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    defaultValue={record.usedT}
                    value={this.state.rows[record.key].usedT}
                    onChange={event => this.handleChangeValue(event, record, 'usedT')} />
                }
              },
            ]
          },
        ],
        fifthTab: [],
      },
      rows: []
    }
  }

  static contextType = AppContext

  stringPadLeft = (number, length, delimeter = '0') => {
    return Array(length - String(number).length + 1).join(delimeter) + number
  }

  randomNumber = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  }

  getImage = (url, callback) => {
    var img = new Image()
    img.src = url
    img.onload = function(){
      callback({
        url: url,
        width: this.width,
        height: this.height
      })
    }
  }

  componentDidMount() {
    let candidates = []
    let imageAttachments = []
    this.context.resetImageDocuments()
    this.context.setLoadingImages(true)

    const candidatesPromises = this.props.candidates.map((item, index) => {
      candidates.push({
        title: () => {
          const paslon1 = DATA_PASLON.find((itemx)=> itemx.paslonID === item?.paslonID)?.photo
          const paslon2 = DATA_PASLON.find((itemx)=> itemx.paslonID === item?.paslonID)?.photo

          return <div key={index} className="data-grid-container">
            <div className="element-data" style={{ fontSize: '12px' }}>
              <span
                style={{
                  color: '#fff',
                  backgroundColor: `#${item.color}`,
                  minWidth: '30px',
                  // marginTop: '5px',
                  textAlign: 'center'
                }}>{isNaN(item.number) ? 0 : item.number}
              </span>

              <img className={classes.PaslonPhoto} alt="PDIP" width={50}
                style={{ marginLeft: '30px' }}
                // src={`${API_Generals}/assets/` + item.photo}
                src={paslon1}
              />
              <span>{item.name}</span>
            </div>
          </div>
        },
        dataIndex: 'candidate' + index,
        key: 'candidate' + index,
        width: 100,
        render: (text, record) => {
          return <TextField
            color="secondary"
            id={'candidate' + index}
            name={'candidate' + index}
            fullWidth
            inputProps={{ min: 0, style: { paddingRight: '15px', paddingLeft: '15px', textAlign: "right" } }}
            InputProps={{ inputComponent: NumberFormatCustom }}
            defaultValue={record.results[index]?.value}
            value={this.state.rows[record.key].results[index]?.value}
            onChange={event => this.handleChangeValueVote(event, record, index)} />
        }
      })
      return true
    })

    Promise.all(candidatesPromises).then(() => {
      const dataVote = []
      const votePromises = this.props.data.map(async (item, index) => {
        await votes(
          this.context.globalState.periodes[this.context.selectedIndex.periodes],
          this.context.election,
          this.context.regionElection.regionalID,
          this.context.typeToDoc((item.type !== undefined ? item.type : "TPS")),
          (item.regionalID !== undefined ? item.regionalID : item.pollstationID),
          (item.type !== undefined ? item.type : "TPS"),
        ).then(async res => {
         
          var provinceID = (
            this.context.defaultSelected.provinceID !== null ?
              this.context.globalState.provinces.findIndex(x => x.regionalID === this.context.defaultSelected.provinceID) :
              this.context.selectedIndex.provinces
          )
          var districtID = (
            this.context.defaultSelected.districtID !== null ?
              this.context.globalState.districts.findIndex(x => x.regionalID === this.context.defaultSelected.districtID) :
              this.context.selectedIndex.districts - 1
          )
          var subdistrictID = (
            this.context.defaultSelected.subdistrictID !== null ?
              this.context.globalState.subdistricts.findIndex(x => x.regionalID === this.context.defaultSelected.subdistrictID) :
              this.context.selectedIndex.subdistricts - 1
          )
          var villageID = (
            this.context.defaultSelected.villageID !== null ?
              this.context.globalState.villages.findIndex(x => x.regionalID === this.context.defaultSelected.villageID) :
              this.context.selectedIndex.villages - 1
          )
          var pollstationID = (
            this.context.defaultSelected.pollstationID !== null ?
              this.context.globalState.pollstations.findIndex(x => x.regionalID === this.context.defaultSelected.pollstationID) :
              this.context.selectedIndex.pollstations - 1
          )

          // await attachments(
          //   this.context.globalState.periodes[this.context.selectedIndex.periodes],
          //   this.context.election,
          //   item.type,
          //   item.type === 'Provinsi' ? item.regionalID : (provinceID !== -1 ? this.context.globalState.provinces[provinceID].regionalID : null),
          //   item.type === 'Kabupaten' || item.type === 'Kota' ? item.regionalID : (districtID !== -1 ? this.context.globalState.districts[districtID].regionalID : null),
          //   item.type === 'Kecamatan' ? item.regionalID : (subdistrictID !== -1 ? this.context.globalState.subdistricts[subdistrictID].regionalID : null),
          //   item.type === 'Desa' || item.type === 'Kelurahan' ? item.regionalID : (villageID !== -1 ? this.context.globalState.villages[villageID].regionalID : null),
          //   item.number !== undefined ? item.pollstationID : (pollstationID !== -1 ? this.context.globalState.pollstations[pollstationID].pollstationID : null),
          // ).then(res => {
          //   if (res !== undefined) {
          //     if (res.meta === undefined || parseInt(res.meta.code) === 401) {
          //       this.context.goToLogout()
          //     } else {
          //       res.data.map(item => {
          //         imageAttachments.push(`${API_Generals}/`+item.file)
          //         return true
          //       })
          //     }
          //   }
          // })

          dataVote.push({
            key: index,
            regional: item.number !== undefined ? 'TPS ' + this.stringPadLeft(item.number, 3) : item.name,
            provinceID: item.type === 'Provinsi' ? item.regionalID : (provinceID !== -1 ? this.context.globalState.provinces[provinceID].regionalID : null),
            districtID: item.type === 'Kabupaten' || item.type === 'Kota' ? item.regionalID : (districtID !== -1 ? this.context.globalState.districts[districtID].regionalID : null),
            subdistrictID: item.type === 'Kecamatan' ? item.regionalID : (subdistrictID !== -1 ? this.context.globalState.subdistricts[subdistrictID].regionalID : null),
            villageID: item.type === 'Desa' || item.type === 'Kelurahan' ? item.regionalID : (villageID !== -1 ? this.context.globalState.villages[villageID].regionalID : null),
            pollstationID: item.number !== undefined ? item.pollstationID : (pollstationID !== -1 ? this.context.globalState.pollstations[pollstationID].pollstationID : null),
            zoneID: this.context.defaultSelected.zoneID,
            periode: parseInt(this.context.globalState.periodes[this.context.selectedIndex.periodes]),
            election: this.context.election,
            document: this.context.typeToDoc((item.type !== undefined ? item.type : "TPS")),
            dptL: res.data.dpt.data.dptL,
            dptP: res.data.dpt.data.dptP,
            dptT: res.data.dpt.data.dptT,
            dptbL: res.data.dpt.data.dptbL,
            dptbP: res.data.dpt.data.dptbP,
            dptbT: res.data.dpt.data.dptbT,
            dpkL: res.data.dpt.data.dpkL,
            dpkP: res.data.dpt.data.dpkP,
            dpkT: res.data.dpt.data.dpkT,
            voterL: res.data.dpt.data.voterL,
            voterP: res.data.dpt.data.voterP,
            voterT: res.data.dpt.data.voterT,
            dptVL: res.data.dpt.input.dptL,
            dptVP: res.data.dpt.input.dptP,
            dptVT: res.data.dpt.input.dptT,
            dptbVL: res.data.dpt.input.dptbL,
            dptbVP: res.data.dpt.input.dptbP,
            dptbVT: res.data.dpt.input.dptbT,
            dpkVL: res.data.dpt.input.dpkL,
            dpkVP: res.data.dpt.input.dpkP,
            dpkVT: res.data.dpt.input.dpkT,
            voterVL: res.data.dpt.input.voterL,
            voterVP: res.data.dpt.input.voterP,
            voterVT: res.data.dpt.input.voterT,
            diffableL: res.data.dpt.data.diffableL,
            diffableP: res.data.dpt.data.diffableP,
            diffableT: res.data.dpt.data.diffableT,
            diffableVL: res.data.dpt.input.diffableL,
            diffableVP: res.data.dpt.input.diffableP,
            diffableVT: res.data.dpt.input.diffableT,
            allocated: res.data.use.allocated,
            broken: res.data.use.broken,
            valid: res.data.use.valid,
            invalid: res.data.use.invalid,
            usedT: res.data.use.usedT,
            used: res.data.use.used,
            unused: res.data.use.unused,
            results: res.data.results
          })
        })
      })

      Promise.all(votePromises).then(() => {
        const getImageAttachments = imageAttachments.map(url => {
          this.getImage(url, async meta => {
            await this.context.setImageDocuments({
              src: meta.url,
              width: meta.width,
              height: meta.height
            })
          })
          return true
        })
        Promise.all(getImageAttachments).then(() => {
          this.context.setLoadingImages(false)
        })

        const data = dataVote.sort((a, b) => a.key - b.key)
      
        this.setState({
          rows: data
        }, () => {
         
          this.setState({
            columns: {
              ...this.state.columns,
              // thirdTab: [
              fifthTab: [
                {
                  title: 'No.',
                  dataIndex: 'key',
                  key: 'key',
                  width: 40,
                  fixed: 'left',
                  render: (text, record) => {
                    return record.key + 1
                  }
                },
                {
                  title: 'Wilayah',
                  dataIndex: 'regional',
                  key: 'regional',
                  width: '15%',
                  fixed: 'left',
                },
                {
                  // title: 'Rincian Perolehan Suara',
                  children: candidates
                },
              ]
            },
            loading: false
          })
        })
      })
    })

    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleChangeValue = (event, record, item) => {
    var newRows = this.state.rows
    console.log(item, record, newRows)
    newRows[record.key][item] = event.target.value !== '' ? parseInt(event.target.value) : 0

    if (item === 'dptL' || item === 'dptP') {
      newRows[record.key].dptT = parseInt(parseInt(newRows[record.key].dptL) + parseInt(newRows[record.key].dptP))
    } else if (item === 'dptbL' || item === 'dptbP') {
      newRows[record.key].dptbT = parseInt(parseInt(newRows[record.key].dptbL) + parseInt(newRows[record.key].dptbP))
    } else if (item === 'dpkL' || item === 'dpkP') {
      newRows[record.key].dpkT = parseInt(parseInt(newRows[record.key].dpkL) + parseInt(newRows[record.key].dpkP))
    } else if (item === 'voterL' || item === 'voterP') {
      newRows[record.key].voterT = parseInt(parseInt(newRows[record.key].voterL) + parseInt(newRows[record.key].voterP))
    } else if (item === 'dptVL' || item === 'dptVP') {
      newRows[record.key].dptVT = parseInt(parseInt(newRows[record.key].dptVL) + parseInt(newRows[record.key].dptVP))
    } else if (item === 'dptbVL' || item === 'dptbVP') {
      newRows[record.key].dptbVT = parseInt(parseInt(newRows[record.key].dptbVL) + parseInt(newRows[record.key].dptbVP))
    } else if (item === 'dpkVL' || item === 'dpkVP') {
      newRows[record.key].dpkVT = parseInt(parseInt(newRows[record.key].dpkVL) + parseInt(newRows[record.key].dpkVP))
    } else if (item === 'voterVL' || item === 'voterVP') {
      newRows[record.key].voterVT = parseInt(parseInt(newRows[record.key].voterVL) + parseInt(newRows[record.key].voterVP))
    } else if (item === 'diffableL' || item === 'diffableP') {
      newRows[record.key].diffableT = parseInt(parseInt(newRows[record.key].diffableL) + parseInt(newRows[record.key].diffableP))
    } else if (item === 'diffableVL' || item === 'diffableVP') {
      newRows[record.key].diffableVT = parseInt(parseInt(newRows[record.key].diffableVL) + parseInt(newRows[record.key].diffableVP))
    } else if (item === 'valid' || item === 'invalid') {
      newRows[record.key].usedT = parseInt(parseInt(newRows[record.key].valid) + parseInt(newRows[record.key].invalid))
      // newRows[record.key].usedT = 500
    }

    this.setState({ rows: newRows })
  }

  handleChangeValueVote = (event, record, index) => {
    var newRows = this.state.rows
    newRows[record.key].results[index].value = event.target.value !== '' ? parseInt(event.target.value) : 0

    this.setState({ rows: newRows })
  }

  handleChangeIndex = index => {
    this.setState({ tabIndex: index })
  }

  handleChange = (event, index) => {
    this.setState({ tabIndex: index })
  }

  handleResize = () => this.setState({
    windowHeight: !isNaN(window.innerHeight) ? window.innerHeight : 285,
    windowWidth: !isNaN(window.innerWidth) ? window.innerWidth : 0
  })

  postVote = async event => {
    this.setState({ loading: true })

    var postData = this.state.rows
    // console.log(postData, 'postData')
    // return
    postData.map((item, index) => {
      if (item.election === 'PILPRES' || item.election === 'PILKADA GUBERNUR' || item.election === 'PILKADA BUPATI/WALIKOTA') {
        item.paslon = item.results
      } else if (item.election === 'DPR RI' || item.election === 'DPRD PROVINSI' || item.election === 'DPRD KABUPATEN/KOTA') {
        item.partai = item.results
      } else if (item.election === 'DPD') {
        item.dpd = item.results
      }
      
      item.difL = item.diffableL
      item.difP = item.diffableP
      item.difT = item.diffableT
      item.difVL = item.diffableVL
      item.difVP = item.diffableVP
      item.difVT = item.diffableVT
      return true
    })

    await postVotes(postData).then(res => {
      this.setState({ loading: false })
      // console.log('postData::', postData)
      if (res !== undefined) {
        if (res.meta === undefined || parseInt(res.meta.code) === 200) {
        swal("Mantap Bos!", "Data berhasil disimpan", "success")
        } else {
          swal("Opps Bos!", res.meta.message, "error")
        }
      } else {
        swal("Opps Bos!", res.meta.message, "error")
      }
    })
  }

  render() {
    // console.log(this.context.currentSelected.type,'type View',this.props)
    // console.log(this.state.rows)
    return (
      // <div>
      //   <Tabs
      //     value={this.state.tabIndex}
      //     onChange={this.handleChange}
      //     variant="fullWidth"
      //     indicatorColor="secondary"
      //     textColor="secondary"
      //     aria-label="full width tabs example"
      //   >
      //     {/* <Tab label="Data Pemilih dan Pengguna Hak Pilih"/> */}
      //     <Tab label="Data Pemilih" />
      //     <Tab label="Pengguna Hak Pilih" />
      //     <Tab label="Data Pemilih Disabilitas" />
      //     <Tab label="Data Penggunaan Surat Suara" />
      //     <Tab label="Rincian Perolehan Suara" />
      //   </Tabs>
      //   {/* <div> */}
      //   <SwipeableViews index={this.state.tabIndex} onChangeIndex={this.handleChangeIndex}>
      //     <TabPanel value={this.state.tabIndex} index={0}>
      //       {this.state.tabIndex === 0 ?
      //       <Table
      //         loading={this.state.loading}
      //         columns={this.state.columns.firstTab}
      //         dataSource={this.state.rows}
      //         pagination={false}
      //         bordered
      //         size="middle"
      //         scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight - 370 }}
      //       // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
      //       />:null
      //     }
      //     </TabPanel>
        
      //         <TabPanel value={this.state.tabIndex} index={1}>
      //           {this.state.tabIndex === 1 ?    
      //           <Table
      //             loading={this.state.loading}
      //             columns={this.state.columns.secondTab}
      //             dataSource={this.state.rows}
      //             pagination={false}
      //             bordered
      //             size="middle"
      //             scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight - 370 }}
      //           // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
      //           />:null
      //         }
      //     </TabPanel>
          
      //         <TabPanel value={this.state.tabIndex} index={2}>
      //           {this.state.tabIndex === 2 ?  
                
      //           <Table
      //             loading={this.state.loading}
      //             columns={this.state.columns.thirdTab}
      //             dataSource={this.state.rows}
      //             pagination={false}
      //             bordered
      //             size="middle"
      //             scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight - 370 }}
      //           // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
      //           />:null
      //         }
      //       </TabPanel>
          
         
      //         <TabPanel value={this.state.tabIndex} index={4}>
      //           {this.state.tabIndex === 4 ?  
      //         <Table
      //           loading={this.state.loading}
      //           columns={this.state.columns.fifthTab}
      //           dataSource={this.state.rows}
      //           pagination={false}
      //           bordered
      //           size="middle"
      //           scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight - 405 }}
      //         // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
      //         />:null
      //           }
      //       </TabPanel>
            
           
          
        
         
      //   </SwipeableViews>
      //   {/* </div> */}
      //   {
      //     (this.context.currentSelected.type === 'Desa' || this.context.currentSelected.type === 'Kelurahan') ?
      //     <Button variant="outlined"
      //       style={{
      //         width: '96%',
      //         color: '#fff',
      //         backgroundColor: '#d32f2f',
      //         borderColor: '#d32f2f',
      //         fontSize: '12px',
      //         bottom: '10px',
      //         // marginLeft: '-13px',
      //         position: 'fixed',
      //       }}
      //       onClick={event => this.postVote(event)}>
      //       Simpan
      //     </Button> : null
      //   }
      // </div>
      <div>
      <Tabs
        value={this.state.tabIndex}
        onChange={this.handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="full width tabs example"
      >
        {/* <Tab label="Data Pemilih dan Pengguna Hak Pilih"/> */}
        {/* <Tab label="Data Pemilih" />
        <Tab label="Pengguna Hak Pilih" />
        <Tab label="Data Pemilih Disabilitas" />
        <Tab label="Data Penggunaan Surat Suara" /> */}
        <Tab label="Rincian Perolehan Suara" />
      </Tabs>
      {/* <div> */}
      <SwipeableViews
        index={this.state.tabIndex}
        onChangeIndex={this.handleChangeIndex}
      >
        <TabPanel value={this.state.tabIndex} index={0}>
          {/* <Table
            loading={this.state.loading}
            columns={this.state.columns.firstTab}
            dataSource={this.state.rows}
            pagination={false}
            bordered
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: this.state.windowHeight - 370,
            }}
            // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
          /> */}
           <Table
            loading={this.state.loading}
            columns={this.state.columns.fifthTab}
            dataSource={this.state.rows}
            pagination={false}
            bordered
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: this.state.windowHeight - 405,
            }}
            // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
          />
        </TabPanel>
        <TabPanel value={this.state.tabIndex} index={1}>
          {this.state.tabIndex === 1 && 
          <Table
            loading={this.state.loading}
            columns={this.state.columns.secondTab}
            dataSource={this.state.rows}
            pagination={false}
            bordered
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: this.state.windowHeight - 370,
            }}
            // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
          />
          }
        </TabPanel>
        <TabPanel value={this.state.tabIndex} index={2}>
          {this.state.tabIndex === 2 && 
          <Table
            loading={this.state.loading}
            columns={this.state.columns.thirdTab}
            dataSource={this.state.rows}
            pagination={false}
            bordered
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: this.state.windowHeight - 370,
            }}
            // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
          />
          }
        </TabPanel>
        <TabPanel value={this.state.tabIndex} index={3}>
 {this.state.tabIndex === 3 && 
 
          <Table
            loading={this.state.loading}
            columns={this.state.columns.fourthTab}
            dataSource={this.state.rows}
            pagination={false}
            bordered
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: this.state.windowHeight - 440,
            }}
            // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
          />
 }
        </TabPanel>
        <TabPanel value={this.state.tabIndex} index={4}>
        

        {this.state.tabIndex === 4 &&   <Table
            loading={this.state.loading}
            columns={this.state.columns.fifthTab}
            dataSource={this.state.rows}
            pagination={false}
            bordered
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: this.state.windowHeight - 405,
            }}
            // scroll={{ x: 'calc(700px + 50%)', y: this.state.windowHeight-455 }}
          />  }
        
        </TabPanel>
      </SwipeableViews>
      {/* </div> */}
      {/* {this.context.currentSelected.type === "Desa" ||
      this.context.currentSelected.type === "Kelurahan" ? ( */}
        <Button
          variant="outlined"
          style={{
            width: "96%",
            color: "#fff",
            backgroundColor: "#d32f2f",
            borderColor: "#d32f2f",
            fontSize: "12px",
            bottom: "10px",
            // marginLeft: '-13px',
            position: "fixed",
          }}
          onClick={(event) => this.postVote(event)}
        >
          Simpan
        </Button>
      {/* ) : null} */}
    </div>
    )
  }
}

export default withRouter(FormEntry)