import React from "react"
import AppContext from "../../../../../../AppContext"
import Partai from "./Partai"
import Paslon from "./Paslon"

class DataResults extends React.PureComponent {

  static contextType = AppContext
  
  render() {
    return (
      this.context.voteResults !== undefined &&
      this.context.voteResults.results !== undefined &&
      this.context.voteResults.results.length > 0 ?
        this.context.election === 'DPR RI' ||
        this.context.election === 'DPRD PROVINSI' ||
        this.context.election === 'DPRD KABUPATEN/KOTA' ? <Partai data={this.context.voteResults.results}/> : 
        (
          this.context.election === 'PILPRES' ||
          this.context.election === 'PILKADA GUBERNUR' ||
          this.context.election === 'PILKADA BUPATI/WALIKOTA' ||
          this.context.election === 'DPD' ?
          <Paslon data={this.context.voteResults.results}/>
        : null)
      : null
    )
  }
}

export default DataResults