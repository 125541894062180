import React from "react"
import {withRouter} from "react-router-dom"

const MainContext = React.createContext()

class MainProvider extends React.PureComponent {
  render() {
    return (
      <MainContext.Provider>
        {this.props.children}
      </MainContext.Provider>
    )
  }
}

export { MainProvider }
export default withRouter(MainContext)
