import React from "react"
import AppContext from "../../../../../AppContext"
import "./style.scss"
import RegionalBreadcrumbs from "../../../../../Components/RegionalBreadcrumbs"
import CustomDropdown from "../../../../../Components/Dropdown"
import ChartPie from "../../../../../Components/Charts/pie"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import PrintIcon from "@material-ui/icons/Print"
import RefreshIcon from "@material-ui/icons/Refresh"
import MapIcon from "@material-ui/icons/Map"
import TableChartIcon from "@material-ui/icons/TableChart"
import PieChartIcon from "@material-ui/icons/PieChart"
import GraphicEqIcon from "@material-ui/icons/GraphicEq"
import BurstModeIcon from "@material-ui/icons/BurstMode"
import Grid from "@material-ui/core/Grid"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import SwipeableViews from "react-swipeable-views"
import { BallClipRotateMultiple } from "react-pure-loaders"
import { withRouter } from "react-router"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import { Modal as StrapModal, ModalHeader, ModalBody } from "reactstrap"
// import FlipCountdown from "@rumess/react-flip-countdown"
import moment from "moment"
import FormEntry from "./Contents/Entry"
import DataResults from "./Contents/Results"
import DataUsed from "./Contents/DataUsed"
import SubRegionals from "./Contents/SubRegionals"
import AnalystKemenangan from "../../../../../Components/Analyst/Kemenangan"
import AnalystKejanggalan from "../../../../../Components/Analyst/Kejanggalan"
import PrintAction from "../../../../../Components/PrintAction"
import classes from "./BodyContent.module.scss"
import { periode } from "../../../../../Services/Others"

function TabPanel(props) {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {children}
    </div>
  )
}

const printRefKemenangan = React.createRef()
const printRefKejanggalan = React.createRef()
moment.locale('id')

class BodyContent extends React.PureComponent {
	static contextType = AppContext
  
	constructor(props) {
		super(props)
		this.PrintActionRef = React.createRef()

    this.state = {
      tabIndex: 0,
      windowHeight: 285,
      windowWidth: undefined,
      modalIsOpen: false,
      viewerIsOpen: false,
      countDownStatus: true,
      endTime: '2020-12-09 17:00:00',
      currentImage: 0,
    }

		this.handleTogglePrintActionArea = this.handleTogglePrintActionArea.bind(this)
		this.handleOutsidePrintActionAreaClick = this.handleOutsidePrintActionAreaClick.bind(this)
		this.handleAddEventClickPrintActionArea = this.handleAddEventClickPrintActionArea.bind(this)
		this.handleRemoveEventClickPrintActionArea = this.handleRemoveEventClickPrintActionArea.bind(this)
  }

	async componentDidMount() {
		console.log(this.context.currentSelected.type === 'Provinsi' && (this.context.election !== 'PILPRES' || this.state.election !== 'PILKADA GUBERNUR' || this.state.election !== 'DPD'))
		await periode().then(res => {
			if (res !== undefined) {
				if (res.meta === undefined || parseInt(res.meta.code) === 401) {
					this.context.goToLogout()
				} else {
					this.context.changeGlobalState('periodes', res.data, async () => {
						this.handlePeriodeClick(this.context.selectedIndex.periodes)
						this.context.setLoading(false)
					})
				}
      }
		})

    this.handleResize()
    window.addEventListener('resize', this.handleResize)
		// this.intervalTime = setInterval(() => {
		// 	const a = moment(this.state.endTime, 'YYYY-MM-DD h:mm:ss')
		// 	const b = moment()
		// 	const days = parseInt(a.diff(b, 'days'))
		// 	const hours = parseInt(a.diff(b, 'hours'))
		// 	const minutes = parseInt(a.diff(b, 'minutes'))
		// 	const seconds = parseInt(a.diff(b, 'seconds'))

		// 	if ((days+hours+minutes+seconds) <= 0) {
		// 		this.setState({countDownStatus: false})
		// 	}
		// }, 1000)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
		// if (this.intervalTime) {
		// 	clearInterval(this.intervalTime)
		// }
  }

  handleResize = () => this.setState({
    windowHeight: !isNaN(window.innerHeight) ? window.innerHeight : 285,
    windowWidth: !isNaN(window.innerWidth) ? window.innerWidth : 0
  })
	
	openLightbox = (event, { photo, index }) => {
    this.setState({
      currentImage: index,
      viewerIsOpen: true,
    })
  }

	closeLightbox = () => {
    this.setState({
      currentImage: 0,
      viewerIsOpen: false,
    })
	}

	closeModal = () => {
		this.setState({modalIsOpen: false})
	}

	handlePeriodeClick = index => {
		this.context.changeSelectedIndex('periodes', index)
	}

	goToPage = (mode, page = null) => {
		this.context.switchMode(mode, () => {
			if (page !== null) {
				this.context.PAGE.changeActivePage('/' + page, () => {
					this.props.history.push('/' + page)
				})
			} else {
				if (this.context.regionElection.regionalID !== undefined) {
					this.refreshData()
				}
			}
		})
	}

	refreshData = () => {
		this.context.getVotes()
		var lastDownline = this.context.currentDownline
		// console.log('this.context.currentDownline:',this.context.currentDownline)
		this.context.changeCurrentDownline([], () => {
			this.context.changeCurrentDownline(lastDownline, () => {
			})
		})
	}

  handleChangeIndex = index => {
    this.setState({ tabIndex: index })
  }

  handleChange = (event, index) => {
    this.setState({ tabIndex: index })
  }

	handleTogglePrintActionArea() {
		this.PrintActionRef.current.setState((prevState) => ({
			isPrintActionOptionActive: !prevState.isPrintActionOptionActive,
		}))

		if (!this.PrintActionRef.current.state.isPrintActionOptionActive) {
			this.handleAddEventClickPrintActionArea()
		} else {
			this.handleRemoveEventClickPrintActionArea()
		}
	}

	handleOutsidePrintActionAreaClick(event) {
		// console.info("WAH")
		// if (
		//   this.PrintActionRef.current.userActionRef.current.contains(event.target) ||
		//   this.state.isOutsidePortalOpen
		// ) {
		//   return
		// }

		// this.handleTogglePrintActionArea()
	}

	handleAddEventClickPrintActionArea() {
		document.addEventListener(
			"mousedown",
			this.handleOutsidePrintActionAreaClick,
			false
		)
	}

	handleRemoveEventClickPrintActionArea() {
		document.removeEventListener(
			"mousedown",
			this.handleOutsidePrintActionAreaClick,
			false
		)
	}

	render() {


		console.log(this.context,'test')
		return (
			<React.Fragment>
				<div className={`BoxLogin-Loader${this.context.isLoading ? " Active" : ""}`}>
					<BallClipRotateMultiple
						color={"#b71c1c"}
						loading={this.context.isLoading}
					/>
				</div>
				{
					this.context.viewMode === 'analyzt' ?
					<PrintAction
						ref={this.PrintActionRef}
						printRefKemenangan={printRefKemenangan.current}
						printRefKejanggalan={printRefKejanggalan.current}
						handleOutsidePortalOpen={this.handleOutsidePortalOpen}
						handleOutsidePortalExit={this.handleOutsidePortalExit}
						handleRemoveEventClickPrintActionArea={this.handleRemoveEventClickPrintActionArea}
					/> : null
				}
				{
					!this.context.isLoading ?
						<div className="BodySummaryContent">
							<div className="InnerControlContent">
								<div className="InnerControlLeft">
									<RegionalBreadcrumbs />
								</div>
								<div className="InnerControlRight">
									<CustomDropdown
										define={"periodes"}
										title={"PERIODE"}
										primary={null}
										label={null}
										data={this.context.globalState.periodes}
										defaultSelected={this.context.defaultSelected.periodeID}
										handleClick={this.handlePeriodeClick}
									/>
									<ButtonGroup disableElevation variant="contained">
										<Button onClick={event => this.goToPage('dashboard', 'dashboard')}><MapIcon /></Button>
										<Button color={this.context.viewMode === 'summary' ? "secondary" : ""} onClick={event => this.goToPage('summary')}><PieChartIcon /></Button>
										{
											this.context.USER.activeUser.level !== 'Executive' ?
											(this.context.regionElection.regionalID !== undefined) ?
											<Button color={this.context.viewMode === 'entry' ? "secondary" : ""} onClick={event => this.goToPage('entry')}><TableChartIcon /></Button>
											: null : null
										}
										{/* {
											(this.context.regionElection.regionalID !== undefined) ?
											<Button color={this.context.viewMode === 'analyzt' ? "secondary" : ""} onClick={event => this.goToPage('analyzt')}><GraphicEqIcon /></Button>
											: null
										} */}
									</ButtonGroup>
								</div>
							</div>
							{/* {this.state.modalIsOpen ? (
								<StrapModal size="xl" centered isOpen={this.state.modalIsOpen} onClose={this.closeModal}>
									<ModalHeader toggle={this.closeModal}>Lampiran Dokumen</ModalHeader>
									<ModalBody>
										{
											!this.context.isLoadingImages ?
											<div>
												<Gallery photos={this.context.imageDocuments} onClick={this.openLightbox} />
												<ModalGateway>
													{this.state.viewerIsOpen ? (
														<Modal onClose={this.closeLightbox}>
															<Carousel
																currentIndex={this.state.currentImage}
																views={this.context.imageDocuments.map(x => ({
																	...x,
																	srcset: x.srcSet,
																	caption: x.title
																}))}
															/>
														</Modal>
													) : null}
												</ModalGateway>
											</div> : null
										}
									</ModalBody>
								</StrapModal>
							) : null}
							{
								this.context.voteResults !== undefined &&
									this.context.voteResults.results !== undefined &&
									this.context.voteResults.results.length > 0 &&
									this.context.currentSelected.type !== undefined ?
									<div className="Title">
										<div className="centerContent">
											<div className="Type">{this.context.currentSelected.type !== undefined ? this.context.currentSelected.type.toUpperCase() : null}</div>
											<span>{this.context.currentSelected.name !== undefined ? this.context.currentSelected.name.replace('KOTA ', '').toUpperCase() : null}</span>
										</div>
										<div className="rightContent">
											{
												this.context.viewMode === 'analyzt' ?
												<IconButton onMouseDown={this.handleTogglePrintActionArea}><PrintIcon /></IconButton>
												: null
											}
											{
												this.context.viewMode === 'entry' ?
												!this.state.countDownStatus ?
												<IconButton onClick={event => this.setState({modalIsOpen: true})}><BurstModeIcon /></IconButton>
												: null : null
											}
											{
												this.context.viewMode === 'entry' && this.state.countDownStatus ? null :
												<IconButton onClick={event => this.refreshData()}><RefreshIcon /></IconButton>
											}
										</div>
									</div>
									: <div className="Title">
											<div className="centerContent">JAWA TENGAH</div>
										</div>
							} */}
							{
								this.context.viewMode === 'summary' ?
									this.context.voteResults !== undefined &&
									this.context.voteResults.results !== undefined &&
									this.context.voteResults.results.length > 0 &&
									this.context.currentDownline.length > 0 ?
									<div className={classes.ContentContainer} style={{ padding: '20px' }}>
										{/* <Grid
											container
											direction="row"
											justify="space-between"
											alignItems="stretch"
											spacing={2}>
											<Grid item xs={12} style={{display:"flex",justifyContent:"center"}}>
											<h4 style={{textAlign:"center"}}>Perolehan Suara Pasangan Calon</h4>
											</Grid>
											<Grid item xs={12} style={{display:"flex",justifyContent:"center"}}>
												<div style={{width:'50%'}}>
												<ChartPie data={this.context.voteResults.results} />
												</div>
											</Grid>
											<Grid item xs={12}>
												<DataResults data={this.context.voteResults.results} model="large" />
											</Grid>
										</Grid> */}
										{/* <DataUsed data={this.context.voteResults} /> */}
										<SubRegionals data={this.context.currentDownline} />
									</div>
									: (this.context.currentSelected.type === 'Provinsi' && (this.context.election !== 'PILPRES' || this.state.election !== 'PILKADA GUBERNUR' || this.state.election !== 'DPD')) ?
										<div className={classes.ContentContainer} style={{ padding: '20px' }}>
											<SubRegionals self={true} data={this.context.currentDownline} />
										</div>
										: <div className="notfound" style={{backgroundImage: `url(${require("../../../../../Assets/Miscellaneous/notfound.png")})`,}} />
								: 
								this.context.viewMode === 'entry' ?
									this.context.regionElection.regionalID !== undefined &&
									this.context.voteResults !== undefined &&
									this.context.voteResults.results !== undefined &&
									this.context.voteResults.results.length > 0 &&
									this.context.currentDownline.length > 0 ?
										// !this.state.countDownStatus ?
											<FormEntry data={this.context.currentDownline} candidates={this.context.voteResults.results} />
										/* :
										<div style={{position: 'absolute', width: '100vw', marginTop: '100px'}}>
											<div style={{fontSize: 48, fontWeight: 'bold', textAlign: 'center'}}>Input Kelengkapan Data akan dibuka dalam :</div>
											<FlipCountdown
												hideYear
												hideMonth
												hideDay
												yearTitle='Tahun'
												monthTitle='Bulan'
												dayTitle='Hari'
												hourTitle='Jam'
												minuteTitle='Menit'
												secondTitle='Detik'
												size='large'
												endAt={this.state.endTime}
											/>
										</div> */
									: <div className="notfound" style={{backgroundImage: `url(${require("../../../../../Assets/Miscellaneous/notfound.png")})`,}} />
								:
								this.context.regionElection.regionalID !== undefined &&
								this.context.voteResults !== undefined &&
								this.context.voteResults.results !== undefined &&
								this.context.voteResults.results.length > 0 &&
								this.context.currentDownline.length > 0 ?
									<div>
										<Tabs
											value={this.state.tabIndex}
											onChange={this.handleChange}
											variant="fullWidth"
											indicatorColor="secondary"
											textColor="secondary"
											aria-label="full width tabs example"
										>
											<Tab label="Analisa Kemenangan" />
											<Tab label="Deteksi Dini Kejanggalan" />
										</Tabs>
										<SwipeableViews index={this.state.tabIndex} onChangeIndex={this.handleChangeIndex}>
											<TabPanel value={this.state.tabIndex} index={0}>
												<div className={classes.ContentPrintContainer} style={{ marginTop: '10px', padding: '20px', height: this.state.windowHeight - 250 }}>
													<AnalystKemenangan propRef={printRefKemenangan} data={this.context.currentDownline} candidates={this.context.voteResults.results}/>
												</div>
											</TabPanel>
											<TabPanel value={this.state.tabIndex} index={1}>
												<div className={classes.ContentPrintContainer} style={{ marginTop: '10px', padding: '20px', height: this.state.windowHeight - 250 }}>
													<AnalystKejanggalan propRef={printRefKejanggalan} data={this.context.currentDownline} candidates={this.context.voteResults.results}/>
												</div>
											</TabPanel>
										</SwipeableViews>
									</div>
								: <div className="notfound" style={{backgroundImage: `url(${require("../../../../../Assets/Miscellaneous/notfound.png")})`,}} />
							}
						</div>
						: null
				}
			</React.Fragment>
		)
	}
}

export default withRouter(BodyContent)
