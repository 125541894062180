import React from "react"
import AppContext from "../../../../../../../../../AppContext"
import classes from "./Card.module.scss"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import { API_Voteroom } from "../../../../../../../../../Services/BaseURL"

var numeral = require('numeral')

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20
  },
  colorPrimary: {
		backgroundColor: '#484848',
		opacity: .9,
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.backgroundcolor !== undefined ? props.backgroundcolor : '#FFE082',
  }),
}))(LinearProgress)

class Card extends React.PureComponent {
	
	static contextType = AppContext

  render() {
    return (
			<div className={classes.Card_container}>
				<img className={classes.Card_image} alt=""
					src={`${API_Voteroom}/assets/` + this.props.data.photo}/>
				<div className={classes.Card_grid}>
					<div className={classes.Side} style={{backgroundColor: `#${this.props.data.color}`}}>
						<h2>{this.props.data.number}</h2>
					</div>
					<div className={classes.Mid}>
						<BorderLinearProgress variant="determinate"
							backgroundcolor={`#${this.props.data.color}`}
							value={this.props.totalValue > 0 ? Number(((this.props.data.value / this.props.totalValue) * 100).toFixed(2)) : parseInt(10)}
						/>
						<span style={{left: `${(this.props.data.value > 0 ? Number(((this.props.data.value / this.props.totalValue) * 100).toFixed(0)) : 10)/2}%`}}>
							<h4>{this.props.totalValue > 0 ? Number(((this.props.data.value / this.props.totalValue) * 100).toFixed(2)) : 0}%</h4>
						</span>
					</div>
					<div className={classes.Top}>
						<div>{numeral(this.props.data.value).format()}</div>
						{/* <div>{this.context.abbreviateNumber(this.props.data.value)}</div> */}
						<div>{this.props.data.name}</div>
					</div>
				</div>
			</div>
		)
  }
}

export default Card