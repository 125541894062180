import API from "./API"
import { API_Generals } from "./BaseURL"

// const GENERALS_API_URL = "http://159.89.195.233:8001"
// const GENERALS_API_URL = "http://demogn.pantimarhaen.id:7001"
//const GENERALS_API_URL = "http://generalsapi.test"
const GENERALS_API_URL = API_Generals

const electionRegion = async (periode=2024, election, type, parentID = null) => {
  let rules = [
    {field: "type", op: "in", data: type}
  ]
  if (parentID !== null) {
    rules.push({field: "parentID", op: "eq", data: parentID})
  }
  const response = API.get('elections/regionals', {
    baseURL: `${GENERALS_API_URL}`,
    params: {
      body: {
        // periode: `${periode}`,
        periode: `2024`,
        election: `${election}`,
        search: {
          groupOp: "AND",
          rules: rules,
          groups: []
        },
        page: 1,
        limit: 100,
        sorts: [{field: "code", mode: "ASC"}]
      }
    }
  })
  .then(res => {
    return res.data
  })
  .catch(err => {
    return err.data
  })

  return response
}

const downline = async (periode, id, type) => {
  const response = API.get('regionals/downline', {
    baseURL: `${GENERALS_API_URL}`,
    params: {
      body: {
        // periode: `${periode}`,
        periode: `2024`,
        id: `${id}`,
        type: `${type}`,
        page: 1,
        limit: 100,
        sorts: [{field: "pollstationID", mode: "ASC"}]
      }
    }
  })
  .then(res => {
    return res.data
  })
  .catch(err => {
    return err.data
  })

  return response
}

const periode = async () => {
  const response = API.get('periode', {
    baseURL: `${GENERALS_API_URL}`,
    params: {
      body: {
        page: 1,
        limit: 100,
        sorts: [{field: "year", mode: "ASC"}]
      }
    }
  })
  .then(res => {
    return res.data
  })
  .catch(err => {
    console.log(err)
    return err.data
  })

  return response
}

export { electionRegion, downline, periode }