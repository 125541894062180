import React, {Component} from "react"
import classes from "./Partai.module.scss"
import Sticky from "./Sticky"
import Carousel from "./Carousel"

class Partai extends Component {
	render() {
		return (
			<div className={classes.Grid_container}>
				<div className={classes.MidSide}>
					<Sticky/>
				</div>
				<div className={classes.MidWrap}>
					<Carousel/>
				</div>
			</div>
		)
	}
}

export default Partai