import React from "react"
import AppContext from "../../../../../../../AppContext"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import ChartDoughnut from "../../../../../../../Components/Charts/doughnut"

var numeral = require('numeral')

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#FFE082',
  },
}))(LinearProgress)

class DataUsed extends React.PureComponent {
  static contextType = AppContext
  
  constructor(props) {
    super(props)
    this.state = {
      totalAllocated: parseInt(parseInt(props.data.use.allocated)+parseInt(props.data.use.allocated*0.025).toFixed(0)),
      totalUsed: parseInt(parseInt(this.props.data.use.valid)+parseInt(this.props.data.use.invalid)),
      participations: {
        data: {
          l: (
            parseInt(props.data.dpt.data.dptL)+
            parseInt(props.data.dpt.data.dptbL)+
            parseInt(props.data.dpt.data.dpkL)
          ),
          p: (
            parseInt(props.data.dpt.data.dptP)+
            parseInt(props.data.dpt.data.dptbP)+
            parseInt(props.data.dpt.data.dpkP)
          )
        },
        input: {
          l: (
            parseInt(props.data.dpt.input.dptL)+
            parseInt(props.data.dpt.input.dptbL)+
            parseInt(props.data.dpt.input.dpkL)
          ),
          p: (
            parseInt(props.data.dpt.input.dptP)+
            parseInt(props.data.dpt.input.dptbP)+
            parseInt(props.data.dpt.input.dpkP)
          )
        }
      }
    }
  }

  render() {
    return (
      <div>
        <div>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12}>
              <span style={{fontSize: '20px', fontWeight: 'bold'}}>TPS Masuk</span>
              <div>{numeral(this.props.data.progress.input.c).format()} dari {numeral(this.props.data.progress.data.c).format()} TPS</div>
              <BorderLinearProgress variant="determinate" value={parseInt(this.props.data.progress.input.c) > 0 ? Number(((parseInt(this.props.data.progress.input.c)/parseInt(this.props.data.progress.data.c)).toFixed(2)*100).toFixed(0)) : 0} />
            </Grid>
          </Grid>
        </div>
        <div style={{marginTop: '20px'}}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
              <h4>Pemilih</h4>
            </Grid>
            <Grid item xs={5}>
              <h4>Suara Sah dan Tidak Sah</h4>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={4}>
                  Jumlah Pemilih dalam DPT
                </Grid>
                <Grid item xs={2}>
                  <div>Laki-Laki</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.data.dptL).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.data.dptL)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Perempuan</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.data.dptP).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.data.dptP)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Total</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(parseInt(this.props.data.dpt.data.dptL)+parseInt(this.props.data.dpt.data.dptP)).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(parseInt(this.props.data.dpt.data.dptL)+parseInt(this.props.data.dpt.data.dptP))}</span> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item xs={3}>
                  Suara Sah
                </Grid>
                <Grid item xs={9}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.use.valid).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.use.valid)}</span> */}
                  <div>Jumlah suara sah</div>
                  <BorderLinearProgress variant="determinate" value={this.state.totalUsed !== 0 ? Number((parseInt(this.props.data.use.valid)/this.state.totalUsed).toFixed(0)) : 0} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={4}>
                  Jumlah Pemilih dalam DPPh
                </Grid>
                <Grid item xs={2}>
                  <div>Laki-Laki</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.data.dpkL).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.data.dpkL)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Perempuan</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.data.dpkP).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.data.dpkP)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Total</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(parseInt(this.props.data.dpt.data.dpkL)+parseInt(this.props.data.dpt.data.dpkP)).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(parseInt(this.props.data.dpt.data.dpkL)+parseInt(this.props.data.dpt.data.dpkP))}</span> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item xs={3}>
                  Suara Tidak Sah
                </Grid>
                <Grid item xs={9}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.use.invalid).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.use.invalid)}</span> */}
                  <div>Jumlah suara tidak sah</div>
                  <BorderLinearProgress variant="determinate" value={this.state.totalUsed !== 0 ? Number((parseInt(this.props.data.use.invalid)/this.state.totalUsed).toFixed(0)) : 0} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={7}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
              spacing={4}
            >
              <Grid item xs={4}>
                Jumlah Pemilih dalam DPTb
              </Grid>
              <Grid item xs={2}>
                <div>Laki-Laki</div>
                <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.data.dptbL).format()}</span>
                {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.data.dptbL)}</span> */}
              </Grid>
              <Grid item xs={2}>
                <div>Perempuan</div>
                <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.data.dptbP).format()}</span>
                {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.data.dptbP)}</span> */}
              </Grid>
              <Grid item xs={2}>
                <div>Total</div>
                <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(parseInt(this.props.data.dpt.data.dptbL)+parseInt(this.props.data.dpt.data.dptbP)).format()}</span>
                {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(parseInt(this.props.data.dpt.data.dptbL)+parseInt(this.props.data.dpt.data.dptbP))}</span> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
              spacing={2}
            >
                <Grid item xs={3}>
                  Total Suara
                </Grid>
                <Grid item xs={9}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.state.totalUsed).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.state.totalUsed)}</span> */}
                  <div>Jumlah suara sah dan tidak sah</div>
                  <BorderLinearProgress variant="determinate" value={this.props.data.use.allocated !== 0 ? Number(((this.state.totalUsed/this.props.data.use.allocated)*100).toFixed(0)) : 0} />
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        </div>
        
        <div style={{marginTop: '20px'}}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
              <h4>Pengguna Hak Pilih</h4>
            </Grid>
            <Grid item xs={5}>
              <h4>Surat Suara</h4>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={4}>
                  Jumlah Pengguna Hak Pilih dalam DPT
                </Grid>
                <Grid item xs={2}>
                  <div>Laki-Laki</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.input.dptL).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.input.dptL)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Perempuan</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.input.dptP).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.input.dptP)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Total</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(parseInt(this.props.data.dpt.input.dptL)+parseInt(this.props.data.dpt.input.dptP)).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(parseInt(this.props.data.dpt.input.dptL)+parseInt(this.props.data.dpt.input.dptP))}</span> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item xs={3}>
                  Suara Diterima
                </Grid>
                <Grid item xs={9}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.use.allocated).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.use.allocated)}</span> */}
                  <div>Jumlah surat suara diterima</div>
                  <BorderLinearProgress variant="determinate" value={this.props.data.use.allocated !== 0 ? 100 : 0} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={4}>
                  Jumlah Pengguna Hak Pilih dalam DPPh
                </Grid>
                <Grid item xs={2}>
                  <div>Laki-Laki</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.input.dpkL).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.input.dpkL)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Perempuan</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.input.dpkP).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.input.dpkP)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Total</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(parseInt(this.props.data.dpt.input.dpkL)+parseInt(this.props.data.dpt.input.dpkP)).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(parseInt(this.props.data.dpt.input.dpkL)+parseInt(this.props.data.dpt.input.dpkP))}</span> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item xs={3}>
                  Suara Dikembalikan
                </Grid>
                <Grid item xs={9}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.use.broken).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.use.broken)}</span> */}
                  <div>Jumlah surat suara dikembalikan</div>
                  <BorderLinearProgress variant="determinate" value={this.props.data.use.allocated !== 0 ? Number(((parseInt(this.props.data.use.broken)/this.props.data.use.allocated)*100).toFixed(0)) : 0} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={4}>
                  Jumlah Pengguna Hak Pilih dalam DPTb
                </Grid>
                <Grid item xs={2}>
                  <div>Laki-Laki</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.input.dptbL).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.input.dptbL)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Perempuan</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.input.dptbP).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.input.dptbP)}</span> */}
                </Grid>
                <Grid item xs={2}>
                  <div>Total</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(parseInt(this.props.data.dpt.input.dptbL)+parseInt(this.props.data.dpt.input.dptbP)).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(parseInt(this.props.data.dpt.input.dptbL)+parseInt(this.props.data.dpt.input.dptbP))}</span> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item xs={3}>
                  Suara Digunakan
                </Grid>
                <Grid item xs={9}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.use.used).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.use.used)}</span> */}
                  <div>Jumlah surat suara digunakan</div>
                  <BorderLinearProgress variant="determinate" value={this.props.data.use.allocated !== 0 ? Number(((parseInt(this.props.data.use.used)/this.props.data.use.allocated)*100).toFixed(0)) : 0} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item xs={3}>
                  Suara Tidak Digunakan
                </Grid>
                <Grid item xs={9}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.use.unused).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.use.unused)}</span> */}
                  <div>Jumlah surat suara tidak diguakan</div>
                  <BorderLinearProgress variant="determinate" value={this.props.data.use.allocated !== 0 ? Number(((parseInt(this.props.data.use.unused)/this.props.data.use.allocated)*100).toFixed(0)) : 0} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        
        <div style={{marginTop: '20px'}}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
              <h4>Partisipasi Pengguna Hak Pilih</h4>
            </Grid>
            <Grid item xs={5}>
              <h4>Disabilitas</h4>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={4}>
                  Partisipasi
                </Grid>
                <Grid item xs={2} style={{
                  textAlign: 'center'
                }}>
                  <div>Laki-Laki</div>
                  <ChartDoughnut source={{
                    data: this.state.participations.data.l,
                    input: this.state.participations.input.l
                  }}/>
                </Grid>
                <Grid item xs={2} style={{
                  textAlign: 'center'
                }}>
                  <div>Perempuan</div>
                  <ChartDoughnut source={{
                    data: this.state.participations.data.p,
                    input: this.state.participations.input.p
                  }}/>
                </Grid>
                <Grid item xs={2} style={{
                  textAlign: 'center'
                }}>
                  <div>Total</div>
                  <ChartDoughnut source={{
                    data: parseInt(parseInt(this.state.participations.data.l)+parseInt(this.state.participations.data.p)),
                    input: parseInt(parseInt(this.state.participations.input.l)+parseInt(this.state.participations.input.p))
                  }}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={3}>
                  Pemilih Disabilitas
                </Grid>
                <Grid item xs={3}>
                  <div>Laki-Laki</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.data.diffableL).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.data.diffableL)}</span> */}
                </Grid>
                <Grid item xs={3}>
                  <div>Perempuan</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.data.diffableP).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.data.diffableP)}</span> */}
                </Grid>
                <Grid item xs={3}>
                  <div>Total</div>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(parseInt(this.props.data.dpt.data.diffableL)+parseInt(this.props.data.dpt.data.diffableP)).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(parseInt(this.props.data.dpt.data.diffableL)+parseInt(this.props.data.dpt.data.diffableP))}</span> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={3}>
                  Pengguna Hak Pilih Disabilitas
                </Grid>
                <Grid item xs={3}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.input.diffableL).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.input.diffableL)}</span> */}
                </Grid>
                <Grid item xs={3}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(this.props.data.dpt.input.diffableP).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(this.props.data.dpt.input.diffableP)}</span> */}
                </Grid>
                <Grid item xs={3}>
                  <span style={{fontSize: '20px', fontWeight: 'bold'}}>{numeral(parseInt(this.props.data.dpt.input.diffableL)+parseInt(this.props.data.dpt.input.diffableP)).format()}</span>
                  {/* <span style={{fontSize: '20px', fontWeight: 'bold'}}>{this.context.abbreviateNumber(parseInt(this.props.data.dpt.input.diffableL)+parseInt(this.props.data.dpt.input.diffableP))}</span> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={7}>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={3}>
                  Partisipasi
                </Grid>
                <Grid item xs={3} style={{
                  textAlign: 'center'
                }}>
                  <div>Laki-Laki</div>
                  <ChartDoughnut source={{
                    data: this.props.data.dpt.data.diffableL,
                    input: this.props.data.dpt.input.diffableL
                  }}/>
                </Grid>
                <Grid item xs={3} style={{
                  textAlign: 'center'
                }}>
                  <div>Perempuan</div>
                  <ChartDoughnut source={{
                    data: this.props.data.dpt.data.diffableP,
                    input: this.props.data.dpt.input.diffableP
                  }}/>
                </Grid>
                <Grid item xs={3} style={{
                  textAlign: 'center'
                }}>
                  <div>Total</div>
                  <ChartDoughnut source={{
                    data: parseInt(parseInt(this.props.data.dpt.data.diffableL)+parseInt(this.props.data.dpt.data.diffableP)),
                    input: parseInt(parseInt(this.props.data.dpt.input.diffableL)+parseInt(this.props.data.dpt.input.diffableP))
                  }}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

export default DataUsed